import { createStyles, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  GettingStartedToursNamesEnum,
  useOnboardingTours,
} from '@portals/api/organizations';
import { TOUR_STEPS_IDS } from '@portals/framework';
import { EntityAccess } from '@portals/framework/EntityAccess';
import {
  AccessLevelEnum,
  DeviceType,
  TableState,
  TableViewModeType,
} from '@portals/types';

import { DevicesFilter } from './DevicesFilter';
import { DevicesList } from './DevicesList';
import { DevicesMapWrapper } from './DevicesMap';
import { ClaimDeviceButton } from '../../../../../components/ClaimDeviceButton';
import { useMonitoringTourContext } from '../../../../../components/MonitoringTourContextProvider';
import { MonitoringTourPartOne } from '../../../../../components/setup-tours/MonitoringTourPartOne';
import { useOverviewContext } from '../../../overview.context';
import { useCurrentSpace } from '../../../overview.hooks';
import { WidgetCardHeader } from '../common/WidgetCardHeader';
import { WidgetCardWrapper } from '../common/WidgetCardWrapper';

interface DevicesWidgetProps {
  initialViewType?: TableViewModeType;
}

export function DevicesListWidget() {
  return <DevicesWidget initialViewType="list" />;
}

export function DevicesMapWidget() {
  return <DevicesWidget initialViewType="map" />;
}

export function DevicesGridWidget() {
  return <DevicesWidget initialViewType="grid" />;
}

function DevicesWidget({ initialViewType = 'grid' }: DevicesWidgetProps) {
  const space = useCurrentSpace();
  const overviewContext = useOverviewContext();
  const { classes } = useStyles();
  const location = useLocation();
  const onboardingTours = useOnboardingTours();
  const { runTourPartOne } = useMonitoringTourContext();

  const [viewType, setViewType] = useState<TableViewModeType>(initialViewType);
  const [filters, setFilters] = useState<TableState<DeviceType>['filters']>([]);
  const [sortBy, setSortBy] = useState<TableState<DeviceType>['sortBy']>([
    { id: 'name', desc: false },
  ]);
  const [startMonitoringTour, setStartMonitoringTour] = useState(false);

  const activeTourSearchParams = new URLSearchParams(location.search).get(
    'active_tour'
  );

  useEffectOnce(function checkIfStartMonitoringTour() {
    if (
      (activeTourSearchParams === GettingStartedToursNamesEnum.Monitoring &&
        (onboardingTours?.getting_started?.claim_device_completed ||
          (space?.state?.devices && space.state.devices > 0))) ||
      runTourPartOne
    ) {
      setStartMonitoringTour(true);
    }
  });

  const numOfDevices =
    (overviewContext.isLocalDataLevel
      ? space?.state?.local_devices
      : space?.state?.devices) || 0;

  const content = useMemo(() => {
    if (viewType === 'map') {
      return <DevicesMapWrapper filters={filters} sortBy={sortBy} />;
    }

    return (
      <DevicesList viewType={viewType} filters={filters} sortBy={sortBy} />
    );
  }, [filters, sortBy, viewType]);

  return (
    <WidgetCardWrapper
      paperProps={{
        className: classes.container,
        pt: 0,
        // @ts-ignore
        'data-tour-step-id': TOUR_STEPS_IDS.device,
      }}
      stackProps={{
        spacing: 0,
      }}
      header={
        <WidgetCardHeader
          viewType={viewType}
          setViewType={setViewType}
          withMapViewType
          filter={
            <DevicesFilter
              filters={filters}
              setFilters={setFilters}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          }
          actions={
            space && space.space_type !== 'root' ? (
              <EntityAccess
                minLevel={AccessLevelEnum.Edit}
                entity={space}
                id="overview-space-devices-btn"
              >
                <ClaimDeviceButton
                  spaceId={space.id}
                  variant="default"
                  size="sm"
                  data-testid="claim-devices-card-button"
                >
                  Claim device
                </ClaimDeviceButton>
              </EntityAccess>
            ) : null
          }
          title={
            <>
              <Text size="md" color="gray.5" data-testid="devices-widget-title">
                Devices
              </Text>

              <Text size="md" color="gray.5">
                ({numOfDevices})
              </Text>
            </>
          }
        />
      }
    >
      {startMonitoringTour && <MonitoringTourPartOne />}
      {content}
    </WidgetCardWrapper>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '.table-scroll-container': {
      border: 'none',
      paddingLeft: 0,
      paddingRight: 0,
      transform: 'translateX(-15px)',
    },
  },
}));
