import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

TimeAgo.addLocale(en);

const timeAgoFormatter = new TimeAgo('en-US');

/** @deprecated - Use `timeFromNow` instead
 * @see timeFromNow */
export const timeAgo = (time: number): string =>
  time ? (timeAgoFormatter.format(time) as string) : 'None';

/** @deprecated - Use `formatDateTime` instead
 * @see formatDateTime */
export const prettyTime = (
  time: number | string | null | undefined
): string => {
  if (!time) {
    return 'Unknown';
  }

  const dateObject = new Date(time);

  return (
    dateObject.toLocaleDateString() + ' ' + dateObject.toLocaleTimeString()
  );
};

/** @deprecated - Use `formatDateTime` instead
 * @see formatDateTime */
export const prettyTimeMoment = (
  time: number | string | null | undefined,
  format = 'MMMM Do, YYYY h:mm A'
): string => {
  if (!time) {
    return 'Unknown';
  }

  return moment(time).format(format);
};

const mediumDateFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'medium',
});

export function mediumDateFormatter(
  date: string | Date | null | undefined,
  invalidMessage = 'Invalid Date'
) {
  if (!date) return invalidMessage;
  if (typeof date === 'string') return mediumDateFormat.format(new Date(date));

  return mediumDateFormat.format(date);
}

const shortTimeFormat = new Intl.DateTimeFormat(undefined, {
  timeStyle: 'short',
});

export function shortTimeFormatter(date: string | Date | undefined) {
  if (!date) return 'Invalid Date';
  if (typeof date === 'string') return shortTimeFormat.format(new Date(date));

  return shortTimeFormat.format(date);
}

export function formatDateTime(
  datetime: Date | string | undefined | null,
  format = 'MMM DD, YYYY hh:mm A',
  invalidMessage = 'Invalid Date',
  timeZone?: string
) {
  if (!datetime) return invalidMessage;

  if (timeZone) {
    return dayjs(datetime).tz(timeZone).format(format);
  }

  return dayjs(datetime).format(format);
}

export function timeFromNow(datetime: Date | string | undefined | null) {
  if (!datetime) return 'Invalid Date';

  return dayjs(datetime).fromNow();
}

export function timeUntil(datetime: Date | string | undefined | null) {
  if (!datetime) return 'Invalid Date';

  const now = dayjs();
  const target = dayjs(datetime);
  const diffInDays = target.diff(now, 'day');

  if (target.diff(now, 'day') > 1) {
    return `Until ${target.format('DD MMM')}`;
  } else if (diffInDays === 0 && target.isAfter(now, 'day')) {
    return `Until tomorrow at ${target.format('HH:mm')}`;
  } else {
    return `Until ${target.format('HH:mm')}`;
  }
}

export function isDateAfterNow(dateString: string | null | undefined): boolean {
  const now = dayjs();
  const date = dayjs(dateString);
  return date.isAfter(now);
}
