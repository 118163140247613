import {
  Flex,
  FlexProps,
  Loader,
  Select,
  SelectProps,
  Text,
} from '@mantine/core';
import React, { forwardRef } from 'react';

import {
  EntityLabelType,
  useCreateEntityLabel,
  useEntityLabelsByCategory,
} from '@portals/api/ui';
import { suppressPropagation } from '@portals/utils';

interface EntityLabelSelectProps
  extends Omit<SelectProps, 'data' | 'onCreate'> {
  category: string;
  onEntityLabelSelected: (entityLabel: EntityLabelType) => void;
}

type EntityLabelOption = { value: string; label: string; color?: string };

function composeOptions(entityLabels?: EntityLabelType[]): EntityLabelOption[] {
  if (!entityLabels) return [];

  return entityLabels.map(({ id, name, color }) => ({
    value: id,
    label: name,
    color,
  }));
}

export function EntityLabelSelect({
  category,
  onEntityLabelSelected,
  ...selectProps
}: EntityLabelSelectProps) {
  const entityLabels = useEntityLabelsByCategory(category);
  const createEntityLabel = useCreateEntityLabel();

  function onCreateLabel(entityLabel: string) {
    createEntityLabel.mutate(
      {
        category,
        name: entityLabel,
      },
      {
        onSuccess: (newEntityLabel) => onEntityLabelSelected(newEntityLabel),
      }
    );

    return entityLabel;
  }

  function getCreateLabel(name: string) {
    return (
      <div>
        + Add label <b>{name}</b>
      </div>
    );
  }

  const options = composeOptions(entityLabels.data);

  function onChange(labelId: string | null) {
    if (!labelId) {
      onEntityLabelSelected(null);

      return;
    }

    const selectedLabel = entityLabels.data?.find(
      (label) => label.id === labelId
    );

    if (selectedLabel) {
      onEntityLabelSelected(selectedLabel);
    }
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <Select
      creatable
      searchable
      clearable
      withinPortal
      rightSection={entityLabels.isLoading ? <Loader size="xs" /> : undefined}
      data={options}
      itemComponent={SelectItem}
      onCreate={onCreateLabel}
      getCreateLabel={getCreateLabel}
      nothingFound="Nothing found"
      onChange={onChange}
      onKeyDown={onKeyDown}
      {...selectProps}
    />
  );
}

interface SelectItemProps extends FlexProps {
  value: string;
  label: string;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ value, label, color, onMouseDown, ...selectItemProps }, ref) => {
    return (
      <Flex
        onMouseDown={suppressPropagation(onMouseDown)}
        ref={ref}
        align="center"
        gap="xs"
        px="md"
        py="sm"
        {...selectItemProps}
      >
        <Text>{label}</Text>
      </Flex>
    );
  }
);
