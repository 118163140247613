import React from 'react';

import { OverviewAssets } from './overview-assets/OverviewAssets';
import { OverviewDashboard } from './overview-dashboard/OverviewDashboard';
import { OverviewDevices } from './overview-devices/OverviewDevices';
import { OverviewDevicesProvider } from './overview-devices/OverviewDevicesProvider';
import { useOverviewContext } from './overview.context';

export function OverviewMainContent() {
  const overview = useOverviewContext();

  if (overview.isDevicesRoute) {
    return (
      <OverviewDevicesProvider>
        <OverviewDevices />
      </OverviewDevicesProvider>
    );
  } else if (overview.isAssetsRoute) {
    return <OverviewAssets />;
  }

  return <OverviewDashboard />;
}
