import {
  Button,
  Group,
  LoadingOverlay,
  ScrollArea,
  SimpleGrid,
  Stack,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import React from 'react';
import { object, string } from 'yup';

import {
  AssetType,
  generateAssetToUpdateStructure,
  SpaceType,
  useCreateAsset,
  useSpaces,
  useUpdateAsset,
} from '@portals/api/organizations';
import { EntityLabelType } from '@portals/api/ui';
import { ModalCenteredMediaLayout } from '@portals/core';
import {
  EntityLabelSelect,
  ModalProps,
  useConfirmationModal,
} from '@portals/framework';

import { ChangeSpaceField } from './add-device-v2/add-device-form/ChangeSpaceField';
import { AssetIcon } from '../pages/assets/AssetIcon';

export interface AddAssetModalProps
  extends ModalProps<{
    initialAsset?: AssetType;
    initialSpaceId?: SpaceType['id'];
  }> {}

type FormValues = Omit<
  AssetType,
  'id' | 'space' | 'access_level' | 'created_at' | 'purchased_at'
> & {
  purchased_at: Date | null;
  space_id: SpaceType['id'];
};

const schema = object({
  space_id: string().required('Space is required'),
});

export function AddAssetModal({ closeMe, data }: ModalProps) {
  const asyncConfirmationCheck = useConfirmationModal();

  const createAsset = useCreateAsset();
  const updateAsset = useUpdateAsset();
  const spaces = useSpaces();

  const form = useForm<FormValues>({
    initialValues: {
      name: data.initialAsset?.name || '',
      icon_name: data.initialAsset?.icon_name || 'box',
      serial_number: data.initialAsset?.serial_number || '',
      comments: data.initialAsset?.comments || '',
      purchased_at: data.initialAsset?.purchased_at
        ? new Date(data.initialAsset?.purchased_at)
        : null,

      space_id: data.initialAsset?.space.id || data.initialSpaceId || '',

      manufacturer: data.initialAsset?.manufacturer || {
        category: 'manufacturer',
        name: '',
        id: '',
      },
      device_model: data.initialAsset?.device_model || {
        category: 'device_model',
        name: '',
        id: '',
      },
      device_type: data.initialAsset?.device_type || {
        category: 'device_type',
        name: '',
        id: '',
      },
      status: data.initialAsset?.status || {
        category: 'status',
        name: '',
        id: '',
        color: '',
      },
    },
    validate: yupResolver(schema),
  });

  function onSubmit(values: typeof form.values) {
    if (data.initialAsset) {
      updateAsset.mutate(
        {
          asset: {
            ...generateAssetToUpdateStructure(data.initialAsset),

            icon_name: values.icon_name,
            name: values.name,
            comments: values.comments,
            serial_number: values.serial_number,
            purchased_at: values.purchased_at?.toISOString() || '',
            space_id: values.space_id,
            manufacturer: {
              id: values.manufacturer?.id || null,
            },
            device_model: {
              id: values.device_model?.id || null,
            },
            device_type: {
              id: values.device_type?.id || null,
            },
            status: {
              id: values.status?.id || null,
            },
          },
          shouldRefetch: true,
        },
        {
          onSuccess: closeMe,
        }
      );
    } else {
      createAsset.mutate(
        {
          name: values.name,
          icon_name: values.icon_name,
          serial_number: values.serial_number,
          comments: values.comments,
          purchased_at: values.purchased_at?.toISOString() || '',
          space_id: values.space_id,
          manufacturer: {
            id: values.manufacturer?.id ?? null,
          },
          device_model: {
            id: values.device_model?.id ?? null,
          },
          device_type: {
            id: values.device_type?.id ?? null,
          },
          status: {
            id: values.status?.id ?? null,
          },
        },
        {
          onSuccess: closeMe,
        }
      );
    }
  }

  function onEntityLabelCreated(
    category: string,
    entityLabel?: EntityLabelType
  ) {
    const adjustedCategoryName = category.replace('asset_', '');

    form.setFieldValue(adjustedCategoryName, entityLabel);
  }

  const currentSpace = spaces.data?.find(
    (space) => space.id === form.values.space_id
  );

  async function onClose() {
    if (!form.isDirty()) {
      closeMe();

      return;
    }

    const isConfirmed = await asyncConfirmationCheck({
      description: 'All unsaved changes will be lost',
      title: 'Are you sure?',
    });

    if (isConfirmed) {
      closeMe();
    }
  }

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={onClose}
      title={
        data.initialAsset
          ? `Edit asset ${data.initialAsset?.name}`
          : 'Add new asset'
      }
      media={
        <AssetIcon
          iconName={form.values.icon_name}
          wrapperSize={120}
          iconSize={57}
          onChange={(iconName) => form.setFieldValue('icon_name', iconName)}
          popoverProps={{
            withinPortal: true,
          }}
        />
      }
    >
      <LoadingOverlay
        visible={createAsset.isLoading || updateAsset.isLoading}
      />

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <ScrollArea.Autosize mah="calc(90vh - 350px)">
            <Stack>
              <ChangeSpaceField
                title="Add asset to"
                allowAllSpaces={!data.initialAsset && !data.initialSpaceId}
                error={form.errors?.space_id}
                space={currentSpace}
                onSetSpaceId={(spaceId) =>
                  form.setFieldValue('space_id', spaceId)
                }
              />

              <TextInput
                {...form.getInputProps('name')}
                label="Name"
                required
                placeholder="Name your asset"
              />

              <SimpleGrid cols={2} spacing="md">
                <EntityLabelSelect
                  category="asset_manufacturer"
                  onEntityLabelSelected={(entityLabel) =>
                    onEntityLabelCreated('asset_manufacturer', entityLabel)
                  }
                  label="Manufacturer"
                  placeholder="Select or add a manufacturer"
                  value={form?.values?.manufacturer?.id || ''}
                />

                <EntityLabelSelect
                  category="asset_device_model"
                  onEntityLabelSelected={(entityLabel) =>
                    onEntityLabelCreated('asset_device_model', entityLabel)
                  }
                  label="Device Model"
                  placeholder="Select or add a device model"
                  value={form?.values?.device_model?.id || ''}
                />
              </SimpleGrid>

              <TextInput
                label="Serial number"
                placeholder="Enter serial number"
                {...form.getInputProps('serial_number')}
              />

              <SimpleGrid cols={2} spacing="md">
                <EntityLabelSelect
                  category="asset_device_type"
                  onEntityLabelSelected={(entityLabel) =>
                    onEntityLabelCreated('asset_device_type', entityLabel)
                  }
                  label="Type"
                  placeholder="Select or create a type"
                  value={form?.values?.device_type?.id || ''}
                />

                <EntityLabelSelect
                  category="asset_status"
                  onEntityLabelSelected={(entityLabel) =>
                    onEntityLabelCreated('asset_status', entityLabel)
                  }
                  label="Status"
                  placeholder="Select or create a status"
                  value={form?.values?.status?.id || ''}
                  withIcon
                />
              </SimpleGrid>

              <DatePickerInput
                label="Purchase Date"
                placeholder="Select purchase date"
                {...form.getInputProps('purchased_at')}
                value={
                  form.values.purchased_at
                    ? new Date(form?.values?.purchased_at)
                    : null
                }
              />

              <Textarea
                label="Comments"
                minRows={3}
                placeholder="Add your comments (optional)"
                {...form.getInputProps('comments')}
              />
            </Stack>
          </ScrollArea.Autosize>

          <Group position="right">
            <Button variant="default" size="md" onClick={onClose}>
              Cancel
            </Button>

            <Button type="submit" size="md">
              {data.initialAsset ? 'Update Asset' : 'Add Asset'}
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalCenteredMediaLayout>
  );
}
