import { createStyles, Group, Menu, MenuProps, Text } from '@mantine/core';
import { find, reduce } from 'lodash/fp';
import React from 'react';

import { SpaceType, useSpaces } from '@portals/api/organizations';

import { SpaceNameInput } from './SpaceNameInput';
import { useOverviewRouting } from '../overview-routing';
import { useOverviewContext } from '../overview.context';
import { ACTION_BUTTONS_WIDTH, getTextWidth } from '../overview.utils';

const getSpacePath = (selectedSpace: SpaceType, spaces: SpaceType[]) => {
  return reduce(
    (acc: Array<string>, curr: number) => {
      const spaceName = find({ id: curr }, spaces)?.name;

      if (!spaceName) return acc;

      acc.push(spaceName);

      return acc;
    },
    [],
    selectedSpace?.path
  );
};

interface SpacePathProps {
  space: SpaceType | undefined;
  fontSize: number;
  truncate: boolean;
  containerWidth: number;
  pathWidth: number;
  rightButtonsWidth: number;
}

export function SpacePath({
  space,
  fontSize,
  truncate,
  pathWidth,
  containerWidth,
  rightButtonsWidth,
}: SpacePathProps) {
  const { theme } = useStyles({
    fontSize,
  });

  const spaces = useSpaces();
  const overview = useOverviewContext();

  if (!space || !spaces.data) return null;

  const pathNames = getSpacePath(space, spaces.data);

  const currentSpace = find(
    { id: space.path[space.path.length - 1] },
    spaces.data
  );

  const currentSpaceWidth = getTextWidth({
    text: currentSpace?.name,
    font: `${fontSize}px ${theme.fontFamily}`,
    containerWidth,
    rightButtonsWidth,
  });

  const currentSpaceMaxWidth =
    ((containerWidth - ACTION_BUTTONS_WIDTH - rightButtonsWidth) *
      currentSpaceWidth) /
    pathWidth;

  return (
    <Group spacing={2} noWrap>
      {pathNames.map((name, index) => (
        <SpacePathNames
          key={index}
          spaceName={name}
          index={index}
          pathNames={pathNames}
          space={space}
          spaceEditModeId={overview.spaceEditModeId}
          truncate={truncate}
          currentSpaceMaxWidth={currentSpaceMaxWidth}
          fontSize={fontSize}
        />
      ))}
    </Group>
  );
}

interface SpacePathNamesProps {
  spaceName: string;
  index: number;
  pathNames: string[];
  space: SpaceType;
  spaceEditModeId: number;
  truncate: boolean;
  currentSpaceMaxWidth: number;
  fontSize: number;
}
function SpacePathNames({
  spaceName,
  index,
  pathNames,
  space,
  spaceEditModeId,
  truncate,
  currentSpaceMaxWidth,
  fontSize,
}: SpacePathNamesProps) {
  const { classes, cx } = useStyles({
    fontSize,
  });

  const overviewRouting = useOverviewRouting();

  if (index === pathNames.length - 1) {
    return spaceEditModeId === space.id ? (
      <SpaceNameInput key={space.id} />
    ) : (
      <Text
        className={cx(classes.path, classes.active)}
        truncate
        maw={currentSpaceMaxWidth}
      >
        {spaceName}
      </Text>
    );
  }

  if (index === 0) {
    return (
      <Group spacing={2} noWrap>
        <Text
          className={cx(classes.path, classes.pathLink)}
          onClick={() =>
            overviewRouting.navigateToOverviewTab({
              spaceId: space.path[index],
            })
          }
          miw="max-content"
        >
          {spaceName}
        </Text>

        <Text miw="max-content" className={cx(classes.path, classes.pathLink)}>
          /
        </Text>
      </Group>
    );
  }

  if (!truncate) {
    return (
      <Group spacing={2} noWrap>
        <Text
          className={cx(classes.path, classes.pathLink)}
          onClick={() =>
            overviewRouting.navigateToOverviewTab({ spaceId: space.path[1] })
          }
          miw="max-content"
        >
          {spaceName}
        </Text>

        <Text miw="max-content" className={cx(classes.path, classes.pathLink)}>
          /
        </Text>
      </Group>
    );
  }

  if (truncate && index === 1) {
    return (
      <Group spacing={2} noWrap>
        <Menu shadow="md" width={200} radius="md" styles={menuStyles}>
          <Menu.Target>
            <Text
              miw="max-content"
              className={cx(classes.path, classes.pathLink)}
            >
              ... /
            </Text>
          </Menu.Target>

          <Menu.Dropdown>
            {pathNames.slice(1, -1).map((name, idx) => (
              <Menu.Item
                onClick={() =>
                  overviewRouting.navigateToOverviewTab({
                    spaceId: space.path[idx + 1],
                  })
                }
                key={idx}
              >
                {name}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
    );
  }
}

const menuStyles: MenuProps['styles'] = (theme) => ({
  itemLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const useStyles = createStyles((theme, { fontSize }: { fontSize: number }) => ({
  path: {
    color: theme.colors.blue_gray[6],
    fontSize: fontSize,
    overflow: 'hidden',
  },

  pathLink: {
    '&:not(.active)': {
      cursor: 'pointer',
    },
  },

  active: {
    color: theme.colors.blue_gray[9],
    fontWeight: 600,
  },
}));
