import { globalQueryKeys } from '../global-query-keys';

export const ASSETS_API_URL = 'ui/organization/assets';
export const ASSETS_WARRANTIES_API_URL = 'ui/organization/asset_warranties';

export const assetsQueryKeys = {
  global: [...globalQueryKeys.assets],
  all: () => [...assetsQueryKeys.global, ASSETS_API_URL],
  details: (assetId: string) => [...assetsQueryKeys.all(), 'details', assetId],
  allAssetsWarranties: () => [...assetsQueryKeys.all(), 'warranties'],
  warrantiesOfAsset: (assetId: string) => [
    ...assetsQueryKeys.details(assetId),
    'warranties',
  ],
};
