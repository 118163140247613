import { Center, createStyles, Stack } from '@mantine/core';
import React from 'react';

import { TOUR_STEPS_IDS } from '@portals/framework';

import { OrganizationTree } from './organization-tree/OrganizationTree';
import { OrganizationAvatar } from './OrganizationAvatar';

export function OverviewSidebar() {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.container}
      spacing="xl"
      data-tour-step-id={TOUR_STEPS_IDS.overviewSpaces}
    >
      <Stack align="center" justify="center">
        <Center w={102} h={102}>
          <OrganizationAvatar />
        </Center>
      </Stack>

      <OrganizationTree draggable />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: theme.spacing.xl,
    height: '100%',
    width: '100%',
  },
}));
