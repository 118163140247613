import { AlertModal } from './AlertModal';
import { ChangePasswordModal } from './ChangePasswordModal';
import { ComingSoonIntegrationModal } from './ComingSoonIntegrationModal';
import { ConfirmationModal } from './ConfirmationModal';
import { CreateApiKey } from './CreateApiKey';
import { DateRange } from './date-range/DateRange';
import { EditNoteModal } from './EditNoteModal';
import FeatureFlagsModal from './FeatureFlagsModal';
import { FeaturesNotifications } from './features-notifications/FeaturesNotifications';
import filtersModals from './filters';
import { MarkAsUncollectibleModal } from './Invoice/MarkAsUncollectibleModal';
import { RetryChargeFailureModal } from './Invoice/RetryChargeFailureModal';
import { RetryChargeSuccessModal } from './Invoice/RetryChargeSuccessModal';
import { PremiumCommandLicenseModal } from './license-to-premium-command/PremiumCommandLicenseModal';
import { AuthenticatorAppModal } from './PersonalSettingsModal/AuthenticatorAppModal';
import { EmailMFAModal } from './PersonalSettingsModal/EmailMFAModal';
import { PersonalSettingsModal } from './PersonalSettingsModal/PersonalSettingsModal';
import { RegisteredWithDifferentPortal } from './RegisteredWithDifferentPortal';
import { ReplaceDevice } from './ReplaceDevice';
import ShowHash from './ShowHash';
import { SSHTunnelLoadingModal } from './SSHTunnelLoadingModal';
import { SwitchTenantModal } from './switch-tenant-modal/SwitchTenantModal';
import { TextEditorModal } from './text-editor/TextEditorModal';
import { UploadFile } from './UploadFile';
import { UploadImageModal } from './UploadImageModal/UploadImageModal';

const MODALS = {
  ChangePasswordModal,
  ShowHash,
  ConfirmationModal,
  FeatureFlagsModal,
  UploadImageModal,
  SwitchTenantModal,
  UploadFile,
  DateRange,
  AuthenticatorAppModal,
  TextEditorModal,
  CreateApiKey,
  ReplaceDevice,
  FeaturesNotifications,
  EditNoteModal,
  AlertModal,
  RegisteredWithDifferentPortal,
  RetryChargeSuccessModal,
  RetryChargeFailureModal,
  MarkAsUncollectibleModal,
  PremiumCommandLicenseModal,
  SSHTunnelLoadingModal,
  PersonalSettingsModal,
  EmailMFAModal,
  ComingSoonIntegrationModal,
  ...filtersModals,
};

export { type UploadFileProps } from './UploadFile';
export type { FeatureFlagsModalProps } from './FeatureFlagsModal';
export { type DateRangeProps } from './date-range/DateRange';
export { type TextEditorModalProps } from './text-editor/TextEditorModal';
export { type DateRangeType } from './date-range/types';
export { type CreateApiKeyProps } from './CreateApiKey';
export { type ReplaceDeviceProps } from './ReplaceDevice';
export { type FeaturesNotificationsProps } from './features-notifications/FeaturesNotifications';
export { type EditNoteModalProps } from './EditNoteModal';
export { type UploadImageModalProps } from './UploadImageModal/UploadImageModal';
export { type AlertModalProps } from './AlertModal';
export { type ConfirmationModalProps } from './ConfirmationModal';
export { type RegisteredWithDifferentPortalModalProps } from './RegisteredWithDifferentPortal';
export { type MarkAsUncollectibleModalProps } from './Invoice/MarkAsUncollectibleModal';
export { type PremiumCommandLicenseModalProps } from './license-to-premium-command/PremiumCommandLicenseModal';
export { type SSHTunnelLoadingModalProps } from './SSHTunnelLoadingModal';
export { type EmailMFAModalProps } from './PersonalSettingsModal/EmailMFAModal';
export { type AuthenticatorAppModalProps } from './PersonalSettingsModal/AuthenticatorAppModal';
export { type PersonalSettingsModalProps } from './PersonalSettingsModal/PersonalSettingsModal';
export { type ComingSoonIntegrationModalProps } from './ComingSoonIntegrationModal';

export default MODALS;
