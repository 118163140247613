import React, { useState } from 'react';

import { IncidentType } from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { useConfirmationModal } from '@portals/framework';
import { useHasEntityAccess } from '@portals/framework/EntityAccess';
import { AccessLevelEnum } from '@portals/types';

import { IncidentDetailsPanelBody } from './IncidentDetailsPanelBody';
import { IncidentDetailsPanelHeader } from './IncidentDetailsPanelHeader';

interface IncidentDetailsPanelProps {
  incident: IncidentType;
  onClosePanel: () => void;
}

export function IncidentDetailsPanel({
  incident,
  onClosePanel,
}: IncidentDetailsPanelProps) {
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [incidentDescription, setIncidentDescription] = useState(
    incident.description
  );

  const asyncConfirmationCheck = useConfirmationModal();

  const hasEditAccess =
    useHasEntityAccess(incident, AccessLevelEnum.Edit) &&
    incident.status !== 'closed';

  const handleEditedDescriptionDiscard = async () => {
    const didDescriptionChange = incident.description !== incidentDescription;

    if (!didDescriptionChange) {
      setIsEditingDescription(false);

      return true;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'You have unsaved changes',
      description: 'Would you like to discard your changes?',
      cancelLabel: 'Keep editing',
      confirmationLabel: 'Discard change',
    });

    if (isConfirmed) {
      setIsEditingDescription(false);
      setIncidentDescription(incident.description);
    }

    return isConfirmed;
  };

  return (
    <DetailsPanel>
      <IncidentDetailsPanelHeader
        incident={incident}
        hasEditAccess={hasEditAccess}
        onClosePanel={onClosePanel}
        handleEditedDescriptionDiscard={handleEditedDescriptionDiscard}
      />

      <IncidentDetailsPanelBody
        incident={incident}
        hasEditAccess={hasEditAccess}
        onIncidentDescriptionChange={(value: string) =>
          setIncidentDescription(value)
        }
        handleIsEditingDescription={(value: boolean) =>
          setIsEditingDescription(value)
        }
        isEditingDescription={isEditingDescription}
        incidentDescription={incidentDescription}
        handleEditedDescriptionDiscard={handleEditedDescriptionDiscard}
      />
    </DetailsPanel>
  );
}
