import { createStyles, Group, Paper, Stack } from '@mantine/core';
import React from 'react';

import { AssetType, AssetWarrantyType } from '@portals/api/organizations';
import { FileTypeIcon, TextWithTooltip, TimeProgressBar } from '@portals/core';

import { AssetWarrantyCardMenu } from './AssetWarrantyCardMenu';

interface AssetWarrantyCardProps {
  warranty: AssetWarrantyType;
  assetId: AssetType['id'];
}

export function AssetWarrantyCard({
  warranty,
  assetId,
}: AssetWarrantyCardProps) {
  const { classes } = useStyles();

  return (
    <Paper radius="md" p="xl" withBorder>
      <Stack spacing="sm" className={classes.content}>
        <Group align="flex-start" position="apart">
          <FileTypeIcon height={80} fileUrl={warranty.warranty_document_url} />

          <AssetWarrantyCardMenu warranty={warranty} assetId={assetId} />
        </Group>

        <TextWithTooltip className={classes.title}>
          {warranty.name}
        </TextWithTooltip>

        {warranty.description && (
          <TextWithTooltip
            lineClamp={2}
            className={classes.description}
            tooltipProps={{ width: 300, multiline: true }}
          >
            {warranty.description}
          </TextWithTooltip>
        )}

        <TimeProgressBar
          className={classes.timeProgressBar}
          startDate={warranty.start_date}
          endDate={warranty.end_date}
        />
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
  },
  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
  partnerName: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 600,
    color: theme.colors.gray[5],
  },
  description: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[8],
  },
  timeProgressBar: {
    marginTop: 'auto',
  },
}));
