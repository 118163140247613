import { Button, Text } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout, ModalFooter } from '@portals/core';
import { ModalProps, PartnerAvatar } from '@portals/framework';
import { CecPartnerType } from '@portals/types';

export interface ConnectionSuccessModalProps
  extends ModalProps<{
    cecPartner: CecPartnerType;
    onOpenClaimDevice: VoidFunction;
  }> {}

export function ConnectionSuccessModal({
  closeMe,
  data: { cecPartner, onOpenClaimDevice },
}: ConnectionSuccessModalProps) {
  const onOpenClaimDeviceClick = () => {
    closeMe();
    onOpenClaimDevice();
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={
        <PartnerAvatar
          size={96}
          radius="lg"
          partnerName={cecPartner.display_name}
          src={cecPartner.logo}
        />
      }
      title="Connection Successful! 🎉"
      description={
        <Text>
          You are now connected with{' '}
          <Text span weight={700}>
            {cecPartner.display_name}
          </Text>
          .<br />
          Claim devices from this brand or connect with more brands
          <br />
          to explore new possibilities.
        </Text>
      }
    >
      <ModalFooter pt="xl" grow>
        <Button onClick={closeMe} variant="default">
          Connect more brands
        </Button>

        <Button
          onClick={onOpenClaimDeviceClick}
          data-testid="claim-device-from-cec-oem-button"
        >
          Claim device from this brand
        </Button>
      </ModalFooter>
    </ModalCenteredMediaLayout>
  );
}
