import {
  ActionIcon,
  ActionIconProps,
  createStyles,
  Group,
  Text,
  Tooltip,
  TooltipProps,
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import React, { useRef } from 'react';

import { ReactComponent as CopySuccess } from '@portals/icons/linear/copy-success.svg';
import { ReactComponent as Copy } from '@portals/icons/linear/copy.svg';
import { ReactComponent as TickCircle } from '@portals/icons/linear/tick-circle.svg';
import { suppressPropagation, useOnClickOutside } from '@portals/utils';

interface CopyToClipboardProps {
  timeout?: number;
  value: string | number;
  actionIconProps?: ActionIconProps;
  tooltipProps?: Partial<TooltipProps>;
}

export function CopyToClipboard({
  timeout = 2000,
  value,
  actionIconProps = {},
  tooltipProps = {},
}: CopyToClipboardProps) {
  const { classes, theme } = useStyles();
  const clipboard = useClipboard({ timeout });

  const tooltipContentRef = useRef(null);
  useOnClickOutside(tooltipContentRef, () => clipboard.reset, clipboard.copied);

  return (
    <Tooltip
      opened={clipboard.copied}
      withinPortal
      withArrow
      arrowSize={10}
      position="right"
      classNames={{ tooltip: classes.tooltip }}
      label={
        <Group spacing="xs" p="sm">
          <TickCircle color={theme.colors.teal_accent[4]} />

          <Text color="gray.9">Copied to clipboard</Text>
        </Group>
      }
      {...tooltipProps}
    >
      <Tooltip label="Copy to clipboard" disabled={clipboard.copied}>
        <ActionIcon
          onClick={suppressPropagation(() => clipboard.copy(value))}
          size="sm"
          variant="transparent"
          className={classes.actionIcon}
          {...actionIconProps}
        >
          {clipboard.copied ? (
            <CopySuccess color={theme.colors.teal_accent[4]} />
          ) : (
            <Copy />
          )}
        </ActionIcon>
      </Tooltip>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  tooltip: {
    pointerEvents: 'all',
    padding: 0,
    background: theme.white,
    filter: 'drop-shadow(-4px 4px 36px rgba(0, 2, 41, 0.1))',
  },
  actionIcon: {
    svg: {
      width: 15,
      height: 15,
    },
  },
}));
