import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { TableColumn, TableState } from '@portals/types';

import { ASSETS_API_URL, assetsQueryKeys } from './assets.constants';
import { AssetType, UpdatedAssetType } from './assets.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

export function useAssets(
  tableState: Pick<
    TableState<AssetType>,
    'sortBy' | 'filters' | 'pageSize' | 'pageIndex'
  >,
  columns: Array<TableColumn<AssetType>>,
  baseUrl: string = ASSETS_API_URL,
  queryKey: Array<string> = assetsQueryKeys.all()
) {
  return usePaginatedTableApiQuery<AssetType>({
    baseUrl,
    queryKey: [...queryKey, baseUrl, tableState],
    tableState,
    columns,
  });
}

export function useAsset(
  assetId: string,
  queryOptions?: QueryOptions<AssetType>
) {
  return useApiQuery<AssetType>(
    `${ASSETS_API_URL}/${assetId}`,
    assetsQueryKeys.details(assetId),
    {
      ...queryOptions,
      enabled: !!assetId && (queryOptions?.enabled ?? true),
      cacheTime: 0,
    }
  );
}

export function useCreateAsset() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: ASSETS_API_URL,
    method: 'POST',
  });

  return useMutation<
    AssetType,
    ServerError,
    Omit<UseUpdateAssetParams['asset'], 'id'>
  >({
    mutationFn: (asset) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(asset),
      }),
    onSuccess: () => {
      toastrSuccess('Asset created successfully');

      queryClient.resetQueries(assetsQueryKeys.all());
    },
    onError: (error) => {
      toastrError(error?.error || 'Failed to create asset. Please try again');
    },
    meta: {
      mutationName: 'useCreateAsset',
      baseUrl: ASSETS_API_URL,
      method: 'POST',
    },
  });
}

export interface UseUpdateAssetParams {
  asset: UpdatedAssetType;
  shouldRefetch?: boolean;
}

export function useUpdateAsset() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ASSETS_API_URL,
    method: 'PUT',
  });

  return useMutation<AssetType, ServerError, UseUpdateAssetParams>({
    mutationFn: ({ asset }) =>
      fetchApiRequest(`${url}/${asset.id}`, {
        ...options,
        body: JSON.stringify(asset),
      }),
    onSuccess: (response, { asset, shouldRefetch }) => {
      toastrSuccess('Asset updated successfully');

      if (shouldRefetch) {
        queryClient.resetQueries(assetsQueryKeys.all());
      } else {
        queryClient.setQueryData(assetsQueryKeys.details(asset.id), response);
      }
    },
    onError: (error) => {
      toastrError(error?.error || 'Failed to update asset. Please try again');
    },
    meta: {
      mutationName: 'useUpdateAsset',
      baseUrl: `${ASSETS_API_URL}/:id`,
      method: 'PUT',
    },
  });
}

export function useRemoveAsset() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ASSETS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, string>({
    mutationFn: (assetId) =>
      fetchApiRequest(`${url}/bulk_destroy`, {
        ...options,
        body: JSON.stringify({ ids: [assetId] }),
      }),
    onSuccess: () => {
      toastrSuccess('Asset removed successfully');
    },
    onError: (error) => {
      toastrError(error?.error || 'Failed to remove asset. Please try again');
    },
    onSettled: () => {
      queryClient.resetQueries(assetsQueryKeys.all());
    },
    meta: {
      mutationName: 'useBulkRemove',
      baseUrl: ASSETS_API_URL,
      method: 'DELETE',
    },
  });
}

export function useMoveAsset() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ASSETS_API_URL,
    method: 'POST',
  });

  return useMutation<AssetType, ServerError, { id: string; space_id: number }>({
    mutationFn: ({ id, space_id }) =>
      fetchApiRequest(`${url}/${id}/move`, {
        ...options,
        body: JSON.stringify({ space_id }),
      }),
    onSuccess: (_) => {
      queryClient.resetQueries(assetsQueryKeys.all());
      toastrSuccess('Asset moved successfully');
    },
    onError: (error) => {
      toastrError(error?.error || 'Failed to move asset. Please try again');
    },
    meta: {
      mutationName: 'useMoveAsset',
      baseUrl: `${ASSETS_API_URL}/:id/move`,
      method: 'POST',
    },
  });
}

export function useBulkRemoveAsset() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ASSETS_API_URL,
    method: 'POST',
  });

  return useMutation<void, ServerError, string[]>({
    mutationFn: (assetIds) =>
      fetchApiRequest(`${url}/bulk_destroy`, {
        ...options,
        body: JSON.stringify({ ids: assetIds }),
      }),
    onSuccess: () => {
      toastrSuccess('Assets removed successfully');
    },
    onError: (error) => {
      toastrError(error?.error || 'Failed to remove assets. Please try again');
    },
    onSettled: () => {
      queryClient.resetQueries(assetsQueryKeys.all());
    },
    meta: {
      mutationName: 'useBulkRemove',
      baseUrl: ASSETS_API_URL,
      method: 'DELETE',
    },
  });
}
