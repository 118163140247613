import React from 'react';

import { WizardSteps, WizardTransitionType } from '@portals/framework';

import { SignupWizardContextDataType, StepIdEnum } from './wizard.types';
import { AccessRequest } from '../steps/AccessRequest';
import { Authentication } from '../steps/Authentication';
import { EmailConfirmation } from '../steps/EmailConfirmation';
import { InviteCoWorkers } from '../steps/InviteCoWorkers';
import { OrganizationDoesntExists } from '../steps/OrganizationDoesntExists';
import { OrganizationExists } from '../steps/OrganizationExists';
import { PartnerExists } from '../steps/PartnerExists';
import { SetUpAccount } from '../steps/SetUpAccount';

export const STEPS: WizardSteps<SignupWizardContextDataType, StepIdEnum> = {
  [StepIdEnum.Authentication]: {
    id: StepIdEnum.Authentication,
    component: <Authentication />,
    isFinalStep: false,
    [WizardTransitionType.Next]: {
      transition: { id: StepIdEnum.Confirmation },
      isDisabled: () => false,
    },
    [WizardTransitionType.Prev]: {
      isDisabled: () => true,
    },
  },
  [StepIdEnum.Confirmation]: {
    id: StepIdEnum.Confirmation,
    component: <EmailConfirmation />,
    isFinalStep: false,
    [WizardTransitionType.Prev]: {
      transition: { id: StepIdEnum.Authentication },
      isDisabled: () => false,
    },
  },
  [StepIdEnum.SetUpAccount]: {
    id: StepIdEnum.SetUpAccount,
    component: <SetUpAccount />,
    isFinalStep: false,
    [WizardTransitionType.Next]: {
      transition: [
        {
          id: StepIdEnum.OrganizationDoesntExists,
          condition: ({ contextData }) => !contextData.organizationExists,
        },
        {
          id: StepIdEnum.OrganizationExists,
          condition: ({ contextData }) => !!contextData.organizationExists,
        },
      ],
    },
  },
  [StepIdEnum.AccessRequest]: {
    id: StepIdEnum.AccessRequest,
    component: <AccessRequest />,
    isFinalStep: true,

    [WizardTransitionType.Prev]: {
      transition: { id: StepIdEnum.PartnerExists },
    },
  },
  [StepIdEnum.OrganizationDoesntExists]: {
    id: StepIdEnum.OrganizationDoesntExists,
    component: <OrganizationDoesntExists />,
    isFinalStep: false,
    [WizardTransitionType.Next]: {
      transition: { id: StepIdEnum.InviteCoWorkers },
    },
    [WizardTransitionType.Prev]: {
      transition: { id: StepIdEnum.SetUpAccount },
    },
  },
  [StepIdEnum.OrganizationExists]: {
    id: StepIdEnum.OrganizationExists,
    component: <OrganizationExists />,
    isFinalStep: false,
    [WizardTransitionType.Next]: {
      isDisabled: () => false,
    },
    [WizardTransitionType.Prev]: {
      transition: { id: StepIdEnum.SetUpAccount },
      isDisabled: () => false,
    },
  },
  [StepIdEnum.PartnerExists]: {
    id: StepIdEnum.PartnerExists,
    component: <PartnerExists />,
    isFinalStep: false,
    [WizardTransitionType.Next]: {
      transition: { id: StepIdEnum.AccessRequest },
    },
    [WizardTransitionType.Prev]: {
      transition: { id: StepIdEnum.SetUpAccount },
      isDisabled: () => false,
    },
  },
  [StepIdEnum.InviteCoWorkers]: {
    id: StepIdEnum.InviteCoWorkers,
    component: <InviteCoWorkers />,
    isFinalStep: true,
    [WizardTransitionType.Next]: {
      isDisabled: ({ contextData }) => !contextData.org,
    },
    [WizardTransitionType.Prev]: {
      isDisabled: () => true,
    },
  },
};
