import {
  Badge,
  BadgeProps,
  Button,
  Center,
  createStyles,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps } from '@portals/framework';

import { OrgC2CIntegrationItem } from '../../../hooks/c2c-integrations';

export interface ComingSoonC2CIntegrationModalProps
  extends ModalProps<{
    integration: OrgC2CIntegrationItem;
  }> {}

export function ComingSoonC2cIntegrationModal({
  closeMe,
  data: { integration },
}: ComingSoonC2CIntegrationModalProps) {
  const { classes } = useStyles();

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title={
        <Stack align="center">
          <Badge size="lg" styles={badgeStyles}>
            Coming soon
          </Badge>
          <Text>{integration.name} is coming soon</Text>
        </Stack>
      }
      classNames={{
        header: classes.header,
        headerContent: classes.headerContent,
      }}
      mediaSize={80}
      media={
        <img
          className={classes.integrationLogo}
          src={integration.logo}
          alt={integration.name}
        />
      }
      footer={
        <Center>
          <Button size="md" w={160} onClick={closeMe}>
            Got it
          </Button>
        </Center>
      }
    >
      <Text ta="center">
        Our Customer Support team will inform you once this integration is
        available. Stay tuned!
      </Text>
    </ModalCenteredMediaLayout>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
  },
  headerContent: {
    gap: theme.spacing.xs,
  },
  integrationLogo: {
    borderRadius: theme.radius.lg,
  },
}));

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: theme.fn.rgba(theme.colors.indigo[0], 0.6),
  },
  inner: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    color: theme.colors.gray[9],
  },
});
