import { Button } from '@mantine/core';
import React from 'react';

import { ReactComponent as ElementPlus } from '@portals/icons/linear/element-plus.svg';

import { canAdmin } from '../../../../lib/access';
import { useOverviewContext } from '../overview.context';
import { useCurrentSpace } from '../overview.hooks';

export function EditDashboardButton() {
  const overview = useOverviewContext();

  const currentSpace = useCurrentSpace();

  if (!overview.isAdmin && !canAdmin(currentSpace)) {
    return null;
  }

  return (
    <Button
      size="md"
      variant="default"
      ml="auto"
      onClick={overview.navigateToDashboardConfig}
      leftIcon={<ElementPlus width={16} height={16} />}
      data-testid="edit-dashboard-button"
    >
      Edit dashboard
    </Button>
  );
}
