import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { isEmpty } from 'lodash/fp';
import React from 'react';

import {
  SpaceDetailsType,
  useAddSpaceAccess,
  UserAccessItem,
} from '@portals/api/organizations';
import { useUsers } from '@portals/api/ui';
import { MembersSVG, usersEmptyStateSrc } from '@portals/assets';
import { ModalCenteredMediaLayout } from '@portals/core';
import { ModalProps, UsersSelect, UsersSelectProps } from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { AddExternalUserAccessModalProps } from './AddExternalUserAccessModal';

export interface AddInternalUserAccessModalProps
  extends ModalProps<{
    space: SpaceDetailsType;
  }> {}

const ACCESS_LEVEL = [
  { value: '1', label: 'View' },
  { value: '2', label: 'Edit' },
  { value: '3', label: 'Admin' },
];

export function AddInternalUserAccessModal({
  closeMe,
  data: { space },
}: AddInternalUserAccessModalProps) {
  const users = useUsers();
  const openModal = useOpenModal();

  const addSpaceAccess = useAddSpaceAccess();

  const usersForm = useForm<{ user_id: string; level: string }>();

  const usersOptions = composeUsersOptions(space?.access?.users, users.data);

  const onSubmit = async (values: typeof usersForm.values) => {
    if (!values.user_id || values.level === undefined) return;

    try {
      await addSpaceAccess.mutateAsync({
        spaceId: space.id,
        ...values,
      });

      closeMe();
    } catch (error) {
      console.error(error);
    }
  };

  if (users.isFetched && isEmpty(usersOptions)) {
    return (
      <Modal opened onClose={closeMe}>
        <Stack py="xl" px="sm" spacing="xl">
          <Stack spacing="md">
            <img src={usersEmptyStateSrc} alt="no-users" height={140} />

            <Text size="sm" weight={600} align="center">
              No members to add
            </Text>

            <Stack spacing={0}>
              <Text size="xs" align="center" color="gray.5">
                Looks like there are no internal members to add¬
              </Text>
              <Text size="xs" align="center" color="gray.5">
                You can invite external users to your organization by clicking
                on the "Add External Members”
              </Text>
            </Stack>
          </Stack>

          <Group w="100%" position="center">
            <Button
              variant="subtle"
              onClick={() => {
                closeMe();

                openModal<AddExternalUserAccessModalProps['data']>(
                  'AddExternalUserAccessModal',
                  {
                    space,
                  }
                );
              }}
            >
              Add External Members
            </Button>
          </Group>

          <Group w="100%" position="center" grow>
            <Button variant="default" onClick={closeMe} size="md">
              Close
            </Button>
          </Group>
        </Stack>
      </Modal>
    );
  }

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add Internal Access"
      media={<MembersSVG />}
    >
      <LoadingOverlay visible={users.isLoading || addSpaceAccess.isLoading} />

      {users.isFetched ? (
        <form onSubmit={usersForm.onSubmit(onSubmit)}>
          <Stack spacing="xl">
            <UsersSelect
              withinPortal
              data={usersOptions}
              required
              {...usersForm.getInputProps('user_id')}
            />

            <Select
              withinPortal
              label="Access Level"
              required
              placeholder="Select access level"
              data={ACCESS_LEVEL}
              {...usersForm.getInputProps('level')}
            />

            <Group grow>
              <Button variant="default" onClick={closeMe}>
                Cancel
              </Button>

              <Button type="submit">Add User</Button>
            </Group>
          </Stack>
        </form>
      ) : null}
    </ModalCenteredMediaLayout>
  );
}

/**
 * Filters out users who already have access to the space and returns a list of active users
 * who do not have access yet, formatted for use in a select input.
 */
function composeUsersOptions(
  usersWithAccess: Array<UserAccessItem> = [],
  users: ReturnType<typeof useUsers>['data']
): UsersSelectProps['data'] {
  if (!users) return [];

  const activeUsers = users.filter((user) => !user.deactivated_at);

  const usersWithAccessIds = new Set<string>();
  usersWithAccess.forEach((userWithAccess) =>
    usersWithAccessIds.add(userWithAccess.id)
  );

  return activeUsers
    .filter((user) => !usersWithAccessIds.has(user.id))
    .map((user) => ({
      value: user.id,
      label: user.name,
      email: user?.email,
    }));
}
