import { Box, Button, createStyles, Modal, Stack, Text } from '@mantine/core';
import { includes } from 'lodash/fp';
import React from 'react';

import {
  useCecPartners,
  useOrganizationConfig,
  usePortalConfig,
} from '@portals/api/organizations';
import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import { ModalProps } from '@portals/framework';
import { FeaturesNotificationsProps } from '@portals/framework/modals';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';

export interface AdminWelcomeVideoModalProps extends ModalProps {}

export function AdminWelcomeVideoModal({
  closeMe,
}: AdminWelcomeVideoModalProps) {
  const { classes } = useStyles();
  const config = useOrganizationConfig();
  const openRouteModal = useOpenRouteModal();
  const portalConfig = usePortalConfig();
  const cecPartners = useCecPartners();
  const currentUser = useCurrentUser();
  const openModal = useOpenModal();

  const hasConnectedCecPartners = cecPartners.data?.some(
    (cecPartner) => cecPartner.connected
  );

  const isTrialNotificationDismissed = includes(
    FeatureNotificationEnum.FourteenDaysTrial,
    currentUser?.data?.feature_notifications
  );

  const showCecPartnersModal =
    portalConfig.isFetched &&
    cecPartners.isFetched &&
    portalConfig.data?.cec_enabled &&
    !hasConnectedCecPartners &&
    !isTrialNotificationDismissed;

  const onClose = () => {
    closeMe();

    if (isTrialNotificationDismissed) {
      openModal<FeaturesNotificationsProps['data']>('FeaturesNotifications', {
        featureNotification: FeatureNotificationEnum.FourteenDaysTrial,
      });
    }

    if (showCecPartnersModal) {
      openRouteModal({ modalId: 'connect' });
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      classNames={{
        content: classes.modalContent,
        header: classes.modalHeader,
        body: classes.modalBody,
      }}
    >
      <Box className={classes.videoWrapper}>
        <iframe
          title="welcome-video"
          width="100%"
          height="370"
          src="https://www.youtube.com/embed/9egnq9ko_sE?&mute=1&autoplay=1"
          allowFullScreen
        />
      </Box>

      <Stack spacing="xs" align="center" px="xl">
        <Text size="lg">
          Welcome to {config?.partner_display_name}'s device cloud
        </Text>
        <Text align="center">
          We’re happy you’re here. Discover the platform's full potential in
          just a few minutes. Watch this short video and unlock a world of
          productivity, collaboration, and success.
        </Text>
      </Stack>

      <Box className={classes.modalFooter} p="xl">
        <Button data-testid="video-cancel-button" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  modalContent: {
    backgroundColor: theme.black,
    borderRadius: theme.radius.lg,
    color: theme.colors.gray[2],
  },

  modalBody: {
    padding: 0,
  },

  modalHeader: {
    backgroundColor: theme.black,
  },

  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
  },

  videoWrapper: {
    iframe: {
      border: 'none',
    },
  },
}));
