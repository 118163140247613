import {
  Box,
  Button,
  createStyles,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { Prism } from '@mantine/prism';
import React from 'react';

import {
  DeviceStateType,
  DeviceType,
  useDeviceLastKnownState,
} from '@portals/api/organizations';
import { DeviceStatusBadge } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { VerticalScrollBar } from '@portals/scrollbar';
import { NoDataState } from '@portals/table';
import { formatDateTime } from '@portals/utils';

import { ExportDeviceStateHistoryModalProps } from '../../../../modals';

interface DeviceStateWrapperProps {
  device: DeviceType;
}

export function DeviceStateWrapper({ device }: DeviceStateWrapperProps) {
  const deviceLastKnownState = useDeviceLastKnownState(device?.id, {
    enabled: device.status !== 'online',
  });

  if (deviceLastKnownState.isInitialLoading) {
    return <LoadingOverlay visible />;
  }

  const deviceState =
    device.status === 'online'
      ? device.state
      : deviceLastKnownState.data?.state;

  return (
    <DeviceState
      deviceId={device.id}
      deviceState={deviceState}
      deviceStatus={device.status}
      deviceStateUpdatedDate={deviceLastKnownState.data?.updated_at}
    />
  );
}

interface DeviceStateProps {
  deviceId: DeviceType['id'];
  deviceState: DeviceType['state'] | undefined;
  deviceStatus: DeviceType['status'];
  deviceStateUpdatedDate: DeviceStateType['updated_at'] | undefined;
}

function DeviceState({
  deviceId,
  deviceState,
  deviceStatus,
  deviceStateUpdatedDate,
}: DeviceStateProps) {
  const openModal = useOpenModal();

  const { classes } = useStyles();

  const displayLastKnowStateInfo =
    deviceStatus === 'offline' || deviceStatus === 'unavailable';

  return (
    <Stack className={classes.stackContainer}>
      {displayLastKnowStateInfo ? (
        <Paper className={classes.topPaper} withBorder radius="md">
          <Group>
            <DeviceStatusBadge status={deviceStatus} />

            <Text>
              Device went {deviceStatus} at{' '}
              {formatDateTime(
                deviceStateUpdatedDate,
                'M/DD/YYYY hh:mm A z',
                'an unknown timestamp',
                'UTC'
              )}
            </Text>
          </Group>
        </Paper>
      ) : null}

      <div className={classes.container}>
        <Paper className={classes.paper} radius="md" withBorder>
          <Group position={displayLastKnowStateInfo ? 'apart' : 'right'}>
            {displayLastKnowStateInfo ? (
              <Group>
                <Text span> Showing last known state </Text>

                <Text span color="gray.5">
                  {formatDateTime(
                    deviceStateUpdatedDate,
                    'M/DD/YYYY hh:mm A z',
                    'timestamp is unknown',
                    'UTC'
                  )}
                </Text>
              </Group>
            ) : null}

            <Button
              className={classes.button}
              disabled={!deviceState}
              data-testid="export-to-csv-button"
              variant="default"
              onClick={() => {
                openModal<ExportDeviceStateHistoryModalProps['data']>(
                  'ExportDeviceStateHistoryModal',
                  {
                    deviceId,
                    lastKnownState: deviceState,
                  }
                );
              }}
            >
              Export to CSV
            </Button>
          </Group>

          <Divider my={32} color="gray.2" />

          <DeviceStateDetails deviceState={deviceState} />
        </Paper>
      </div>
    </Stack>
  );
}

interface DeviceStateDetailsProps {
  deviceState: DeviceType['state'] | undefined;
}

function DeviceStateDetails({ deviceState }: DeviceStateDetailsProps) {
  const { classes } = useStyles();

  if (!deviceState) {
    return (
      <NoDataState title="Awaiting for device to start sending telemetries" />
    );
  }

  return (
    <VerticalScrollBar
      renderView={(props) => <Box className={classes.box} {...props} />}
    >
      <Prism withLineNumbers language="json">
        {JSON.stringify(deviceState, null, 2)}
      </Prism>
    </VerticalScrollBar>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    paddingBlock: theme.spacing.md,
  },

  paper: {
    padding: 32,
    height: '100%',
    color: theme.colors.blue_gray[6],
  },

  topPaper: {
    padding: '32px 40px',
    marginTop: 10,
    color: theme.colors.blue_gray[6],
  },

  stackContainer: {
    height: '100%',
    paddingRight: 15,
    color: theme.colors.blue_gray[6],
  },

  box: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.lg,
  },

  button: {
    alignSelf: 'flex-end',
  },
}));
