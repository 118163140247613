import { MantineColor } from '@mantine/core';
import { capitalize, isNull, isUndefined } from 'lodash/fp';

import { DeviceStatusType } from '@portals/types';

export function getDeviceStatusColor(
  deviceStatus: DeviceStatusType | undefined
): {
  baseColor: MantineColor;
  bg: MantineColor;
  indicator: MantineColor;
  darkIndicator: MantineColor;
} {
  switch (deviceStatus) {
    case 'online':
      return {
        baseColor: 'teal',
        bg: 'teal.0',
        indicator: 'teal_accent.4',
        darkIndicator: 'teal_accent.3',
      };

    case 'offline':
      return {
        baseColor: 'gray',
        bg: 'gray.1',
        indicator: 'gray.4',
        darkIndicator: 'gray.4',
      };

    case 'error':
      return {
        baseColor: 'red',
        bg: 'red.0',
        indicator: 'red.4',
        darkIndicator: 'red_accent.2',
      };

    case 'unavailable':
    default:
      return {
        baseColor: 'amber',
        bg: 'amber.0',
        indicator: 'amber.7',
        darkIndicator: 'amber_accent.4',
      };
  }
}

export function getDeviceStatusText(
  deviceStatus: DeviceStatusType | undefined
) {
  return deviceStatus ? capitalize(deviceStatus) : 'N/A';
}

export function getDeviceName(deviceName: string | null | undefined) {
  return deviceName || 'Unknown';
}

interface DeviceConfigStatusTextParams {
  configVersion: number | null | undefined;
  appliedConfigVersion: number | null | undefined;
}

export function getDeviceConfigStatusText({
  configVersion,
  appliedConfigVersion,
}: DeviceConfigStatusTextParams) {
  if (
    isNull(configVersion) ||
    isNull(appliedConfigVersion) ||
    isUndefined(configVersion) ||
    isUndefined(appliedConfigVersion)
  ) {
    return 'Unknown';
  }

  if (configVersion === appliedConfigVersion) {
    return 'Up to date';
  }

  const versionsDiff = configVersion - appliedConfigVersion;

  return `${versionsDiff} versions behind`;
}

export const DEVICE_STATUS_METADATA = {
  online: {
    id: 'online',
    displayName: 'Online',
    color: 'teal_accent.4',
  },
  offline: {
    id: 'offline',
    displayName: 'Offline',
    color: 'gray.4',
  },
  error: {
    id: 'error',
    displayName: 'Error',
    color: 'red.4',
  },
  unavailable: {
    id: 'unavailable',
    displayName: 'Unavailable',
    color: 'amber.7',
  },
} satisfies Record<
  DeviceStatusType,
  { id: DeviceStatusType; displayName: string; color: MantineColor }
>;

export function getDeviceStatusNames() {
  return Object.keys(DEVICE_STATUS_METADATA) as DeviceStatusType[];
}

export function getDeviceStatusList() {
  return Object.values(DEVICE_STATUS_METADATA);
}
