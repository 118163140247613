import {
  Box,
  Button,
  createStyles,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { useStartFreeTrial } from '@portals/api/organizations';
import { cecNoticeSrc } from '@portals/assets';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

export interface CeCTrialAnnouncementModalProps
  extends ModalProps<{
    onContinueWithTrial: VoidFunction;
  }> {}

export function CeCTrialAnnouncementModal({
  data: { onContinueWithTrial },
  closeMe,
}: CeCTrialAnnouncementModalProps) {
  const { classes } = useStyles();
  const startFreeTrial = useStartFreeTrial();

  const onConfirmTrial = () => {
    startFreeTrial.mutate(undefined, {
      onSuccess: () => {
        onContinueWithTrial();
        closeMe();
      },
    });
  };

  return (
    <Modal
      onClose={closeMe}
      opened
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      padding={0}
      size={650}
    >
      <img src={cecNoticeSrc} height="auto" width="100%" />

      <Stack justify="center" p="xxl" pb="md">
        <Text ta="center" fz={24}>
          Thank you for joining Xyte Connect+
        </Text>

        <Stack>
          <Text ta="center" fz="md">
            You're now set to manage devices from multiple brands in a single
            interface.
          </Text>

          <Paper
            radius="xl"
            p="sm"
            sx={(theme) => ({
              border: `2px solid transparent`,
              background: `linear-gradient(${theme.white} 0 0) padding-box, linear-gradient(91.93deg, ${theme.colors.blue_accent[4]} -0.01%, ${theme.colors.pink[4]} 99.99%) border-box`,
            })}
          >
            <Group noWrap>
              <Box className={classes.trialDays}>
                <Text ta="center" c="gray.9" fz={24} fw={200}>
                  14
                </Text>

                <Text ta="center" c="gray.9">
                  day trial
                </Text>
              </Box>

              <Text>
                Your 14-day free trial starts now. After the trial, each
                connected device will be billed starting at $1.99. Volume
                pricing is available; our team will reach out with more details.
              </Text>
            </Group>
          </Paper>
        </Stack>
      </Stack>

      <ModalFooter p="xl" position="center" noWrap>
        <Button
          fullWidth
          variant="default"
          onClick={closeMe}
          data-testid="not-now-button"
        >
          Not now
        </Button>

        <Button fullWidth onClick={onConfirmTrial}>
          Let's start!
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  trialDays: {
    backgroundColor: theme.colors.gray[2],
    borderRadius: theme.radius.xl,
    borderColor: `rgba(41, 121, 255, 1)`,
    paddingBlock: theme.spacing.sm,
    paddingInline: theme.spacing.xs,
    minWidth: 70,
    height: 70,
  },
}));
