import React from 'react';

import { OverviewConfigPanel } from './OverviewConfigPanel';
import { OverviewHeaderPanel } from './OverviewHeaderPanel';
import { useOverviewContext } from '../../overview.context';

export function PanelWrapper() {
  const overview = useOverviewContext();

  if (overview.isConfigMode) {
    return <OverviewConfigPanel />;
  }

  return <OverviewHeaderPanel />;
}
