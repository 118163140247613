import React from 'react';

import { SpaceFormWrapper } from './SpaceFormWrapper';
import { useCurrentSpace } from '../../../overview.hooks';
import { OVERVIEW_SETTINGS_TABS } from '../../settings-drawer.constants';
import { OverviewSettingsTabEnum } from '../../settings-drawer.types';
import { SettingsTabContainer } from '../tabs.common';

export function Space() {
  const space = useCurrentSpace();

  return (
    <SettingsTabContainer
      tab={OVERVIEW_SETTINGS_TABS[OverviewSettingsTabEnum.General]}
    >
      <SpaceFormWrapper space={space} />
    </SettingsTabContainer>
  );
}
