import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { PaginationResponse } from '@portals/types';

import { devicesQueryKeys, getDeviceApiUrl } from './devices.constants';
import { CreateDeviceWarranty } from './devices.types';
import { fetchApiRequest, useRequestOptions } from '../../utils/common';
import { buildUrlFromFilters } from '../../utils/paginated-query';
import { DeviceWarrantyType } from '../warranties';

function getApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/warranties`;
}

export function useDeviceWarranties(deviceId: string) {
  const { url, options } = useRequestOptions({ url: getApiUrl(deviceId) });

  const requestUrl = buildUrlFromFilters<DeviceWarrantyType>({
    url,
    pagination: {
      page: 0,
      pageSize: 50,
    },
  });

  return useQuery<
    PaginationResponse<DeviceWarrantyType>,
    { error: string },
    DeviceWarrantyType[]
  >({
    queryKey: devicesQueryKeys.warranties.list(deviceId),
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => response.data,
    meta: {
      baseUrl: `${getDeviceApiUrl(':id')}/warranties`,
      method: 'GET',
    },
  });
}

export function useCreateDeviceWarranty(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getApiUrl(deviceId),
    method: 'POST',
  });

  return useMutation({
    mutationFn: (warranty: CreateDeviceWarranty) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(warranty),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Warranty uploaded successfully'));

      queryClient.invalidateQueries(devicesQueryKeys.warranties.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useCreateDeviceWarranty',
      baseUrl: `${getDeviceApiUrl(':id')}/warranties`,
      method: 'POST',
    },
  });
}

export function useUpdateDeviceWarranty(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getApiUrl(deviceId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (warranty: DeviceWarrantyType) => {
      return fetchApiRequest(`${url}/${warranty.id}`, {
        ...options,
        body: JSON.stringify(warranty),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Warranty updated successfully'));

      queryClient.invalidateQueries(devicesQueryKeys.warranties.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateDeviceWarranty',
      baseUrl: `${getDeviceApiUrl(':id')}/warranties/:id`,
      method: 'PUT',
    },
  });
}

export function useDeleteDeviceWarranty(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getApiUrl(deviceId),
    method: 'DELETE',
  });

  return useMutation({
    mutationFn: (warrantyId: string) => {
      return fetchApiRequest(`${url}/${warrantyId}`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Warranty deleted successfully'));

      queryClient.invalidateQueries(devicesQueryKeys.warranties.global);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useDeleteDeviceWarranty',
      baseUrl: `${getDeviceApiUrl(':id')}/warranties/:id`,
      method: 'DELETE',
    },
  });
}
