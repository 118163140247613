import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  Paper,
  SelectProps,
  Stack,
  Text,
  Textarea,
  TextareaProps,
} from '@mantine/core';
import React from 'react';

import {
  IncidentClosedMethodEnum,
  IncidentType,
  useUpdateIncident,
} from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import {
  CustomerAvatarAndName,
  DetailsList,
  DetailsListProps,
  PriorityIcon,
} from '@portals/framework';
import { RouteModalLink } from '@portals/framework/route-modals';
import { ReactComponent as BoldTicketCircleIcon } from '@portals/icons/bold/tick-circle.svg';
import { ReactComponent as EditIcon } from '@portals/icons/linear/edit-3.svg';
import { IncidentPriorityLevel } from '@portals/types';
import { formatDateTime, getIncidentPriorityLevelName } from '@portals/utils';

import { ExpandableContainer } from './ExpandableContainer';
import { IncidentDetailsPanelSection } from './IncidentDetailsPanelSection';
import { AssigneeSelect } from '../../pages/incidents/AssigneeSelect';
import { SpacePath } from '../../pages/overview/overview-dashboard/widgets/incidents/SpacePath';
import { PriorityLevelSelect } from '../PriorityLevelSelect';

function getClosedMethod(incident: IncidentType) {
  if (incident.closed_method === IncidentClosedMethodEnum.Manually) {
    return 'manually';
  } else if (
    incident.closed_method === IncidentClosedMethodEnum.Automatically
  ) {
    return 'automatically';
  } else if (incident.closed_method === IncidentClosedMethodEnum.Integration) {
    return 'via integration';
  } else {
    return 'by device';
  }
}

interface IncidentDetailsPanelBodyProps {
  incident: IncidentType;
  hasEditAccess: boolean;
  onIncidentDescriptionChange: (value: string) => void;
  handleIsEditingDescription: (value: boolean) => void;
  isEditingDescription: boolean;
  incidentDescription: string;
  handleEditedDescriptionDiscard: () => Promise<boolean>;
}

export function IncidentDetailsPanelBody({
  incident,
  hasEditAccess,
  onIncidentDescriptionChange,
  handleIsEditingDescription,
  isEditingDescription,
  incidentDescription,
  handleEditedDescriptionDiscard,
}: IncidentDetailsPanelBodyProps) {
  const { classes, cx, theme } = useStyles();

  const updateIncident = useUpdateIncident();

  const isIncidentResolved = incident.status === 'closed';

  const detailsList: DetailsListProps['items'] = [
    {
      label: 'Customer',
      value: <CustomerAvatarAndName name={incident.customer_name} size={20} />,
    },
    {
      label: 'Space',
      value: <SpacePath spacePath={incident.space_tree_path_name} />,
    },
    {
      label: 'Device',
      value: (
        <RouteModalLink
          modalId="device"
          fw={400}
          pathParams={[incident.device_id]}
        >
          {incident.device_name}
        </RouteModalLink>
      ),
    },
    {
      label: 'Model',
      value: incident.device_model,
    },

    {
      label: 'Manufacturer',
      value: incident.partner_display_name,
    },
    {
      label: 'Last Updated',
      value: formatDateTime(incident.created_at),
    },
  ];

  const handlePriorityLevelSelectChange = (value: IncidentPriorityLevel) => {
    updateIncident.mutate({
      deviceId: incident.device_id,
      incidentId: incident.id,
      incident: {
        priority: value,
      },
    });
  };

  const enableEdit = () => handleIsEditingDescription(true);

  const handleEditDescriptionSave = () =>
    updateIncident.mutate(
      {
        deviceId: incident.device_id,
        incidentId: incident.id,
        incident: {
          description: incidentDescription,
        },
      },
      {
        onSuccess: () => handleIsEditingDescription(false),
      }
    );

  return (
    <DetailsPanel.Body spacing="md" pt="md">
      <IncidentDetailsPanelSection title="Priority">
        {hasEditAccess ? (
          <PriorityLevelSelect
            value={incident.priority}
            onChange={handlePriorityLevelSelectChange}
            styles={selectStyles}
            data-analyticsid="drawer-incident-priority-select"
          />
        ) : (
          <Group py="xs">
            <PriorityIcon priorityLevel={incident.priority} iconSize={18} />
            <Text>{getIncidentPriorityLevelName(incident.priority)}</Text>
          </Group>
        )}
      </IncidentDetailsPanelSection>
      <IncidentDetailsPanelSection title="Assigned to">
        <AssigneeSelect
          incident={incident}
          avatarSize={25}
          styles={selectStyles}
          data-testid="incident-assignee"
          data-analyticsid="drawer-incident-assignee-select"
        />
      </IncidentDetailsPanelSection>

      <IncidentDetailsPanelSection
        px={0}
        titleGroupProps={{ px: 'md' }}
        title="Description"
        actionIcon={
          hasEditAccess &&
          !isEditingDescription && (
            <ActionIcon
              onClick={enableEdit}
              data-testid="edit-description-button"
              data-analyticsid="drawer-incident-edit-description-button"
            >
              <EditIcon width={16} height={16} />
            </ActionIcon>
          )
        }
      >
        <Stack
          px="md"
          pb={isIncidentResolved ? 'xs' : 'initial'}
          data-testid="description-preview"
        >
          {isEditingDescription ? (
            <Textarea
              data-testid="incident-description-textarea"
              value={incidentDescription}
              onChange={(e) => onIncidentDescriptionChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  handleEditedDescriptionDiscard();
                }
              }}
              placeholder="No description"
              styles={editableDescriptionStyles}
              minRows={2}
              maxRows={8}
              autosize
            />
          ) : (
            <ExpandableContainer maxHeight={230}>
              <Text
                className={cx(classes.detailsText, {
                  [classes.emptyDetailsText]: !incidentDescription,
                })}
              >
                {incidentDescription || 'No description'}
              </Text>
            </ExpandableContainer>
          )}

          {isEditingDescription && (
            <Group position="apart" spacing="sm" noWrap>
              <Button
                variant="white"
                fullWidth
                onClick={handleEditedDescriptionDiscard}
              >
                Discard
              </Button>

              <Button
                fullWidth
                onClick={handleEditDescriptionSave}
                loading={updateIncident.isLoading}
                data-testid="save-description-button"
              >
                Save
              </Button>
            </Group>
          )}
        </Stack>

        {isIncidentResolved && (
          <Paper px="md" className={classes.resolveReasonContainer}>
            <Group align="start" noWrap>
              <span>
                <BoldTicketCircleIcon
                  color={theme.fn.themeColor('teal_accent.4')}
                  width={24}
                  height={24}
                />
              </span>

              <Stack mt={4}>
                <Stack spacing="xs">
                  <Text color="gray.9" data-testid="resolve-by-title">
                    Resolved {getClosedMethod(incident)}
                    {incident.closed_by && (
                      <Text span color="gray.7">
                        {' '}
                        (by {incident.closed_by.name})
                      </Text>
                    )}
                  </Text>

                  <Text color="gray.5" size="xs">
                    {formatDateTime(incident.closed_at)}
                  </Text>
                </Stack>

                {incident.closed_reason && (
                  <Text
                    className={classes.resolveReasonText}
                    data-testid="resolve-reason-preview"
                  >
                    {incident.closed_reason}
                  </Text>
                )}
              </Stack>
            </Group>
          </Paper>
        )}
      </IncidentDetailsPanelSection>

      <IncidentDetailsPanelSection title="Information">
        <DetailsList items={detailsList} />
      </IncidentDetailsPanelSection>
    </DetailsPanel.Body>
  );
}

const useStyles = createStyles((theme) => ({
  resolveReasonContainer: {
    backgroundColor: theme.colors.gray[1],
    borderRadius: `0 0 ${theme.radius.lg}`,
    borderTop: '2px solid white',

    paddingTop: theme.spacing.md,
    paddingBottom: 0,
  },
  resolveReasonText: {
    whiteSpace: 'pre-wrap',
  },
  emptyDetailsText: {
    color: theme.colors.gray[5],
  },
  detailsText: {
    whiteSpace: 'pre-wrap',
  },
}));

const selectStyles: SelectProps['styles'] = (theme) => ({
  input: {
    borderColor: theme.colors.gray[3],
    backgroundColor: 'transparent',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  item: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
  },
});

const editableDescriptionStyles: TextareaProps['styles'] = (theme) => ({
  input: {
    padding: theme.spacing.md,
  },
});
