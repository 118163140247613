import { filter, uniq } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { useSpace } from '@portals/api/organizations';
import { expandNodesByDepth, searchTree } from '@portals/utils';

import { OverviewSettingsTabEnum } from './overview-settings-drawer/settings-drawer.types';
import { useSpacesTree } from '../../../lib/spaces';
import { OVERVIEW_PATHS } from '../../routes';

export function useOverviewRouteParams() {
  return useParams<{ spaceId: string; settingsTab: OverviewSettingsTabEnum }>();
}

export function useCurrentSpace(
  queryOptions?: Parameters<typeof useSpace>[0]['queryOptions']
) {
  const params = useOverviewRouteParams();

  return useSpace({ spaceId: Number(params.spaceId), queryOptions });
}

export function useOverviewOrganizationTree() {
  const tree = useSpacesTree();

  const currentSpace = useCurrentSpace();

  // Load expanded nodes from local storage if exists
  const [persistedExpandedNodes, setPersistedExpandedNodes] = useLocalStorage<
    number[]
  >('spacesTree.expandedNodes', expandNodesByDepth(tree, 3));

  const [expandedNodes, setExpandedNodes] = useState(
    uniq([...(persistedExpandedNodes || []), ...(currentSpace?.path || [])])
  );

  useEffect(
    function updatePersistedExpandedNodes() {
      // Remove nodes that are not present in the tree anymore
      const filteredNodes = filter(
        (nodeId) => Boolean(searchTree(tree[0], nodeId)),
        expandedNodes
      );

      setPersistedExpandedNodes(filteredNodes);
    },
    [tree, expandedNodes, setPersistedExpandedNodes]
  );

  return {
    tree,
    expandedNodes,
    setExpandedNodes,
  };
}

export function useCurrentOverviewTab() {
  const match = useMatch({
    path: `${OVERVIEW_PATHS.space}/:overviewTab`,
    end: false,
  });

  return match?.params.overviewTab;
}
