import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { devicesEmptyStateSrc } from '@portals/assets';

import { useOverviewDevicesContext } from './OverviewDevicesProvider';
import { ClaimDeviceButton } from '../../../components/ClaimDeviceButton';

export function OverviewDevicesEmptyState() {
  const { classes } = useStyles();

  const { space } = useOverviewDevicesContext();

  return (
    <Stack className={classes.container}>
      <img src={devicesEmptyStateSrc} width={400} height="auto" />

      <Stack spacing={6} fw={500}>
        <Text>No devices yet</Text>

        <Text fz="xs" c="gray.5">
          It looks like you haven’t added any devices to your account.
          <br />
          Once you add devices, they’ll appear here for easy management.
        </Text>
      </Stack>

      <ClaimDeviceButton spaceId={space.data?.id}>Add device</ClaimDeviceButton>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing.xl,
    textAlign: 'center',
  },
}));
