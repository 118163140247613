import { Group } from '@mantine/core';
import React from 'react';

import { SearchInput } from '@portals/core';

import { DeviceFilters } from './device-filters/DeviceFilters';
import { DevicesBulkActions } from './DevicesBulkActions';
import { useOverviewDevicesContext } from './OverviewDevicesProvider';

export function DevicesControls() {
  const overviewDevices = useOverviewDevicesContext();

  return (
    <Group position="apart">
      <Group>
        <SearchInput
          size="sm"
          defaultValue={overviewDevices.searchTerm}
          onChange={(e) => overviewDevices.setSearchTerm(e.target.value)}
          onClear={() => overviewDevices.setSearchTerm('')}
        />

        {overviewDevices.selectedDeviceIds.size > 0 && <DevicesBulkActions />}
      </Group>

      <DeviceFilters />
    </Group>
  );
}
