import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { AssetType } from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-3.svg';

interface AssetsCommentsSectionProps {
  isEditable: boolean;
  asset: AssetType;
  onChange: (comments: string) => void;
}

export function AssetsCommentsSection({
  asset,
  isEditable,
  onChange,
}: AssetsCommentsSectionProps) {
  const { classes } = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [comments, setComments] = useState(asset?.comments);

  function onDiscard() {
    setComments(asset?.comments);
    setIsEdit(false);
  }

  function onSubmit() {
    onChange(comments);
    setIsEdit(false);
  }

  useUpdateEffect(() => {
    if (asset?.comments !== comments) {
      setComments(asset.comments);
    }
  }, [asset?.comments]);

  function getContent() {
    if (isEditable && isEdit) {
      return (
        <Stack spacing="xs">
          <Textarea
            data-autofocus
            autoFocus
            value={comments}
            className={classes.textArea}
            onChange={(event) => setComments(event.currentTarget.value)}
            placeholder="Add comment"
          />

          <SimpleGrid cols={2} spacing="xs">
            <Button variant="default" onClick={onDiscard} size="xs">
              Discard
            </Button>

            <Button onClick={onSubmit} size="xs">
              Save
            </Button>
          </SimpleGrid>
        </Stack>
      );
    }

    return asset?.comments || <Text>No comments</Text>;
  }

  return (
    <Box pos="relative">
      {isEdit ? null : (
        <ActionIcon
          className={classes.editIcon}
          onClick={() => setIsEdit((isEdit) => !isEdit)}
        >
          <Edit width={16} height={16} />
        </ActionIcon>
      )}

      <DetailsPanel.Section
        title="Comments"
        content={getContent()}
        className={classes.sectionWrapper}
      />
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  sectionWrapper: {
    backgroundColor: theme.colors.gray[1],
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
  },
  editIcon: {
    zIndex: 1,
    position: 'absolute',
    right: theme.spacing.sm,
    top: theme.spacing.sm,
  },
  textArea: {
    paddingInline: 0,
    border: 'none',
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 0,
  },
}));
