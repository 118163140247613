import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';

import { ServerError } from '@portals/api';
import {
  ConnectorExtraBrightSignType,
  ConnectorType,
  useSetConnectorParams,
} from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';

export interface BrightSignConnectorSettingsModalProps
  extends ModalProps<{
    connector: ConnectorType;
  }> {}

export function BrightSignConnectorSettingsModal({
  closeMe,
  data,
}: BrightSignConnectorSettingsModalProps) {
  const [networksList, setNetworksList] = useState<string[] | null>(null);
  const [error, setError] = useState<ServerError | null>(null);

  const setConnectorParams =
    useSetConnectorParams<ConnectorExtraBrightSignType>();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      network: '',
    },
  });

  function onAuthorize(values: typeof form.values) {
    if (!networksList) {
      setError(null);

      setConnectorParams.mutate(
        {
          connectorId: data.connector.id,
          connectorParams: {
            email: values.email,
            password: values.password,
          },
        },
        {
          onSuccess: (connector) => {
            setNetworksList(connector.extra.networks);
          },
          onError: (error) => {
            setError(error);
          },
        }
      );
    } else {
      setError(null);

      setConnectorParams.mutate(
        {
          connectorId: data.connector.id,
          connectorParams: {
            network: values.network,
            import: true,
          },
        },
        {
          onSuccess: () => {
            closeMe();
          },
          onError: (error) => {
            setError(error);
          },
        }
      );
    }
  }

  return (
    <Modal
      radius="lg"
      onClose={closeMe}
      opened={true}
      size="lg"
      title="BrightSign Authentication"
      styles={(theme) => ({
        content: {
          padding: theme.spacing.xxl,
        },
      })}
    >
      <LoadingOverlay visible={setConnectorParams.isLoading} />

      <Stack>
        <Text size="sm">
          Please enter the credentials for the customer's BSN.cloud Control
          Cloud account.
        </Text>

        <form onSubmit={form.onSubmit(onAuthorize)}>
          <Stack spacing="xxl">
            {networksList ? (
              <Select
                label="Network"
                placeholder="Select network"
                data={networksList}
                withinPortal
                dropdownPosition="bottom"
                {...form.getInputProps('network')}
                data-testid="select-network-dropdown"
              />
            ) : (
              <Stack>
                <TextInput
                  w="100%"
                  data-autofocus
                  required
                  {...form.getInputProps('email')}
                  label="Email"
                  placeholder="Enter email"
                  data-testid="email-text-area"
                />

                <TextInput
                  w="100%"
                  required
                  type="password"
                  label="Password"
                  placeholder="Enter password"
                  {...form.getInputProps('password')}
                  data-testid="password-input"
                />
              </Stack>
            )}

            {error && (
              <Text color="red" size="sm" data-testid="error-message">
                {error.error}
              </Text>
            )}

            <Group position="right">
              <Button onClick={closeMe} variant="default">
                Cancel
              </Button>
              <Button type="submit" data-testid="authorize-button">
                Authorize
              </Button>
            </Group>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
}
