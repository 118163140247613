import { Button, LoadingOverlay } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  C2CConnectorName,
  SUPPORTED_C2C_CONNECTORS,
  useConnectors,
  useOrganizationConfig,
  useSendInternalSupportEmail,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { C2CIntegrationId } from '@portals/framework';
import { useIntegrations, useOpenModal } from '@portals/redux';

import { CecConnectionCard } from './CecConnectionCard';
import {
  OrgC2CIntegrationItem,
  useIntegrationsList,
} from '../../../hooks/c2c-integrations';
import { useOpenTrialAnnouncementModal } from '../../../hooks/trial-announcement';
import {
  AddC2cConnectorModalProps,
  ComingSoonC2CIntegrationModalProps,
  ThirdPartyC2CConnectorsModalProps,
} from '../../modals';

interface C2CIntegrationsProps {
  searchTerm: string;
}

function isC2CConnector(
  connectorName: C2CConnectorName | C2CIntegrationId
): connectorName is C2CConnectorName {
  const connector = SUPPORTED_C2C_CONNECTORS.find(
    (connector) => connector === connectorName
  );

  return connector !== undefined;
}

export function C2CIntegrations({ searchTerm }: C2CIntegrationsProps) {
  const organizationConfig = useOrganizationConfig();
  const currentUser = useCurrentUser();
  const openModal = useOpenModal();

  const navigate = useNavigate();

  const integrations = useIntegrations();
  const integrationsList = useIntegrationsList();

  const connectors = useConnectors();
  const sendSupportEmail = useSendInternalSupportEmail();

  const openTrialAnnouncementModal = useOpenTrialAnnouncementModal();

  function onAskToConnect(integration: OrgC2CIntegrationItem) {
    sendSupportEmail.mutate(
      {
        title: `${organizationConfig?.name} is asking to integrate with ${integration.name}`,
        message: `Dear Support team member,

${organizationConfig?.name} has asked to integrate with ${integration.name}.
Please get back to the requesting user: ${currentUser.data?.name} at ${currentUser.data?.email} to understand their need.

The Xyte team`,
      },
      {
        onSuccess: () => {
          openModal<ComingSoonC2CIntegrationModalProps['data']>(
            'ComingSoonC2cIntegrationModal',
            { integration }
          );
        },
      }
    );
  }

  function openThirdPartyConnectorsModal(integration: OrgC2CIntegrationItem) {
    openModal<ThirdPartyC2CConnectorsModalProps['data']>(
      'ThirdPartyC2CConnectorsModal',
      {
        integration,
        onAskToConnect: () => onAskToConnect(integration),
        onConnectWithThirdParty: (connectorName: C2CConnectorName) =>
          openC2cConnectorModal(connectorName),
      }
    );
  }

  function openC2cConnectorModal(connectorName: C2CConnectorName) {
    openModal<AddC2cConnectorModalProps['data']>('AddC2cConnectorModal', {
      connectorName,
    });
  }

  function onConnectClick(integration: OrgC2CIntegrationItem) {
    if (integration.isComingSoon) {
      onAskToConnect(integration);
    } else if (integration.thirdPartyConnectors) {
      openThirdPartyConnectorsModal(integration);
    } else if (isC2CConnector(integration.id)) {
      openC2cConnectorModal(integration.id);
    } else if (integration.href) {
      navigate(integration.href);
    }
  }

  const filteredIntegrations = useMemo(() => {
    if (searchTerm === '') return integrationsList;

    const lowerSearchTerm = searchTerm.toLowerCase();

    return integrationsList.filter((integration) => {
      const searchableContent =
        `${integration.name}__@@__${integration.description}`.toLowerCase();

      return searchableContent.includes(lowerSearchTerm);
    });
  }, [integrationsList, searchTerm]);

  if (filteredIntegrations.length === 0) {
    return null;
  }

  function getIsConnected(integrationId: C2CIntegrationId) {
    if (isC2CConnector(integrationId)) {
      const activeConnector = find(
        (connector) =>
          connector.state === 'active' && connector.name === integrationId,
        connectors.data
      );

      return activeConnector !== undefined;
    }

    return integrations?.[integrationId]?.state === 'active';
  }

  return (
    <>
      <LoadingOverlay visible={sendSupportEmail.isLoading} />

      {filteredIntegrations.map((integration) => (
        <CecConnectionCard
          key={integration.id}
          id={integration.id}
          logo={integration.logo}
          title={integration.name}
          description={integration.description}
          isComingSoon={integration.isComingSoon}
          isConnected={getIsConnected(integration.id)}
          searchTerm={searchTerm}
          action={
            <Button
              onClick={() =>
                openTrialAnnouncementModal({
                  onContinueWithTrial: () => onConnectClick(integration),
                })
              }
              data-testid={
                integration.isComingSoon
                  ? 'c2c-card-ask-to-connect-btn'
                  : 'c2c-card-connect-btn'
              }
            >
              {integration.isComingSoon ? 'Ask to connect' : 'Connect'}
            </Button>
          }
        />
      ))}
    </>
  );
}
