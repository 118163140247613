import {
  Breadcrumbs,
  BreadcrumbsProps,
  Button,
  createStyles,
  Divider,
  Group,
  Text,
} from '@mantine/core';
import React from 'react';

import { ReactComponent as Add } from '@portals/icons/linear/add.svg';
import { ReactComponent as RefreshIcon } from '@portals/icons/linear/refresh.svg';

import { useOverviewContext } from '../../overview.context';
import { useCurrentSpace } from '../../overview.hooks';

export function OverviewConfigPanel() {
  const { classes, cx } = useStyles();
  const overview = useOverviewContext();
  const currentSpace = useCurrentSpace();

  return (
    <div>
      <Group position="apart" className={classes.actionsBar}>
        <Group>
          <Button
            size="md"
            className={classes.subtleButton}
            leftIcon={<RefreshIcon width={18} height={18} />}
            onClick={overview.onReset}
            variant="transparent"
          >
            Reset to default
          </Button>

          <Divider orientation="vertical" color="gray.7" />

          <Button
            size="md"
            className={cx(classes.subtleButton, classes.outlineButton)}
            onClick={overview.navigateToWidgetsPanel}
            variant="outline"
            data-testid="add-widgets-button"
            leftIcon={<Add width={18} height={18} />}
          >
            Add Widgets
          </Button>
        </Group>

        <Group>
          <Button
            size="md"
            className={classes.subtleButton}
            variant="transparent"
            onClick={overview.onDiscardChanges}
          >
            Discard
          </Button>

          <Button
            size="md"
            color="indigo_accent.2"
            onClick={overview.onSaveChanges}
            data-testid="save-changes-button"
          >
            Save Changes
          </Button>
        </Group>
      </Group>

      <Breadcrumbs styles={breadcrumbsStyles}>
        {currentSpace?.tree_path_name?.split('/').map((item, index) => (
          <Text key={index}>{item}</Text>
        ))}
      </Breadcrumbs>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  actionsBar: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.xl,
    backgroundColor: theme.colors.gray[8],
  },
  subtleButton: {
    color: theme.colors.gray[1],

    ...theme.fn.hover({
      color: theme.colors.gray[3],
      backgroundColor: 'transparent',
    }),
  },
  outlineButton: {
    borderColor: 'inherit',
  },
}));

const breadcrumbsStyles: BreadcrumbsProps['styles'] = (theme) => ({
  root: {
    paddingBlock: theme.spacing.xl,
    paddingInline: theme.spacing.xxl,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    backgroundColor: theme.white,
    fontWeight: 500,
    color: theme.colors.blue_gray[6],
    userSelect: 'none',
  },
  breadcrumb: {
    fontSize: theme.fontSizes.xl,

    '&:last-child': {
      fontWeight: 600,
      color: theme.colors.gray[9],
    },
  },
  separator: {
    fontSize: theme.fontSizes.xl,
    color: 'inherit',
  },
});
