import { ActionIcon, createStyles, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useClickOutside } from '@mantine/hooks';
import React, { useState } from 'react';

import { TextWithTooltip } from '@portals/core';
import { ReactComponent as CloseX } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as Edit3 } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as TickSimple } from '@portals/icons/linear/tick-simple.svg';

import { useDeviceContext } from '../context';
import { getDeviceName } from '../utils';

interface DeviceNameProps {
  deviceName: string;
}

export function DeviceName({ deviceName }: DeviceNameProps) {
  const { classes } = useStyles();

  const [isEditMode, setIsEditMode] = useState(false);

  if (isEditMode) {
    return (
      <EditDeviceName
        deviceName={deviceName}
        onClose={() => setIsEditMode(false)}
      />
    );
  }

  return (
    <Group noWrap>
      <TextWithTooltip className={classes.name} data-testid="device-name">
        {getDeviceName(deviceName)}
      </TextWithTooltip>

      <ActionIcon ml="auto" onClick={() => setIsEditMode(true)}>
        <Edit3 width={20} height={20} />
      </ActionIcon>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  name: {
    color: theme.colors.blue_gray[8],
    fontWeight: 500,
    fontSize: theme.fontSizes.lg,

    [theme.fn.largerThan(1600)]: {
      fontSize: theme.fontSizes.xl,
    },
  },
}));

interface EditDeviceNameProps {
  deviceName: string;
  onClose: VoidFunction;
}

function EditDeviceName({ deviceName, onClose }: EditDeviceNameProps) {
  const { onUpdateDevice } = useDeviceContext();

  const ref = useClickOutside(onClose);

  const form = useForm({
    initialValues: {
      name: deviceName || '',
    },
  });

  const onSubmit = (values: typeof form.values) => {
    if (values.name === deviceName) {
      onClose();
    } else {
      onUpdateDevice({ name: values.name });
    }
  };

  return (
    <form ref={ref} onSubmit={form.onSubmit(onSubmit)}>
      <Group spacing="xs" noWrap>
        <TextInput
          sx={{ flexGrow: 1 }}
          autoFocus
          {...form.getInputProps('name')}
        />

        <ActionIcon size="lg" type="button" onClick={onClose}>
          <CloseX width={16} height={16} />
        </ActionIcon>

        <ActionIcon size="lg" variant="filled" type="submit">
          <TickSimple width={16} height={16} />
        </ActionIcon>
      </Group>
    </form>
  );
}
