import { Box, createStyles, Stack } from '@mantine/core';
import React from 'react';

import { ReactComponent as SMS } from '@portals/icons/bulk/sms.svg';

import { StepSubtitle, StepTitle } from '../common';

export function AccessRequest() {
  const { classes } = useStyles();

  return (
    <Stack spacing="lg" align="center">
      <Box className={classes.imageContainer}>
        <SMS className={classes.image} />
      </Box>

      <StepTitle>Access request has been sent</StepTitle>

      <StepSubtitle>
        You will receive an email once your request is approved
      </StepSubtitle>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  image: {
    width: 50,
    height: 50,
    transform: 'rotate(-25deg)',
    color: theme.colors.blue_accent[4],
  },

  imageContainer: {
    backgroundColor: theme.fn.rgba(theme.colors.blue_accent[4], 0.1),
    borderRadius: 50,
    padding: theme.spacing.xl,
    width: 100,
    height: 100,
  },
}));
