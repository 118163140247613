import { Group, Text } from '@mantine/core';
import React, { forwardRef } from 'react';

interface SpacePathProps {
  spacePath: string;
  includeCustomerSpaceInPath?: boolean;
}

export const SpacePath = forwardRef<HTMLDivElement, SpacePathProps>(
  ({ spacePath, includeCustomerSpaceInPath }, ref) => {
    const spaceNames = getSpaceNames({ spacePath, includeCustomerSpaceInPath });

    const firstSpaceName = spaceNames.at(0);
    const lastSpaceName = spaceNames.at(-1);

    if (spaceNames.length <= 2) {
      return (
        <Text ref={ref} fz="sm" w="min-content" truncate>
          {spaceNames.join(' / ')}
        </Text>
      );
    }

    return (
      <Group ref={ref} noWrap spacing="xs" fz="sm">
        <Text truncate>{firstSpaceName}</Text>
        <Text>/ ... /</Text>
        <Text truncate>{lastSpaceName}</Text>
      </Group>
    );
  }
);

function getSpaceNames({
  spacePath,
  includeCustomerSpaceInPath,
}: {
  spacePath: string;
  includeCustomerSpaceInPath?: boolean;
}) {
  const spaceNamesArray = spacePath.split('/');

  if (includeCustomerSpaceInPath) {
    return spaceNamesArray.slice(1);
  }

  const slicedPath = spaceNamesArray.slice(2);

  if (slicedPath.length === 0) {
    return spaceNamesArray.slice(1);
  }

  return slicedPath;
}
