import { Button, Stack, Text } from '@mantine/core';
import React from 'react';

import { binocularsEmptyStateSrc } from '@portals/assets';

interface ConnectRouteModalEmptyStateProps {
  searchTerm: string;
  onConnectDomotzClick: VoidFunction;
  onSuggestBrandClick: VoidFunction;
}

export function ConnectRouteModalEmptyState({
  searchTerm,
  onConnectDomotzClick,
  onSuggestBrandClick,
}: ConnectRouteModalEmptyStateProps) {
  return (
    <Stack spacing="xxl" py="xxl" align="center" justify="center">
      <Stack spacing="sm" align="center" justify="center" ta="center">
        <img width={190} height="auto" src={binocularsEmptyStateSrc} alt="" />

        <Text fz="lg" fw={600}>
          No results found
        </Text>
        <Text c="gray.7">
          We couldn't find <strong>{searchTerm}</strong> in our list.
          <br />
          You can try connecting it through Domotz connector.
        </Text>
      </Stack>

      <Stack>
        <Button onClick={onConnectDomotzClick}>Explore Domotz connector</Button>

        <Button
          variant="default"
          onClick={onSuggestBrandClick}
          data-testid="suggest-a-brand-button"
        >
          Ask for a brand
        </Button>
      </Stack>
    </Stack>
  );
}
