import { useDescope } from '@descope/react-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { signedIn, signedOut } from '@portals/redux/actions/auth';
import { toastrError } from '@portals/redux/actions/toastr';
import { AuthType, TenantType } from '@portals/types';

import {
  AUTH_API_URL,
  VERIFY_EMAIL_API_URL,
  verifyEmailQueryKeys,
} from './auth.constants';
import { useApiQuery } from '../hooks/query';
import { ServerError } from '../types';
import { fetchApiRequest, useRequestOptions } from '../utils';

interface UseSignInParams {
  token: string;
  tenant_type: TenantType;
  is_support_user?: boolean;
}

export function useSignIn() {
  const dispatch = useDispatch();

  return useMutation<AuthType, ServerError, UseSignInParams>({
    mutationFn: ({ token, tenant_type, is_support_user }) => {
      return fetchApiRequest(`${AUTH_API_URL}/descope/login`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          cache: 'no-store',
        }),
        body: JSON.stringify({
          token,
          tenant_type,
          is_support_user,
          accepted_terms_and_conditions: true,
          last_tenant_id: localStorage.getItem('last_tenant_id'),
        }),
      });
    },
    onSuccess: (auth) => {
      dispatch(signedIn(auth));
    },
    meta: {
      mutationName: 'useSignIn',
      baseUrl: `${AUTH_API_URL}/descope/login`,
      method: 'POST',
    },
  });
}

export function useSignOut() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const descope = useDescope();

  const { url, options } = useRequestOptions({
    url: `auth/sign_out`,
    method: 'DELETE',
  });

  return useMutation<void, ServerError, void>({
    mutationFn: () => fetchApiRequest(url, options),
    onSuccess: () => {
      queryClient.clear();
      localStorage.removeItem('auth');
      dispatch(signedOut());

      return descope.logout();
    },
  });
}

interface UseForgotPasswordParams {
  email: string;
  tenantType: TenantType;
}

export function useForgotPassword() {
  return useMutation<{ message: string }, ServerError, UseForgotPasswordParams>(
    {
      mutationFn: ({ email, tenantType }) =>
        fetchApiRequest(`${AUTH_API_URL}/descope/reset_password`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            cache: 'no-store',
          }),
          body: JSON.stringify({
            email,
            tenant_type: tenantType,
          }),
        }),
      meta: {
        mutationName: 'useForgotPassword',
        baseUrl: `${AUTH_API_URL}/descope/reset_password`,
        method: 'POST',
      },
    }
  );
}

interface UseCheckUserDomainResponse {
  name: string;
  partner_name: string;
  device_partner_name: string | null;
  domain: string | null;
  requires_cec_migration: boolean;
}

export function useCheckUserDomain() {
  return useMutation<
    UseCheckUserDomainResponse,
    ServerError,
    { email: string; cloudId?: string }
  >({
    mutationFn: ({ email, cloudId }) =>
      fetchApiRequest(`${AUTH_API_URL}/descope/check_domain`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          cache: 'no-store',
        }),
        body: JSON.stringify({
          email,
          cloud_id: cloudId,
        }),
      }),
    meta: {
      mutationName: 'useCheckUserDomain',
      baseUrl: `${AUTH_API_URL}/descope/check_domain`,
      method: 'POST',
    },
  });
}

export function useResendCecMigrationEmail() {
  const dispatch = useDispatch();

  return useMutation<void, ServerError, { email: string }>({
    mutationFn: ({ email }) =>
      fetchApiRequest(`${AUTH_API_URL}/descope/resend_cec_migration_email`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          cache: 'no-store',
        }),
        body: JSON.stringify({ email }),
      }),
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}

interface UseSendEmailVerificationParams {
  email: string;
  reference_partner: string | undefined;
}

export function useSendEmailVerification() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: VERIFY_EMAIL_API_URL,
    method: 'POST',
  });

  return useMutation<
    { message: string },
    ServerError,
    UseSendEmailVerificationParams
  >({
    mutationFn: ({ email, reference_partner }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ email, reference_partner }),
      }),
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useSendEmailVerification',
      baseUrl: VERIFY_EMAIL_API_URL,
      method: 'POST',
    },
  });
}

interface VerifyEmailType {
  email: string;
}

export function useVerifyEmail(token: string | null) {
  return useApiQuery<VerifyEmailType>(
    `${VERIFY_EMAIL_API_URL}/verify_token?token=${token}`,
    verifyEmailQueryKeys.base,
    {
      enabled: !!token,
    }
  );
}
