import { Avatar, AvatarProps, Tooltip } from '@mantine/core';
import React from 'react';

import { ReactComponent as UserCircleIcon } from '@portals/icons/bold/user-circle.svg';
import { getNameAbbreviation } from '@portals/utils';

import { useSeedRandomColor } from '../hooks';

export interface NameAbbreviationAvatarProps extends AvatarProps {
  name: string;
  withToolTip?: boolean;
}

export function NameAbbreviationAvatar({
  withToolTip = true,
  name,
  color,
  ...avatarProps
}: NameAbbreviationAvatarProps) {
  const randomColor = useSeedRandomColor(name);
  const abbreviation = getNameAbbreviation(name);

  return (
    <Tooltip label={name} withArrow withinPortal disabled={!withToolTip}>
      <Avatar
        color={color || randomColor}
        styles={{ placeholder: { fontWeight: 500 } }}
        {...avatarProps}
      >
        {abbreviation || <UserCircleIcon width="100%" height="100%" />}
      </Avatar>
    </Tooltip>
  );
}
