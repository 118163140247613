import { Group, SelectProps, Text } from '@mantine/core';
import React from 'react';

import { IncidentType, useUpdateIncident } from '@portals/api/organizations';
import { PriorityIcon } from '@portals/framework';
import { useHasEntityAccess } from '@portals/framework/EntityAccess';
import { AccessLevelEnum } from '@portals/types';
import { getIncidentPriorityLevelName } from '@portals/utils';

import { PriorityLevelSelect } from '../../components/PriorityLevelSelect';

interface PriorityColumnProps {
  incident: IncidentType;
}

export function PriorityColumn({ incident }: PriorityColumnProps) {
  const updateIncident = useUpdateIncident();
  const hasEditAccess = useHasEntityAccess(incident, AccessLevelEnum.Edit);

  if (!hasEditAccess || incident.status === 'closed') {
    return (
      <Group pl="xs">
        <PriorityIcon priorityLevel={incident.priority} iconSize={18} />
        <Text>{getIncidentPriorityLevelName(incident.priority)}</Text>
      </Group>
    );
  }

  return (
    <PriorityLevelSelect
      withinPortal
      styles={prioritySelectStyles}
      value={incident.priority}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(value) => {
        updateIncident.mutate({
          deviceId: incident.device_id,
          incidentId: incident.id,
          incident: {
            priority: value,
          },
        });
      }}
    />
  );
}

const prioritySelectStyles: SelectProps['styles'] = (theme) => ({
  input: {
    borderWidth: 0,
    '::placeholder': {
      color: theme.colors.gray[7],
    },
  },
  rightSection: {
    pointerEvents: 'none',
    paddingRight: theme.spacing.xs,
  },
  item: {
    paddingInline: `${theme.spacing.md} !important`,
    paddingBlock: `${theme.spacing.xs} !important`,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
  },
});
