import { createStyles, Spoiler, SpoilerProps, Text } from '@mantine/core';
import React from 'react';

interface ExpandableContainerProps
  extends Omit<SpoilerProps, 'showLabel' | 'hideLabel'> {}

export function ExpandableContainer({
  children,
  ...restSpoilerProps
}: ExpandableContainerProps) {
  const { classes } = useStyles();

  return (
    <Spoiler
      showLabel={<Text className={classes.openSpoilerText}>Show more</Text>}
      hideLabel={<Text className={classes.closeSpoilerText}>Show less</Text>}
      classNames={{
        control: classes.spoilerControl,
      }}
      {...restSpoilerProps}
    >
      {children}
    </Spoiler>
  );
}

const useStyles = createStyles((theme) => ({
  spoilerControl: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',

    fontSize: theme.fontSizes.sm,
    width: '100%',
  },

  openSpoilerText: {
    position: 'absolute',
    bottom: -5,
    width: '100%',
    marginBlockStart: theme.spacing.md,
    paddingTop: theme.spacing.xxl,

    background: `linear-gradient(transparent 0%, ${theme.colors.gray[1]} 55%)`,
    textDecoration: 'underline',
    fontWeight: 400,

    '&:focus-visible': {
      outline: 'none',
    },

    '&:focus-within': {
      outline: 'none',
    },
  },

  closeSpoilerText: {
    width: '100%',
    height: '1rem',
    fontWeight: 400,

    textDecoration: 'underline',
  },
}));
