import { Flex, FlexProps, Text, TextProps } from '@mantine/core';
import React, { ComponentProps } from 'react';

import { NameAbbreviationAvatar } from '@portals/core';

interface CustomerAvatarAndNameProps extends FlexProps {
  name: string;
  size?: number;
  avatarProps?: Omit<
    ComponentProps<typeof NameAbbreviationAvatar>,
    'name' | 'size'
  >;
  textProps?: TextProps;
}

export function CustomerAvatarAndName({
  name,
  size,
  avatarProps,
  textProps,
  ...flexProps
}: CustomerAvatarAndNameProps) {
  return (
    <Flex gap="xs" align="center" {...flexProps}>
      <NameAbbreviationAvatar
        name={name}
        radius="xl"
        size={size}
        withToolTip={false}
        {...avatarProps}
      />
      <Text {...textProps}>{name}</Text>
    </Flex>
  );
}
