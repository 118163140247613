import { Box, Button, Flex, Group, LoadingOverlay, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash/fp';
import React from 'react';

import {
  assetsQueryKeys,
  AssetType,
  useAssets,
  useBulkRemoveAsset,
} from '@portals/api/organizations';
import { TruncatedSpaceLink } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { DateCell, PaginatedTable } from '@portals/table';
import { PaginatedFilterTypeEnum, TableColumn } from '@portals/types';

import { AddAssetModalProps } from '../../../modals';
import { AssetDetailsPanel } from '../../assets/AssetDetailsPanel';
import { ASSETS_ICONS_MAP } from '../../assets/assets-icons-map';
import { AssetsAdditionalActions } from '../../assets/AssetsAdditionalActions';
import { useCurrentSpace } from '../overview.hooks';

const COLUMNS: Array<TableColumn<AssetType>> = [
  {
    dataField: 'name',
    text: 'Name',
    minWidth: 300,
    isSticky: true,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    sort: true,
    formatter: (_, { icon_name, name }) => {
      const Icon = ASSETS_ICONS_MAP[icon_name];

      return (
        <Group noWrap>
          <Flex
            sx={(theme) => ({ borderRadius: theme.radius.md })}
            align="center"
            justify="center"
            bg="gray.2"
            w={48}
            h={48}
          >
            <Icon />
          </Flex>

          <Text>{name}</Text>
        </Group>
      );
    },
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: (_, { status }) => status?.name,
    minWidth: 200,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    sort: true,
  },
  {
    dataField: 'manufacturer_name',
    text: 'Manufacturer',
    minWidth: 250,
    formatter: (_, { manufacturer }) => manufacturer?.name,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    sort: true,
  },
  {
    dataField: 'device_model_name',
    text: 'Model',
    minWidth: 250,
    formatter: (_, { device_model }) => device_model?.name,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    sort: true,
  },
  {
    dataField: 'device_type_name',
    text: 'Type',
    minWidth: 250,
    formatter: (_, { device_model }) => device_model?.name,
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    sort: true,
  },

  {
    dataField: 'space_name',
    text: 'Space ID',
    minWidth: 250,
    formatter: (_, { space }) => (
      <TruncatedSpaceLink spaceId={space.id} spacePath={space.tree_path_name} />
    ),
    filter: {
      type: PaginatedFilterTypeEnum.Text,
    },
    sort: true,
  },
  {
    dataField: 'serial_number',
    text: 'Serial number',
    minWidth: 250,
    filter: {
      type: PaginatedFilterTypeEnum.Number,
    },
    sort: true,
  },
  {
    dataField: 'purchased_at',
    text: 'Purchased at',
    minWidth: 300,
    formatter: (_, { purchased_at }) => <DateCell date={purchased_at} />,
  },
];

interface OverviewAssetsListProps {
  dataHookUrl: string;
}

export function OverviewAssetsList({ dataHookUrl }: OverviewAssetsListProps) {
  const queryClient = useQueryClient();
  const openModal = useOpenModal();
  const bulkRemoveAssets = useBulkRemoveAsset();
  const space = useCurrentSpace();

  function onDetailsPanelClose() {
    queryClient.invalidateQueries(assetsQueryKeys.all());
  }

  return (
    <Box h="100%" w="100%">
      <LoadingOverlay visible={bulkRemoveAssets.isLoading} />

      <PaginatedTable<AssetType>
        keyField="id"
        name="organizations.overview.assets"
        dataHook={useAssets}
        dataHookUrl={dataHookUrl}
        columns={COLUMNS}
        onSelected={noop}
        noDataIndication={{ title: 'No assets' }}
        defaultSortBy={[{ id: 'created_at', desc: true }]}
        selectedItemsActions={() => (
          <AssetsAdditionalActions onBulkRemove={bulkRemoveAssets.mutate} />
        )}
        additionalActions={() => (
          <Group position="right">
            <Button
              variant="default"
              onClick={() =>
                openModal<AddAssetModalProps['data']>('AddAssetModal', {
                  initialSpaceId: space.id,
                })
              }
            >
              Add asset
            </Button>
          </Group>
        )}
        detailsPanel={{
          type: 'page',
          onClose: onDetailsPanelClose,
          renderer: ({ row, onClose }) => (
            <AssetDetailsPanel assetId={row.original.id} onClose={onClose} />
          ),
        }}
      />
    </Box>
  );
}
