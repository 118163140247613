import { useDescope } from '@descope/react-sdk';
import {
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  Input,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { captureException } from '@sentry/react';
import React, { useState } from 'react';
import {
  generatePath,
  Link,
  useMatch,
  useSearchParams,
} from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { object, string } from 'yup';

import { useSendEmailVerification } from '@portals/api/auth';
import { useCheckOrganizationExistence } from '@portals/api/organizations';
import { useEmailAvailabilityCheckWithinDomain } from '@portals/api/ui';
import { GoogleIcon } from '@portals/assets';
import { TermsOfUse } from '@portals/framework';

import { StepTitle } from '../common';
import { useSignUpWizard, useTrackPageView } from '../wizard/wizard.hooks';
import { StepIdEnum } from '../wizard/wizard.types';

const schema = object({
  email: string().email().required('Email is required'),
});

export function Authentication() {
  const { onNext, contextData, setContextData, skipToStep } = useSignUpWizard();

  const [, setSearchParams] = useSearchParams();

  const form = useForm({
    initialValues: {
      email: contextData.email,
    },

    validate: yupResolver(schema),
  });

  const [isSocialSignUpLoading, setIsSocialSignUpLoading] = useState(false);
  const [googleError, setGoogleError] = useState<string | null>(null);

  const emailAvailabilityCheckWithinDomain =
    useEmailAvailabilityCheckWithinDomain();

  const match = useMatch('/auth/sign-up/:partner_name?');

  const checkOrganizationExistence = useCheckOrganizationExistence();
  const sendEmailVerification = useSendEmailVerification();

  const descopeSdk = useDescope();

  const onSubmit = async (values: typeof form.values) => {
    try {
      const userExists = await emailAvailabilityCheckWithinDomain.mutateAsync({
        email: values.email,
      });

      if (userExists.email_in_use) {
        form.setFieldError('email', 'Email already in use');
        return;
      }

      await sendEmailVerification.mutateAsync({
        email: values.email,
        reference_partner: match?.params.partner_name,
      });

      setContextData((curr) => ({
        ...curr,
        email: values.email,
      }));

      onNext();
    } catch (error) {
      console.error(error);
    }
  };

  const onSignUpWithSocialProvider = async (
    socialProvider: keyof Awaited<
      ReturnType<typeof useDescope>['oauth']['start']
    >
  ) => {
    setIsSocialSignUpLoading(true);

    try {
      const descopeSdkResponse = await descopeSdk.oauth.start[socialProvider](
        `${window.location.origin}/auth/sign-up`
      );

      if (!descopeSdkResponse.data || descopeSdkResponse.error) {
        setIsSocialSignUpLoading(false);

        captureException(
          `descope error - ${descopeSdkResponse.error?.errorDescription}`
        );

        setGoogleError(
          descopeSdkResponse.error?.errorDescription ??
            'Failed to start OAuth flow'
        );

        throw new Error('Failed to start OAuth flow');
      }

      setIsSocialSignUpLoading(false);

      window.location.replace(descopeSdkResponse.data.url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffectOnce(() => {
    const finishSocialAuth = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get('code');

      if (!code) {
        return;
      }

      try {
        const responseSdkResponse = await descopeSdk.oauth.exchange(code);

        if (
          responseSdkResponse.error ||
          !responseSdkResponse.data ||
          !responseSdkResponse.data.user ||
          !responseSdkResponse.data.user.name ||
          !responseSdkResponse.data.user.email
        ) {
          captureException(
            `descope error - ${responseSdkResponse.error?.errorDescription}`
          );

          setGoogleError(
            responseSdkResponse.error?.errorDescription ??
              'Failed to exchange OAuth code'
          );

          throw new Error('Failed to exchange OAuth code');
        }

        const userExists = await emailAvailabilityCheckWithinDomain.mutateAsync(
          {
            email: responseSdkResponse.data.user.email,
          }
        );

        if (userExists.email_in_use) {
          setGoogleError('Email already taken');
          return;
        }

        const name = responseSdkResponse.data.user.name;
        const email = responseSdkResponse.data.user.email;

        const orgExists = await checkOrganizationExistence.mutateAsync({
          email,
        });

        setContextData((curr) => ({
          ...curr,
          name,
          email,
          sessionToken: responseSdkResponse.data?.sessionJwt,
          organizationExists: orgExists.in_use,
        }));

        setSearchParams((prev) => {
          prev.delete('code');
          return prev;
        });

        orgExists.in_use
          ? skipToStep(StepIdEnum.OrganizationExists)
          : skipToStep(StepIdEnum.OrganizationDoesntExists);
      } catch (error) {
        console.error(error);
      }
    };

    finishSocialAuth();
  });

  useTrackPageView('Sign up screen');

  const isLoading =
    emailAvailabilityCheckWithinDomain.isLoading ||
    checkOrganizationExistence.isLoading ||
    sendEmailVerification.isLoading;

  return (
    <Stack spacing="xxl">
      <Group spacing="lg" position="apart">
        <StepTitle align="left">Sign up</StepTitle>

        <Box>
          <Text span>Already have an account?</Text>{' '}
          <Anchor
            data-analyticsid="sign-in-link"
            size="sm"
            component={Link}
            to={generatePath('/auth/sign-in/:partner_name?', {
              partner_name: match?.params?.partner_name || null,
            })}
          >
            Sign in
          </Anchor>
        </Box>
      </Group>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <LoadingOverlay visible={isLoading} />

        <Stack spacing="xxl">
          <TextInput
            data-testid="onboarding-email-input"
            required
            type="email"
            label="Enter your work email"
            placeholder="e.g. thomas@xyte.com"
            {...form.getInputProps('email')}
          />

          <Box>
            <Button
              type="submit"
              fullWidth
              h={40}
              data-testid="continue-with-email-button"
              data-analyticsid="continue-with-email"
            >
              Continue with email
            </Button>

            <Divider label="OR" labelPosition="center" my="xs" />

            <Button
              data-testid="continue-with-google"
              data-analyticsid="continue-with-google"
              leftIcon={<GoogleIcon />}
              variant="default"
              fullWidth
              h={40}
              loading={isSocialSignUpLoading}
              onClick={() => onSignUpWithSocialProvider('google')}
            >
              Continue with Google
            </Button>

            {googleError && <Input.Error mt="xs">{googleError}</Input.Error>}
          </Box>

          <TermsOfUse />
        </Stack>
      </form>
    </Stack>
  );
}
