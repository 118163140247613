import {
  Group,
  SegmentedControl,
  SegmentedControlProps,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useMemo } from 'react';

import { useSpaces } from '@portals/api/organizations';
import { ReactComponent as DateIcon } from '@portals/icons/linear/data-2.svg';
import { ReactComponent as MouseSquare } from '@portals/icons/linear/mouse-square.svg';

import { useOverviewContext } from './overview.context';
import { useCurrentSpace } from './overview.hooks';
import { DataLevelEnum } from './overview.types';

export function SpaceDataLevelControl() {
  const overview = useOverviewContext();

  const spaces = useSpaces();
  const currentSpace = useCurrentSpace();

  const hasChildren = useMemo(
    () => spaces.data?.some((space) => space.parent_id === currentSpace?.id),
    [currentSpace?.id, spaces.data]
  );

  const controlData: SegmentedControlProps['data'] = useMemo(() => {
    return [
      {
        value: DataLevelEnum.All,
        label: (
          <Tooltip
            multiline
            width={300}
            label="View aggregated data for the selected space and all its subordinate spaces. This provides a comprehensive overview that includes all linked spaces."
          >
            <Group position="center" spacing="xs">
              <DateIcon width={18} height={18} />

              <Text fz="sm">Include sub spaces</Text>
            </Group>
          </Tooltip>
        ),
      },
      {
        value: DataLevelEnum.Local,
        label: (
          <Tooltip
            multiline
            width={300}
            label="View data exclusively for the currently selected space. Data from subordinate spaces won't be included."
          >
            <Group position="center" spacing="xs">
              <MouseSquare width={18} height={18} />

              <Text fz="sm">Selected space only</Text>
            </Group>
          </Tooltip>
        ),
      },
    ];
  }, []);

  if (!hasChildren || currentSpace?.space_type === 'root') {
    return null;
  }

  return (
    <SegmentedControl
      fullWidth
      size="md"
      transitionDuration={0}
      data={controlData}
      value={overview.dataLevel}
      onChange={(dataLevel: DataLevelEnum) =>
        overview.onSetDataLevel(dataLevel)
      }
    />
  );
}
