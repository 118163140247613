import { Button, createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { useRequestToJoinPartner } from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';

import { StepSubtitle, StepTitle } from '../common';
import { useSignUpWizard, useTrackPageView } from '../wizard/wizard.hooks';

export function PartnerExists() {
  const { classes } = useStyles();

  const { contextData, onNext } = useSignUpWizard();

  const requestToJoinPartner = useRequestToJoinPartner();

  useTrackPageView('Partner already exists screen');

  return (
    <Stack>
      <StepTitle>Account already exists</StepTitle>

      <StepSubtitle mb="lg">
        You've entered an email domain that matches an existing account in our
        database.
      </StepSubtitle>

      <Group className={classes.partnerWrapper}>
        <NameAbbreviationAvatar
          src={contextData.partnerLogo}
          name={contextData.partnerName ?? ''}
          radius="xl"
          color="dark"
          variant="filled"
        />

        <Text size="sm" color="gray.7" truncate>
          {contextData.partnerName}
        </Text>
      </Group>

      <Button
        fullWidth
        mt="lg"
        onClick={() =>
          requestToJoinPartner.mutate(
            {
              email: contextData.email,
            },
            {
              onSuccess: () => {
                onNext();
              },
            }
          )
        }
      >
        Request to join
      </Button>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  partnerWrapper: {
    width: '100%',
    borderRadius: theme.radius.lg,
    border: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.colors.gray[1],
    padding: theme.spacing.xl,
  },
}));
