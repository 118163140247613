import { Button, LoadingOverlay, Stack } from '@mantine/core';
import React from 'react';

import { useWarrantiesOfAsset } from '@portals/api/organizations';
import { DetailsPanel } from '@portals/core';
import { useOpenModal } from '@portals/redux';

import { AssetWarrantyCard } from './AssetWarrantyCard';
import { UploadAssetWarrantyModalProps } from '../../../modals';

interface AssetWarrantiesTabProps {
  assetId: string;
}

export function AssetWarrantiesTab({ assetId }: AssetWarrantiesTabProps) {
  const openModal = useOpenModal();
  const warrantiesOfAsset = useWarrantiesOfAsset(assetId);

  return (
    <DetailsPanel.Body>
      <LoadingOverlay visible={warrantiesOfAsset.isFetching} />

      <Button
        sx={{
          flexShrink: 0,
        }}
        size="md"
        variant="filled"
        onClick={() =>
          openModal<UploadAssetWarrantyModalProps['data']>(
            'UploadAssetWarrantyModal',
            {
              assetId,
            }
          )
        }
      >
        + Add warranty
      </Button>

      <Stack>
        {warrantiesOfAsset?.data?.data?.map((warranty) => (
          <AssetWarrantyCard
            warranty={warranty}
            assetId={assetId}
            key={warranty.id}
          />
        ))}
      </Stack>
    </DetailsPanel.Body>
  );
}
