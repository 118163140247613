import {
  Badge,
  BadgeProps,
  Button,
  ButtonProps,
  createStyles,
  HoverCard,
  HoverCardProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

import { IncidentType, useSnoozeIncident } from '@portals/api/organizations';
import { IncidentStatus } from '@portals/types';
import { suppressPropagation, timeUntil } from '@portals/utils';

interface IncidentStatusBadgeProps extends BadgeProps {
  incident: IncidentType;
  withActionCard?: boolean;
}

export function IncidentStatusBadge({
  incident,
  withActionCard,
  ...badgeProps
}: IncidentStatusBadgeProps) {
  const { classes } = useStyles();

  const badgeTitle = getBadgeTitle(incident.status);

  if (withActionCard && incident.status === 'snoozed') {
    return (
      <SnoozedBadgeHoverCard
        incident={incident}
        target={
          <div className={classes.badgeHoverContainer}>
            <StatusBadge
              incidentStatus={incident.status}
              badgeTitle={badgeTitle}
              badgeProps={badgeProps}
            />
          </div>
        }
      />
    );
  }

  return (
    <StatusBadge
      incidentStatus={incident.status}
      badgeTitle={badgeTitle}
      badgeProps={badgeProps}
    />
  );
}

interface StatusBadgeProps {
  incidentStatus: IncidentStatus;
  badgeTitle: string;
  badgeProps: BadgeProps;
}

function StatusBadge({
  incidentStatus,
  badgeTitle,
  badgeProps,
}: StatusBadgeProps) {
  return (
    <Badge
      size="lg"
      radius="xl"
      fz="xs"
      fw={400}
      tt="capitalize"
      color="gray.9"
      bg={getBadgeStatusBgColor(incidentStatus)}
      {...badgeProps}
    >
      {badgeTitle}
    </Badge>
  );
}

interface SnoozedBadgeHoverCardProps {
  target: ReactNode;
  incident: IncidentType;
}

function SnoozedBadgeHoverCard({
  target,
  incident,
}: SnoozedBadgeHoverCardProps) {
  const snoozeIncident = useSnoozeIncident();
  const { classes } = useStyles();

  return (
    <HoverCard withArrow arrowSize={17} shadow="sm" styles={hoverCardStyles}>
      <HoverCard.Target>{target}</HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack p="lg" spacing={8} w={154} align="center">
          <Text
            c="gray.9"
            align="center"
            className={classes.timeUntilTextAdditionalStyles}
          >
            {timeUntil(incident.snoozed_until)}
          </Text>
          <Button
            variant="white"
            fullWidth
            styles={unsnoozeButtonAdditionalStyles}
            onClick={(event) => {
              suppressPropagation(function () {
                snoozeIncident.mutate({ incident, snoozed_until: null });
              })(event);
            }}
            loading={snoozeIncident.isLoading}
          >
            Unsnooze
          </Button>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

function getBadgeStatusBgColor(status: IncidentStatus) {
  if (status === 'active') {
    return 'red.0';
  }
  if (status === 'snoozed') {
    return 'amber.0';
  }
  return 'gray.1';
}

function getBadgeTitle(status: IncidentStatus) {
  if (status === 'active') {
    return 'Active';
  }
  if (status === 'snoozed') {
    return 'Snoozed';
  }
  return 'Resolved';
}

const hoverCardStyles: HoverCardProps['styles'] = (theme) => ({
  dropdown: {
    borderRadius: theme.radius.lg,
    padding: 0,
  },
});

const useStyles = createStyles({
  badgeHoverContainer: {
    width: 'min-content',
  },
  timeUntilTextAdditionalStyles: {
    whiteSpace: 'normal',
  },
});

const unsnoozeButtonAdditionalStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    border: `1px solid ${theme.colors.gray[3]}`,
  },
});
