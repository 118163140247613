import { Descope, useUser } from '@descope/react-sdk';
import {
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React, { useState } from 'react';

import { useUpdateUserSettings } from '@portals/api/ui';
import { toastrSuccess } from '@portals/redux/actions/toastr';

import { ModalProps } from '../../components/Modals';
import { captureDescopeError } from '../../utils/descope.utils';

export interface EmailMFAModalProps extends ModalProps {}

export function EmailMFAModal({ closeMe }: EmailMFAModalProps) {
  const { user, isUserLoading } = useUser();
  const updateUserSettings = useUpdateUserSettings();

  const [isDescopeFlowLoading, setIsDescopeFlowLoading] = useState(true);

  const isOtpEnabled = Boolean(user.customAttributes?.isOtpEnabled);

  const onSuccess: React.ComponentProps<typeof Descope>['onSuccess'] = (e) => {
    const updatedOtpEnabled = Boolean(
      e.detail.user?.customAttributes?.isOtpEnabled
    );

    updateUserSettings.mutate(
      { is_mfa_enabled: updatedOtpEnabled },
      {
        onSuccess: () => {
          const message = updatedOtpEnabled
            ? 'Two-Factor Authentication via email has been successfully set up'
            : 'Two-Factor Authentication via email has been successfully disabled';

          toastrSuccess(message);
          closeMe();
        },
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      radius="lg"
      padding="xxl"
      styles={modalStyles}
    >
      <LoadingOverlay visible={isDescopeFlowLoading || isUserLoading} />

      <Descope
        flowId={isOtpEnabled ? 'cancel-mfa-email' : 'mfa-email'}
        onSuccess={onSuccess}
        onReady={() => setIsDescopeFlowLoading(false)}
        onError={captureDescopeError}
      />
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = {
  body: {
    minHeight: 480,
  },
};
