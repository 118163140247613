import {
  ActionIcon,
  Button,
  ButtonProps,
  Flex,
  Group,
  InputProps,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useState } from 'react';

import {
  IncidentType,
  useSnoozeIncident,
  useUpdateIncident,
  useWithSupportCenter,
} from '@portals/api/organizations';
import { CopyToClipboard, DetailsPanel, TextWithTooltip } from '@portals/core';
import {
  IncidentStatusBadge,
  ModalButton,
  useConfirmationModal,
} from '@portals/framework';
import { ReactComponent as ArrowUp } from '@portals/icons/linear/arrow-up.svg';
import { ReactComponent as DiscardIcon } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as EditIcon } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as NotificationIcon } from '@portals/icons/linear/notification.svg';
import { ReactComponent as SnoozeIcon } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as LinearTicketCircle } from '@portals/icons/linear/tick-circle.svg';
import { ReactComponent as SaveIcon } from '@portals/icons/linear/tick-simple.svg';
import { formatDateTime, timeFromNow, timeUntil } from '@portals/utils';

import {
  CreateTicketModalProps,
  ResolveIncidentModalProps,
} from '../../modals';
import { IncidentSnoozeDropdown } from '../../pages/incidents/IncidentSnoozeDropdown';

interface IncidentDetailsPanelHeaderProps {
  incident: IncidentType;
  onClosePanel: () => void;
  handleEditedDescriptionDiscard: () => Promise<boolean>;
  hasEditAccess: boolean;
}

export function IncidentDetailsPanelHeader({
  incident,
  onClosePanel,
  handleEditedDescriptionDiscard,
  hasEditAccess,
}: IncidentDetailsPanelHeaderProps) {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [incidentTitle, setIncidentTitle] = useState(incident.title);

  const asyncConfirmationCheck = useConfirmationModal();
  const updateIncident = useUpdateIncident();

  const handleEditedTitleDiscard = async () => {
    const didTitleChange = incident.title !== incidentTitle;

    if (!didTitleChange) {
      setIsEditingTitle(false);

      return true;
    }

    const isConfirmed = await asyncConfirmationCheck({
      title: 'You have unsaved changes',
      description: 'Would you like to discard your changes?',
      cancelLabel: 'Keep editing',
      confirmationLabel: 'Discard change',
    });

    if (isConfirmed) {
      setIsEditingTitle(false);
      setIncidentTitle(incident.description);
    }

    return isConfirmed;
  };

  const withSupportCenter = useWithSupportCenter();
  const snoozeIncident = useSnoozeIncident();

  const isIncidentSnoozed = incident.status === 'snoozed';

  const handleClosePanel = async () => {
    const [canDiscardTitle, canDiscardDescription] = await Promise.all([
      handleEditedTitleDiscard(),
      handleEditedDescriptionDiscard(),
    ]);

    if (canDiscardTitle && canDiscardDescription) {
      onClosePanel();
    }
  };

  const handleIncidentTitleSave = () =>
    updateIncident.mutate(
      {
        deviceId: incident.device_id,
        incidentId: incident.id,
        incident: {
          title: incidentTitle,
        },
      },
      {
        onSuccess: () => setIsEditingTitle(false),
      }
    );

  return (
    <DetailsPanel.Header
      onClose={handleClosePanel}
      title={
        <Group spacing="xs">
          <IncidentStatusBadge incident={incident} />
          {incident.status === 'snoozed' && (
            <Text fz="xs" c="gray.5" data-testid="incident-snoozed-until">
              {timeUntil(incident.snoozed_until)}
            </Text>
          )}
        </Group>
      }
      pb="md"
    >
      <Flex direction="column">
        {incident.short_id && (
          <Flex align="center" mb="xs">
            <Text c="gray.7" fz="sm" data-testid="incident-short-id">
              {incident.short_id}
            </Text>

            <CopyToClipboard
              actionIconProps={{ color: 'gray.7' }}
              value={incident.short_id}
              data-testid="copy-short-id-button"
            />
          </Flex>
        )}

        <DetailsPanel.Title
          data-testid="incident-title"
          truncate={undefined}
          c="gray.9"
          mb={4}
        >
          <Group>
            {hasEditAccess && isEditingTitle ? (
              <>
                <TextInput
                  autoFocus
                  disabled={!hasEditAccess}
                  value={incidentTitle}
                  onChange={(e) => {
                    setIncidentTitle(e.target.value);
                  }}
                  styles={editTitleInputStyles}
                />
                <Group spacing="sm">
                  <ActionIcon
                    onClick={handleEditedTitleDiscard}
                    data-testid="edit-title-discard-button"
                  >
                    <DiscardIcon width={20} height={20} />
                  </ActionIcon>
                  <ActionIcon
                    variant="filled"
                    onClick={handleIncidentTitleSave}
                    data-testid="edit-title-save-button"
                  >
                    <SaveIcon width={20} height={20} />
                  </ActionIcon>
                </Group>
              </>
            ) : (
              <>
                <Text>{incident.title}</Text>

                {incident.issue === 'user' && incident.status !== 'closed' && (
                  <ActionIcon
                    onClick={() => setIsEditingTitle(true)}
                    data-testid="edit-title-button"
                    data-analyticsid="incident-drawer-edit-title-button"
                  >
                    <EditIcon width={20} height={20} />
                  </ActionIcon>
                )}
              </>
            )}
          </Group>
        </DetailsPanel.Title>

        <TextWithTooltip
          label={formatDateTime(incident.created_at)}
          w="fit-content"
          c="gray.7"
          fz="sm"
          tooltipProps={{ withArrow: true }}
        >
          Created {timeFromNow(incident.created_at)}
        </TextWithTooltip>
      </Flex>

      {incident.status !== 'closed' && hasEditAccess && (
        <DetailsPanel.Actions>
          <ModalButton
            variant="default"
            size="xs"
            data-testid="sidebar-resolve-button"
            data-analyticsid="incident-drawer-resolve-button"
            label="Resolve"
            leftIcon={<LinearTicketCircle width={16} height={16} />}
            modalName="ResolveIncidentModal"
            data={
              {
                incident,
                onSuccess: () => {
                  handleClosePanel();
                },
              } satisfies ResolveIncidentModalProps['data']
            }
          />
          {withSupportCenter && (
            <ModalButton
              variant="default"
              size="xs"
              leftIcon={<ArrowUp width={16} height={16} />}
              data-testid="sidebar-escalate-button"
              data-analyticsid="incident-drawer-escalate-button"
              label="Escalate"
              modalName="CreateTicketModal"
              data={
                {
                  deviceId: incident.device_id,
                  description: incident.description,
                } satisfies CreateTicketModalProps['data']
              }
            />
          )}
          <IncidentSnoozeDropdown
            isIncidentSnoozed={isIncidentSnoozed}
            withinPortal
            target={
              <Button
                data-testid="sidebar-snooze-button"
                data-analyticsid="incident-drawer-snooze-button"
                variant="white"
                styles={toggleSnoozeButtonStyles}
                leftIcon={
                  isIncidentSnoozed ? (
                    <NotificationIcon width={16} height={16} />
                  ) : (
                    <SnoozeIcon width={16} height={16} />
                  )
                }
                loading={snoozeIncident.isLoading}
                onClick={() => {
                  if (isIncidentSnoozed) {
                    snoozeIncident.mutate({ incident, snoozed_until: null });
                  }
                }}
              >
                {isIncidentSnoozed ? 'Unsnooze' : 'Snooze'}
              </Button>
            }
            onOptionClick={(value) => {
              if (!isIncidentSnoozed) {
                snoozeIncident.mutate({ incident, snoozed_until: value });
              }
            }}
            openDelay={isIncidentSnoozed ? 200 : 0}
          />
        </DetailsPanel.Actions>
      )}
    </DetailsPanel.Header>
  );
}

const toggleSnoozeButtonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    border: `1px solid ${theme.colors.gray[4]}`,
    height: 30,
    paddingRight: theme.spacing.xs,
  },
  label: {
    fontSize: theme.fontSizes.xs,
  },
});

const editTitleInputStyles: InputProps['styles'] = (theme) => ({
  root: {
    flex: 1,
  },
  input: {
    fontSize: theme.fontSizes.lg,
  },
});
