import { Text, TextProps, Tooltip, TooltipProps } from '@mantine/core';
import React, { ReactNode } from 'react';

interface TextWithTooltipProps extends TextProps {
  label?: ReactNode;
  tooltipProps?: Partial<TooltipProps>;
}

export function TextWithTooltip({
  label,
  children,
  tooltipProps,
  ...textProps
}: TextWithTooltipProps) {
  return (
    <Tooltip label={label || children} {...tooltipProps}>
      <Text truncate {...textProps}>
        {children}
      </Text>
    </Tooltip>
  );
}
