import { globalQueryKeys } from '../global-query-keys';

export const CONNECTORS_API_URL = 'ui/organization/connectors';

export const SUPPORTED_C2C_CONNECTORS = [
  'mtr',
  'zoom',
  'xio',
  'q_sys',
  'bright_sign',
  'domotz',
  'neat',
] as const;

export type SupportedC2cConnectorName =
  (typeof SUPPORTED_C2C_CONNECTORS)[number];

export const connectorsQueryKeys = {
  base: [...globalQueryKeys.integrations, CONNECTORS_API_URL],
  all: () => [...connectorsQueryKeys.base, 'all'],
  details: (connectorId: string) => [...connectorsQueryKeys.base, connectorId],
  importDetails: (connectorId: string) => [
    ...connectorsQueryKeys.details(connectorId),
    'import_details',
  ],
};
