import {
  Button,
  Group,
  Popover,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React, { useState } from 'react';
import { useMatch } from 'react-router-dom';

import { useSendEmailVerification } from '@portals/api/auth';
import { ReactComponent as Sent } from '@portals/icons/linear/tick-circle.svg';

import { useSignUpWizard } from '../wizard/wizard.hooks';

export function EmailConfirmation() {
  const theme = useMantineTheme();

  const { onPrev, contextData } = useSignUpWizard();

  const [isEmailSent, setIsEmailSent] = useState(false);

  const match = useMatch('/auth/sign-up/:partner_name?');

  const sendEmailVerification = useSendEmailVerification();

  const onResendEmail = () => {
    sendEmailVerification.mutate(
      {
        email: contextData.email,
        reference_partner: match?.params.partner_name,
      },
      {
        onSuccess: () => {
          setIsEmailSent(true);
        },
      }
    );
  };

  return (
    <Stack spacing="xl">
      <Text ta="center" fz={32}>
        Check your inbox to confirm your email address
      </Text>

      <Text ta="center">
        We sent link to <b>{contextData.email}</b>.
        <br />
        If you don’t see it, check the spam folder.
        <br />
        After confirming your email, you can explore the platform.
      </Text>

      <Stack spacing="xs">
        <Popover
          opened={isEmailSent}
          withArrow
          shadow="md"
          arrowSize={20}
          onChange={setIsEmailSent}
        >
          <Popover.Target>
            <Button
              fullWidth
              color="gray.1"
              c="gray.9"
              fw={500}
              onClick={onResendEmail}
              loading={sendEmailVerification.isLoading}
            >
              Resend email
            </Button>
          </Popover.Target>

          <Popover.Dropdown>
            <Group spacing="xs">
              <Sent color={theme.colors.green[5]} width={18} height={18} />

              <Text>Email sent</Text>
            </Group>
          </Popover.Dropdown>
        </Popover>

        <Button fullWidth variant="default" onClick={onPrev}>
          Back
        </Button>
      </Stack>
    </Stack>
  );
}
