import { useMutation, useQueryClient } from '@tanstack/react-query';

import { PaginationResponse, TableColumn, TableState } from '@portals/types';

import {
  ASSETS_API_URL,
  ASSETS_WARRANTIES_API_URL,
  assetsQueryKeys,
} from './assets.constants';
import { AssetType, AssetWarrantyType } from './assets.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';

export function useAssetsWarranties(
  tableState: Pick<
    TableState<AssetWarrantyType>,
    'sortBy' | 'filters' | 'pageIndex' | 'pageSize'
  >,
  columns: Array<TableColumn<AssetWarrantyType>>,
  baseUrl: string = ASSETS_WARRANTIES_API_URL,
  queryKey = assetsQueryKeys.allAssetsWarranties()
) {
  return usePaginatedTableApiQuery<AssetWarrantyType>({
    baseUrl,
    queryKey: [...queryKey, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: {
      refetchInterval: 30000,
    },
  });
}

export function useWarrantiesOfAsset(assetId: string) {
  return useApiQuery<PaginationResponse<AssetWarrantyType>>(
    `${ASSETS_API_URL}/${assetId}/asset_warranties`,
    assetsQueryKeys.warrantiesOfAsset(assetId)
  );
}

interface CreateAssetWarrantyParams {
  assetId: string;
  warranty: {
    start_date: string;
    end_date: string;
    name: string;
    description: string;
    warranty_document_url: string;
  };
}

export function useCreateAssetWarranty() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    method: 'POST',
    url: ASSETS_API_URL,
  });

  return useMutation<AssetWarrantyType, ServerError, CreateAssetWarrantyParams>(
    {
      mutationFn: ({ warranty, assetId }) =>
        fetchApiRequest(`${url}/${assetId}/asset_warranties`, {
          ...options,
          body: JSON.stringify(warranty),
        }),
      onSuccess: (_, { assetId }) => {
        queryClient.resetQueries(assetsQueryKeys.warrantiesOfAsset(assetId));
      },
      meta: {
        mutationName: 'useCreateAssetWarranty',
        baseUrl: `${url}/:assetId/asset_warranties`,
        method: 'POST',
      },
    }
  );
}

interface UseUpdateWarrantyOfAssetParams {
  warranty: AssetWarrantyType;
  assetId: AssetType['id'];
  shouldRefetch?: boolean;
}

export function useUpdateWarrantyOfAsset() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    method: 'PATCH',
    url: ASSETS_API_URL,
  });

  return useMutation<
    AssetWarrantyType,
    ServerError,
    UseUpdateWarrantyOfAssetParams
  >({
    mutationFn: ({ warranty, assetId }) =>
      fetchApiRequest(`${url}/${assetId}/asset_warranties/${warranty.id}`, {
        ...options,
        body: JSON.stringify(warranty),
      }),
    onSuccess: (_, { assetId, shouldRefetch }) => {
      if (shouldRefetch) {
        queryClient.resetQueries(assetsQueryKeys.allAssetsWarranties());
      } else {
        queryClient.resetQueries(assetsQueryKeys.warrantiesOfAsset(assetId));
      }
    },
    meta: {
      mutationName: 'useUpdateAssetWarranty',
      baseUrl: `${url}/:assetId/asset_warranties`,
      method: 'PATCH',
    },
  });
}

interface UseRemoveWarrantyOfAssetParams {
  assetId: string;
  warrantyId: string;
  shouldRefetch?: boolean;
}

export function useRemoveWarrantyOfAsset() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    method: 'DELETE',
    url: ASSETS_API_URL,
  });

  return useMutation<void, ServerError, UseRemoveWarrantyOfAssetParams>({
    mutationFn: ({ assetId, warrantyId }) =>
      fetchApiRequest(
        `${url}/${assetId}/asset_warranties/${warrantyId}`,
        options
      ),
    onSuccess: (_, { assetId, shouldRefetch }) => {
      if (shouldRefetch) {
        queryClient.resetQueries(assetsQueryKeys.allAssetsWarranties());
      } else {
        queryClient.resetQueries(assetsQueryKeys.warrantiesOfAsset(assetId));
      }
    },
    meta: {
      mutationName: 'useRemoveAssetWarranty',
      baseUrl: `${url}/:assetId/asset_warranties/:warrantyId`,
      method: 'DELETE',
    },
  });
}
