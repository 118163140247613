import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { Pendo } from '@portals/analytics';
import { useAuth } from '@portals/redux';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import {
  PaginatedQueryParamsType,
  PaginationResponse,
  TableColumn,
  TableState,
} from '@portals/types';

import {
  DEVICES_API_URL,
  devicesQueryKeys,
  getDeviceApiUrl,
} from './devices.constants';
import {
  DeviceDetailsType,
  DeviceType,
  DeviceUpdatesType,
} from './devices.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError } from '../../types';
import {
  buildUrlFromFilters,
  fetchApiRequest,
  getRequestOptions,
  useRequestOptions,
} from '../../utils';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';
import { globalQueryKeys } from '../global-query-keys';
import { spacesQueryKeys, SpaceType } from '../spaces';

export function useDevices(
  tableState: Pick<
    TableState<DeviceType>,
    'sortBy' | 'filters' | 'pageIndex' | 'pageSize'
  >,
  columns: Array<TableColumn>,
  baseUrl: string = DEVICES_API_URL,
  queryKey = devicesQueryKeys.all()
) {
  return usePaginatedTableApiQuery<DeviceType>({
    baseUrl,
    queryKey: [...queryKey, baseUrl, tableState],
    tableState,
    columns,
    queryOptions: {
      refetchInterval: 30000,
    },
  });
}

export function useDevicesInfiniteQuery(
  params: PaginatedQueryParamsType<DeviceType>,
  queryOptions?: UseInfiniteQueryOptions<PaginationResponse<DeviceType>>
) {
  const auth = useAuth();

  return useInfiniteQuery<PaginationResponse<DeviceType>>({
    queryKey: [...devicesQueryKeys.all(), JSON.stringify(params)],
    staleTime: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.page_info.has_next) {
        return allPages.length;
      }

      return undefined;
    },
    queryFn: (context) => {
      const { url, options } = getRequestOptions(
        { url: DEVICES_API_URL },
        auth
      );

      const requestUrl = buildUrlFromFilters({
        url,
        pagination: {
          pageSize: params.pagination.pageSize,
          page: context.pageParam || params.pagination.page,
        },
        filters: params.filters,
        sorting: params.sorting,
      });

      return fetchApiRequest(requestUrl, options);
    },
    ...queryOptions,
  });
}

export function useDevice(
  deviceId: string,
  queryOptions?: QueryOptions<DeviceDetailsType>
) {
  return useApiQuery<DeviceDetailsType>(
    `${DEVICES_API_URL}/${deviceId}`,
    devicesQueryKeys.details(deviceId),
    {
      ...queryOptions,
      enabled: !!deviceId && (queryOptions?.enabled ?? true),
      cacheTime: 0,
    }
  );
}

interface UseMoveDeviceParams {
  space_id: SpaceType['id'];
  device_id: string;
}

export function useMoveDevice() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<void, ServerError, UseMoveDeviceParams>({
    mutationFn: ({ space_id, device_id }) =>
      fetchApiRequest(`${url}${getDeviceApiUrl(device_id)}/move`, {
        ...options,
        body: JSON.stringify({
          space_id,
        }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    meta: {
      mutationName: 'useMoveDevice',
      baseUrl: `${DEVICES_API_URL}/:id/move`,
      method: 'POST',
    },
  });
}

interface UseBulkDeleteDevicesParams {
  device_ids: Array<string>;
  with_children?: boolean;
}

export function useBulkDeleteDevices() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<void, { error: any }, UseBulkDeleteDevicesParams>({
    mutationFn: ({ device_ids: ids, with_children = false }) =>
      fetchApiRequest(`${url}${DEVICES_API_URL}/bulk_destroy`, {
        ...options,
        body: JSON.stringify({ ids, with_children }),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useBulkDeleteDevices',
      baseUrl: `${DEVICES_API_URL}/bulk_destroy`,
      method: 'POST',
    },
  });
}

interface UseAddDevicePayload {
  device_model_id: string;
  space_id?: SpaceType['id'];
  name?: string;
  cloud_id?: string;
  mac?: string;
  sn?: string;
  zoom_room?: string;
  c2c_id?: string;
}

export function useAddDevice() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: '',
    method: 'POST',
  });

  return useMutation<DeviceType, ServerError, UseAddDevicePayload>({
    mutationFn: (payload) =>
      fetchApiRequest(`${url}${DEVICES_API_URL}`, {
        ...options,
        body: JSON.stringify(payload),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(spacesQueryKeys.base);
    },
    onError: ({ error }, { device_model_id, name, cloud_id }) => {
      if (error === 'No matching device found') {
        Pendo.track('Error: No matching device found (v1)', {
          device_model_id,
          name,
          cloud_id,
        });
      }

      toastrError(error);
    },
    meta: {
      mutationName: 'useAddDevice',
      baseUrl: DEVICES_API_URL,
      method: 'POST',
    },
  });
}

export function useSnoozeDevice(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getDeviceApiUrl(deviceId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: (minutes: number) => {
      return fetchApiRequest(`${url}/snooze`, {
        ...options,
        body: JSON.stringify({ minutes }),
      });
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Device snoozed successfully'));

      queryClient.invalidateQueries(globalQueryKeys.devices);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useSnoozeDevice',
      baseUrl: `${DEVICES_API_URL}/:id/snooze`,
      method: 'PUT',
    },
  });
}

export function useUnsnoozeDevice(deviceId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: getDeviceApiUrl(deviceId),
    method: 'PUT',
  });

  return useMutation({
    mutationFn: () => {
      return fetchApiRequest(`${url}/unsnooze`, options);
    },
    onSuccess: () => {
      dispatch(toastrSuccess('Device unsnoozed successfully'));

      queryClient.invalidateQueries(globalQueryKeys.devices);
    },
    onError: ({ error }: { error: string }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUnsnoozeDevice',
      baseUrl: `${DEVICES_API_URL}/:id/unsnooze`,
      method: 'PUT',
    },
  });
}

export function useIsFirstClaimedDevice() {
  const { url, options } = useRequestOptions({ url: DEVICES_API_URL });

  const requestUrl = buildUrlFromFilters<DeviceType>({
    url,
    pagination: {
      page: 0,
      pageSize: 10,
    },
  });

  return useQuery<PaginationResponse<DeviceType>, ServerError, boolean>({
    queryKey: devicesQueryKeys.all(),
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => {
      return response.data?.length === 1;
    },
  });
}

export function useFirstClaimedDevice() {
  const { url, options } = useRequestOptions({ url: DEVICES_API_URL });

  const requestUrl = buildUrlFromFilters<DeviceType>({
    url,
    pagination: {
      page: 0,
      pageSize: 10,
    },
  });

  return useQuery<
    PaginationResponse<DeviceType>,
    ServerError,
    DeviceType | undefined
  >({
    queryKey: devicesQueryKeys.all(),
    staleTime: 0,
    queryFn: () => fetchApiRequest(requestUrl, options),
    select: (response) => {
      return response.data[0];
    },
  });
}

export function useDeviceUpdates(
  deviceId: string,
  queryOptions?: QueryOptions<DeviceUpdatesType>
) {
  return useApiQuery<DeviceUpdatesType>(
    `${DEVICES_API_URL}/${deviceId}/updates`,
    devicesQueryKeys.all(),
    {
      ...queryOptions,
      enabled: !!deviceId,
      cacheTime: 0,
      meta: {
        baseUrl: `${DEVICES_API_URL}/:id/updates`,
        method: 'GET',
      },
    }
  );
}
