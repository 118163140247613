import { Box, createStyles, Stack } from '@mantine/core';
import React, { useMemo } from 'react';

import { ASSETS_API_URL } from '@portals/api/organizations';

import { OverviewAssetsList } from './OverviewAssetsList';
import { useOverviewContext } from '../overview.context';
import { useCurrentSpace } from '../overview.hooks';
import { SpaceDataLevelControl } from '../SpaceDataLevelControl';

export function OverviewAssets() {
  const overview = useOverviewContext();
  const { classes } = useStyles();

  const space = useCurrentSpace();

  const sortUrlsSearchParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams();

    if (overview.isLocalDataLevel && space.space_type !== 'root') {
      urlSearchParams.append('q[space_id_eq]', `${space.id}`);
    } else {
      urlSearchParams.append(
        'q[space_path_ltree_starts_with]',
        `"${encodeURIComponent(space.path.join('.'))}"`
      );
    }

    return urlSearchParams.toString();
  }, [space.id, overview.isLocalDataLevel, space.path, space.space_type]);

  const dataHookUrl = `${ASSETS_API_URL}?${sortUrlsSearchParams}`;

  return (
    <Stack className={classes.wrapper} px="xxl" pt="xs">
      <Box sx={{ flexGrow: 1 }}>
        <SpaceDataLevelControl />
      </Box>

      <Box h="100%" w="100%">
        <OverviewAssetsList dataHookUrl={dataHookUrl} />
      </Box>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.gray[0],
  },
}));
