import { px, Select, SelectProps, useMantineTheme } from '@mantine/core';
import React from 'react';

import {
  IncidentType,
  useUpdateIncidentAssignee,
} from '@portals/api/organizations';
import { useUsers } from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';
import { useHasEntityAccess } from '@portals/framework/EntityAccess';
import { AccessLevelEnum } from '@portals/types';

import { AssigneeSelectItem } from './AssigneeSelectItem';

interface AssigneeSelectProps extends Omit<SelectProps, 'data' | 'value'> {
  incident: IncidentType;
  avatarSize?: number;
  value?: string | null;
}

export function AssigneeSelect({
  incident,
  avatarSize = 34,
  ...selectProps
}: AssigneeSelectProps) {
  const hasEditAccess = useHasEntityAccess(incident, AccessLevelEnum.Edit);
  const updateIncidentAssignee = useUpdateIncidentAssignee();
  const users = useUsers();
  const theme = useMantineTheme();

  const assigneeDisplayName = incident.assignee?.name || 'Unassigned';

  const assignableUsers =
    users.data?.map(({ id, name, settings }) => ({
      value: id,
      label: name,
      imageUrl: settings.profile_image,
    })) || [];

  const dropdownData = incident.assignee
    ? [{ value: '', label: 'UnassignButton' }, ...assignableUsers]
    : assignableUsers;

  const handleSelectAssignee = (selectedAssigneeId: string) => {
    const selectedAssignee = assignableUsers.find(
      (assignee) => assignee.value === selectedAssigneeId
    );

    updateIncidentAssignee.mutate({
      deviceId: incident.device_id,
      incidentId: incident.id,
      assignee_id: selectedAssignee ? selectedAssignee.value : null,
    });
  };

  return (
    <Select
      data={dropdownData}
      withinPortal
      value={incident.assignee?.id}
      placeholder={assigneeDisplayName}
      styles={assigneeSelectStyles}
      disabled={!hasEditAccess || incident.status === 'closed'}
      itemComponent={AssigneeSelectItem}
      dropdownComponent="div"
      iconWidth={avatarSize + px(theme.spacing.sm)}
      icon={
        <NameAbbreviationAvatar
          radius="xl"
          color={incident.assignee?.id ? undefined : 'gray.5'}
          size={avatarSize}
          withToolTip={false}
          src={incident.assignee?.name}
          name={incident.assignee?.name || ''}
        />
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={handleSelectAssignee}
      inputContainer={(children) => <div>{children}</div>}
      {...selectProps}
    />
  );
}

const assigneeSelectStyles: SelectProps['styles'] = (theme) => ({
  input: {
    width: '100%',
    borderWidth: 0,
    color: theme.colors.gray[9],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
