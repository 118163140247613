import { Box, createStyles, Group, Stack, Text, Tooltip } from '@mantine/core';
import React from 'react';

import { DeviceType } from '@portals/api/organizations';
import { ReactComponent as Hierarchy2 } from '@portals/icons/linear/hierarchy-2.svg';
import { ReactComponent as Hierarchy } from '@portals/icons/linear/hierarchy.svg';

interface DeviceRelationsIndicatorProps {
  device: DeviceType;
}

export function DeviceRelationsIndicator({
  device,
}: DeviceRelationsIndicatorProps) {
  const { classes } = useStyles();

  if (device.parent_device) {
    return (
      <Tooltip
        withinPortal
        withArrow
        label={
          <Stack w={120} px="md" py="xs" spacing={4}>
            <Text size="xs">Parent Device:</Text>

            <Text size="xs">{device?.parent_device?.name || 'Unknown'}</Text>
          </Stack>
        }
        disabled={!device.parent_device}
      >
        <Group align="center" spacing={4} className={classes.container}>
          <Box sx={(theme) => ({ color: theme.colors.blue_gray[4] })}>
            <Hierarchy2 width={16} height={16} />
          </Box>
        </Group>
      </Tooltip>
    );
  }

  if (device.child_devices_count > 0) {
    return (
      <Tooltip
        withinPortal
        withArrow
        label="Number of child devices"
        disabled={!device.child_devices_count && !device.parent_devicetrue}
      >
        <Group align="center" spacing={4} noWrap className={classes.container}>
          <Box sx={(theme) => ({ color: theme.colors.blue_gray[4] })}>
            <Hierarchy width={16} height={16} />
          </Box>

          <Text size="xs" color="gray.5">
            {device.child_devices_count}
          </Text>
        </Group>
      </Tooltip>
    );
  }

  return null;
}

const useStyles = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 21,
    padding: `3px 12px`,
  },
}));
