import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import {
  C2CConnectorName,
  ConnectorImportDetails,
  ConnectorType,
  SetupLink,
} from './connector.types';
import {
  CONNECTORS_API_URL,
  connectorsQueryKeys,
} from './connectors.constants';
import { useApiQuery } from '../../hooks';
import { QueryOptions, ServerError, ServerSuccess } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { spacesQueryKeys, SpaceType } from '../spaces';

export function useConnectors() {
  return useApiQuery<ConnectorType[]>(
    CONNECTORS_API_URL,
    connectorsQueryKeys.all(),
    { staleTime: 0 }
  );
}

export function useConnectorById(
  connectorId: string,
  queryOptions: QueryOptions<ConnectorType> = {}
) {
  return useApiQuery<ConnectorType>(
    `${CONNECTORS_API_URL}/${connectorId}`,
    connectorsQueryKeys.details(connectorId),
    { staleTime: 0, ...queryOptions }
  );
}

interface UseCreateNewConnectorParams {
  connectorName: C2CConnectorName;
  spaceId: SpaceType['id'];
}

export function useCreateNewConnector() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'POST',
  });

  return useMutation<ConnectorType, ServerError, UseCreateNewConnectorParams>(
    ({ connectorName, spaceId }) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          name: connectorName,
          space_id: spaceId,
        }),
      }),
    {
      meta: {
        mutationName: 'useCreateNewConnector',
        baseUrl: CONNECTORS_API_URL,
        method: 'POST',
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector created');

        return queryClient.invalidateQueries(spacesQueryKeys.base);
      },
    }
  );
}

export function useDeleteConnector() {
  const { url: baseUrl, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'DELETE',
  });

  return useMutation<ServerSuccess, ServerError, string>(
    (integrationId) => fetchApiRequest(`${baseUrl}/${integrationId}`, options),
    {
      meta: {
        mutationName: 'useDeleteConnector',
        baseUrl: `${CONNECTORS_API_URL}/:id`,
        method: 'POST',
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector removed');
      },
    }
  );
}

interface UseSetConnectorParams {
  connectorParams: any;
  connectorId: string;
}

export function useSetConnectorParams<ConnectorExtra = Record<string, any>>() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'PUT',
  });

  return useMutation<
    ConnectorType<ConnectorExtra>,
    ServerError,
    UseSetConnectorParams
  >(
    ({ connectorId, connectorParams }) =>
      fetchApiRequest(`${url}/${connectorId}/set_params`, {
        ...options,
        body: JSON.stringify(connectorParams),
      }),
    {
      meta: {
        mutationName: 'useSetConnectorParams',
        baseUrl: `${CONNECTORS_API_URL}/:id/set_params`,
        method: 'PUT',
      },
      onSettled: (_response, _error, variables) => {
        queryClient.refetchQueries(connectorsQueryKeys.all());
        queryClient.refetchQueries(
          connectorsQueryKeys.details(variables.connectorId)
        );
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector updated');
      },
    }
  );
}

export function useDisableConnector() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'PUT',
  });

  return useMutation<ConnectorType, ServerError, string>(
    (connectorId) => fetchApiRequest(`${url}/${connectorId}/disable`, options),
    {
      meta: {
        mutationName: 'useDisableConnector',
        baseUrl: `${CONNECTORS_API_URL}/:id`,
        method: 'PUT',
      },
      onSettled: (_response, _error, connectorId) => {
        queryClient.refetchQueries(connectorsQueryKeys.all());
        queryClient.refetchQueries(connectorsQueryKeys.details(connectorId));
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector disabled');
      },
    }
  );
}

export function useEnableConnector() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'PUT',
  });

  return useMutation<ConnectorType, ServerError, string>(
    (connectorId) => fetchApiRequest(`${url}/${connectorId}/enable`, options),
    {
      meta: {
        mutationName: 'useEnableConnector',
        baseUrl: `${CONNECTORS_API_URL}/:id`,
        method: 'PUT',
      },
      onSettled: (_response, _error, connectorId) => {
        queryClient.refetchQueries(connectorsQueryKeys.all());
        queryClient.refetchQueries(connectorsQueryKeys.details(connectorId));
      },
      onError: (error) => {
        toastrError(error?.error);
      },
      onSuccess: () => {
        toastrSuccess('Connector enabled');
      },
    }
  );
}

export function useGenerateConnectorSetupLink() {
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: CONNECTORS_API_URL,
    method: 'POST',
  });

  return useMutation<SetupLink, ServerError, string>(
    (connectorId) =>
      fetchApiRequest(`${url}/${connectorId}/generate_setup_link`, options),
    {
      onSettled: (_response, _error, connectorId) => {
        queryClient.refetchQueries(connectorsQueryKeys.all());
        queryClient.refetchQueries(connectorsQueryKeys.details(connectorId));
      },
      meta: {
        mutationName: 'useGenerateDomotzSetupLink',
        baseUrl: `${CONNECTORS_API_URL}/:id/generate_setup_link`,
        method: 'POST',
      },
    }
  );
}

export function useConnectorImportDetails(params: {
  connectorId: string;
  isEnabled: boolean;
}) {
  const { connectorId, isEnabled } = params;

  return useApiQuery<ConnectorImportDetails>(
    `${CONNECTORS_API_URL}/${connectorId}/import_details`,
    connectorsQueryKeys.importDetails(connectorId),
    {
      enabled: isEnabled,
      refetchInterval: (response) => {
        // When import status is `unavailable`, it means something went wrong and there's no reason to keep polling
        if (response?.import_status === 'unavailable') {
          return false;
        }

        return 10_000;
      },
    }
  );
}
