import { Group } from '@mantine/core';
import React from 'react';

import {
  OverviewSettingsTabEnum,
  OverviewSettingsTabType,
} from './settings-drawer.types';
import { Access } from './tabs/access';
import { IncidentRoutingWrapper } from './tabs/incident-routing';
import { Space } from './tabs/space';

export const OVERVIEW_SETTINGS_TABS: Record<
  OverviewSettingsTabEnum,
  OverviewSettingsTabType
> = {
  [OverviewSettingsTabEnum.General]: {
    id: OverviewSettingsTabEnum.General,
    sidebarLabel: 'General',
    tabLabel: 'General',
    component: <Space />,
  },
  [OverviewSettingsTabEnum.IncidentRouting]: {
    id: OverviewSettingsTabEnum.IncidentRouting,
    sidebarLabel: 'Incident Routing',
    tabLabel: (
      <Group align="center" spacing="lg">
        Incident Routing
      </Group>
    ),
    component: <IncidentRoutingWrapper />,
    visible: ({ portalCapabilities }) => portalCapabilities.paid_plans === true,
  },
  [OverviewSettingsTabEnum.Access]: {
    id: OverviewSettingsTabEnum.Access,
    sidebarLabel: 'Access',
    tabLabel: 'Access',
    component: <Access />,
  },
};
