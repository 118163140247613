import { TableColumn, TableState } from '@portals/types';

import {
  WARRANTIES_API_URL,
  warrantiesQueryKeys,
} from './warranties.constants';
import { DeviceWarrantyType } from './warranties.types';
import { usePaginatedTableApiQuery } from '../../utils/paginated-table';

export function useWarranties(
  tableState: Partial<
    Pick<TableState<DeviceWarrantyType>, 'filters' | 'sortBy'>
  >,
  columns: TableColumn<DeviceWarrantyType>[]
) {
  return usePaginatedTableApiQuery<DeviceWarrantyType>({
    baseUrl: WARRANTIES_API_URL,
    tableState,
    columns,
    queryKey: [...warrantiesQueryKeys.all(), tableState],
  });
}
