import { SegmentedControl, Stack, Text, Tooltip } from '@mantine/core';
import { capitalize } from 'lodash/fp';
import React, { useMemo, useState } from 'react';

import {
  DeviceType,
  IncidentClosedMethodEnum,
  INCIDENTS_API_URL,
  incidentsQueryKeys,
  IncidentType,
  useIncidents,
} from '@portals/api/organizations';
import { TextWithTooltip } from '@portals/core';
import { IncidentStatusBadge } from '@portals/framework';
import { getDeviceName, RouteModalLink } from '@portals/framework/route-modals';
import { PaginatedTable } from '@portals/table';
import {
  PaginatedFilterTypeEnum,
  TableColumn,
  TableFilterTypeEnum,
} from '@portals/types';
import { formatDateTime, PRIORITY_OPTIONS, timeFromNow } from '@portals/utils';

import { IncidentDetailsPanel } from '../../../../components/Incidents/IncidentDetailsPanel';
import { AssigneeSelect } from '../../../../pages/incidents/AssigneeSelect';
import { PriorityColumn } from '../../../../pages/incidents/PriorityColumn';
import { SpacePath } from '../../../../pages/overview/overview-dashboard/widgets/incidents/SpacePath';

interface IncidentsProps {
  device: DeviceType;
}

const FILTER_OPTIONS = {
  active: 'Active',
  snoozed: 'Snoozed',
  resolved: 'Resolved',
} as const;

type FilterValues = (typeof FILTER_OPTIONS)[keyof typeof FILTER_OPTIONS];

export const getDefaultFilters = (
  deviceId: string,
  selectedTab: FilterValues
): string => {
  switch (selectedTab) {
    case FILTER_OPTIONS.active:
      return `?q[device_id_eq]=${deviceId}&[status_filter]=active`;

    case FILTER_OPTIONS.snoozed:
      return `?q[device_id_eq]=${deviceId}&[status_filter]=snoozed`;

    case FILTER_OPTIONS.resolved:
    default:
      return `?q[device_id_eq]=${deviceId}&[status_filter]=closed`;
  }
};

export function Incidents({ device }: IncidentsProps) {
  const [filter, setFilter] = useState<FilterValues>(FILTER_OPTIONS.active);

  const columns = useMemo(() => {
    const columns: TableColumn<IncidentType>[] = [
      {
        dataField: 'title',
        text: 'Title',
        sort: true,
        isSticky: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
        formatter: (_, { title, description }) => (
          <TextWithTooltip label={title}>
            <Text c="gray.7" mb={4} truncate>
              {title}
            </Text>
            <Text c="gray.5" truncate>
              {description}
            </Text>
          </TextWithTooltip>
        ),
      },
      {
        dataField: 'priority',
        text: 'Priority',
        maxWidth: 170,
        minWidth: 170,
        sort: true,
        filter: {
          type: PaginatedFilterTypeEnum.Select,
          options: PRIORITY_OPTIONS,
        },
        formatter: (_, incident) => <PriorityColumn incident={incident} />,
      },
      {
        dataField: 'short_id',
        text: 'ID',
      },
      {
        dataField: 'created_at',
        text: 'Created',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Date,
        },
        formatter: (_, { created_at }) => (
          <Tooltip
            label={formatDateTime(created_at)}
            position="top-start"
            withArrow
          >
            <Text>{timeFromNow(created_at)}</Text>
          </Tooltip>
        ),
      },
      {
        dataField: 'customer_name',
        text: 'Customer',
        minWidth: 190,
      },
      {
        dataField: 'space_tree_path_name',
        text: 'Space',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
        formatter: (_, { space_tree_path_name }) => (
          <Tooltip label={space_tree_path_name}>
            <SpacePath spacePath={space_tree_path_name} />
          </Tooltip>
        ),
      },
      {
        dataField: 'device_name',
        text: 'Device',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
        formatter: (_, { device_name, device_id }) => (
          <RouteModalLink
            modalId="device"
            pathParams={[device_id, 'incidents']}
          >
            {getDeviceName(device_name)}
          </RouteModalLink>
        ),
      },
      {
        dataField: 'device_model',
        text: 'Model',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'partner_display_name',
        text: 'Manufacturer',
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Text,
        },
      },
      {
        dataField: 'assignee',
        text: 'Assigned to',
        minWidth: 220,
        formatter: (_, incident) => (
          <AssigneeSelect
            incident={incident}
            data-analyticsid="device-modal-incidents-table-row-select-assignee"
          />
        ),
      },
      {
        dataField: 'status',
        text: 'Status',
        formatter: (_, incident) => <IncidentStatusBadge incident={incident} />,
      },
      {
        dataField: 'updated_at',
        text: 'Updated',
        hidden: true,
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Date,
        },
        formatter: (_, { updated_at }) => {
          if (!updated_at) return null;

          return (
            <Tooltip
              label={formatDateTime(updated_at)}
              position="top-start"
              withArrow
            >
              <Text>{timeFromNow(updated_at)}</Text>
            </Tooltip>
          );
        },
      },
    ];

    if (filter === FILTER_OPTIONS.resolved) {
      columns.push({
        dataField: 'closed_method',
        text: 'Resolved by',
        sort: true,
        minWidth: 215,
        filter: {
          type: TableFilterTypeEnum.Select,
          options: {
            [IncidentClosedMethodEnum.Manually]: 'Manually',
            [IncidentClosedMethodEnum.Automatically]: 'Automatically',
            [IncidentClosedMethodEnum.Device]: 'Device',
            [IncidentClosedMethodEnum.Integration]: 'Integration',
          },
        },
        formatter: (_, { closed_method, closed_by }) => {
          if (!closed_method) return null;

          return (
            <Text>
              <Text span color="gray.7">
                {capitalize(closed_method)}{' '}
              </Text>
              {closed_by ? (
                <Text span color="gray.5">
                  (by {closed_by.name})
                </Text>
              ) : null}
            </Text>
          );
        },
      });

      columns.push({
        dataField: 'closed_at',
        text: 'Resolved at',
        hidden: true,
        sort: true,
        filter: {
          type: TableFilterTypeEnum.Date,
        },
        formatter: (_, { closed_at }) => {
          if (!closed_at) return null;

          return (
            <Tooltip
              label={formatDateTime(closed_at)}
              position="top-start"
              withArrow
            >
              <Text>{timeFromNow(closed_at)}</Text>
            </Tooltip>
          );
        },
        minWidth: 215,
      });
    }

    return columns;
  }, [filter]);

  return (
    <Stack
      spacing="xl"
      py="xl"
      sx={{
        height: '100%',
      }}
    >
      <SegmentedControl
        data={[
          FILTER_OPTIONS.active,
          FILTER_OPTIONS.snoozed,
          FILTER_OPTIONS.resolved,
        ]}
        onChange={(filter: FilterValues) => setFilter(filter)}
        value={filter}
        data-testid="location-tabs"
      />

      <PaginatedTable<IncidentType>
        key={filter}
        keyField="id"
        name={`device-incidents-${filter}`}
        columns={columns}
        dataHookUrl={`${INCIDENTS_API_URL}/${getDefaultFilters(
          device.id,
          filter
        )}`}
        dataHook={useIncidents}
        dataHookQueryKey={[...incidentsQueryKeys.base, 'device-details']}
        noDataIndication={{
          title:
            filter === FILTER_OPTIONS.resolved
              ? 'No incidents'
              : 'No resolved incidents',
        }}
        noHeader
        isUrlSyncEnabled={false}
        defaultSortBy={
          filter === FILTER_OPTIONS.resolved
            ? [{ id: 'created_at', desc: true }]
            : [{ id: 'priority', desc: false }]
        }
        detailsPanel={{
          type: 'inner',
          renderer: ({ row, onClose }) => (
            <IncidentDetailsPanel
              incident={row.original}
              onClosePanel={onClose}
            />
          ),
        }}
      />
    </Stack>
  );
}
