import {
  ActionIcon,
  Badge,
  Button,
  createStyles,
  Divider,
  Group,
  Menu,
  Tooltip,
} from '@mantine/core';
import React, { useMemo } from 'react';

import { ReactComponent as CloseXIcon } from '@portals/icons/linear/close-x.svg';
import { ReactComponent as FlashIcon } from '@portals/icons/linear/flash.svg';
import { ReactComponent as TrashIcon } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { SupportedCommandType } from '@portals/types';

import { useOverviewDevicesContext } from './OverviewDevicesProvider';
import { DeleteDeviceModalProps, RunCommandProps } from '../../../modals';
import { useDevicesCommonDeviceModel } from '../../devices/devices.hooks';

export const DevicesBulkActions = React.memo(() => {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const { clearSelectedDeviceIds, selectedDeviceIds, allDevices } =
    useOverviewDevicesContext();

  const selectedDevices = useMemo(
    () => allDevices.filter((device) => selectedDeviceIds.has(device.id)),
    [allDevices, selectedDeviceIds]
  );

  const commonDeviceModel = useDevicesCommonDeviceModel(selectedDevices);

  function scheduleCommand(command: SupportedCommandType) {
    if (!commonDeviceModel) return;

    openModal<RunCommandProps['data']>('RunCommand', {
      deviceType: commonDeviceModel.id,
      command,
      deviceIds: Array.from(selectedDeviceIds),
      onSuccess: clearSelectedDeviceIds,
    });
  }

  function onDelete() {
    openModal<DeleteDeviceModalProps['data']>('DeleteDeviceModal', {
      devices: selectedDevices,
    });
  }

  return (
    <Group>
      <Badge
        size="lg"
        className={classes.badge}
        rightSection={
          <ActionIcon
            variant="transparent"
            className={classes.unselectAction}
            onClick={clearSelectedDeviceIds}
          >
            <CloseXIcon />
          </ActionIcon>
        }
      >
        {selectedDeviceIds.size} Selected
      </Badge>

      <Divider orientation="vertical" />

      <Group>
        <Tooltip
          label="Selected devices should share same model & vendor"
          disabled={commonDeviceModel !== undefined}
        >
          <div>
            <Menu>
              <Menu.Target>
                <Button
                  size="sm"
                  variant="transparent"
                  disabled={!commonDeviceModel}
                  leftIcon={<FlashIcon width={16} height={16} />}
                >
                  Commands
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {(commonDeviceModel?.supported_commands || []).map(
                  (supportedCommand) => (
                    <Menu.Item
                      key={supportedCommand.id}
                      onClick={() => scheduleCommand(supportedCommand)}
                    >
                      {supportedCommand.friendly_name}
                    </Menu.Item>
                  )
                )}
              </Menu.Dropdown>
            </Menu>
          </div>
        </Tooltip>

        <Button
          p={0}
          c="red.4"
          size="sm"
          variant="transparent"
          leftIcon={<TrashIcon width={16} height={16} />}
          onClick={onDelete}
        >
          Delete
        </Button>
      </Group>
    </Group>
  );
});

const useStyles = createStyles((theme) => ({
  badge: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    backgroundColor: theme.colors.gray[2],
  },
  unselectAction: {
    width: 'fit-content',
    minWidth: 'unset',

    svg: {
      width: 14,
      height: 14,
    },
  },
}));
