import { createStyles, Flex, keyframes } from '@mantine/core';
import React from 'react';

interface LinearProgressBarProps {
  color?: string;
  height?: number;
}

export function LinearProgressBar({
  color,
  height = 7,
}: LinearProgressBarProps) {
  const { classes } = useStyles({ color, height });

  return (
    <Flex align="center" className={classes.loaderContainer}>
      <div className={classes.loaderBar} />
    </Flex>
  );
}

const loadingBar = keyframes({
  from: { transform: 'translateX(-100%)' },
  to: { transform: 'translateX(400%)' },
});

const useStyles = createStyles(
  (theme, params: { color?: string; height: number }) => ({
    loaderContainer: {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: params.height,
      borderRadius: 4,
      backgroundColor: theme.colors.gray[2],
    },
    loaderBar: {
      width: '35%',
      height: `calc(100% - 2px)`,
      borderRadius: 2,
      background: params.color ?? theme.fn.primaryColor(),
      animation: `${loadingBar} 1.5s ease-in-out infinite`,
    },
  })
);
