import { Box, CloseButton, createStyles, Group, Text } from '@mantine/core';
import React, { ReactNode } from 'react';

import { useOverviewRouting } from '../../overview-routing';
import { useOverviewRouteParams } from '../../overview.hooks';
import { OverviewSettingsTabType } from '../settings-drawer.types';

interface SettingsTabContainerProps {
  tab: OverviewSettingsTabType;
  children: ReactNode;
}

export function SettingsTabContainer({
  tab,
  children,
}: SettingsTabContainerProps) {
  const styles = useStyles();
  const params = useOverviewRouteParams();
  const overviewRouting = useOverviewRouting();

  const onClose = () => {
    if (!params.spaceId) return;

    overviewRouting.navigateToOverviewTab(
      { spaceId: Number(params.spaceId), overviewTab: 'dashboard' },
      { replace: true }
    );
  };

  return (
    <Box
      className={styles.cx(styles.classes.container, 'settings-tab-container')}
    >
      <Group
        align="center"
        className={styles.cx(styles.classes.header, 'settings-tab-header')}
        position="apart"
      >
        <Text className={styles.classes.title}>{tab.tabLabel}</Text>

        <CloseButton size="lg" onClick={onClose} />
      </Group>

      <Box
        className={styles.cx(styles.classes.content, 'settings-tab-content')}
      >
        {children}
      </Box>
    </Box>
  );
}

export const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '70px 1fr',
    height: '100%',
  },
  header: {
    height: 70,
    background: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    padding: '0 42px',
  },
  title: {
    fontSize: theme.fontSizes.xl,
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
}));
