import React from 'react';

import { CecPartnerType } from '@portals/types';

import { ConnectionCard, ConnectionCardProps } from './ConnectionCard';

interface CecPartnerConnectionCardProps {
  cecPartner: CecPartnerType;
  highlightText: ConnectionCardProps['highlightText'];
  action: ConnectionCardProps['action'];
}

export function CecPartnerConnectionCard({
  cecPartner,
  action,
  highlightText,
}: CecPartnerConnectionCardProps) {
  return (
    <ConnectionCard
      id={cecPartner.id}
      key={cecPartner.id}
      logo={cecPartner.logo}
      highlightText={highlightText}
      isConnected={cecPartner.connected}
      title={cecPartner.display_name}
      description={cecPartner.about}
      action={action}
    />
  );
}
