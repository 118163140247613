import 'rc-tree/assets/index.css';
import { Box, createStyles, Space } from '@mantine/core';
import { includes, isEmpty, toLower, uniq, without } from 'lodash/fp';
import { default as RcTree, TreeProps } from 'rc-tree';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import { AllowDropOptions } from 'rc-tree/lib/Tree';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useContextMenu } from 'react-contexify';
import { useDragDropManager } from 'react-dnd';

import { SpaceTreeNodeType } from '@portals/api/organizations';
import { usePermissionAccess } from '@portals/framework';
import { VerticalScrollBar } from '@portals/scrollbar';
import { EmptyState } from '@portals/table';
import { filterTreeNodes, getAllNodesIds, searchTree } from '@portals/utils';

import { NodeToggler } from './components/NodeToggler';
import { TreeNodeDnDWrapper } from './components/tree-node/TreeNodeDnDWrapper';
import { TreeHeader } from './components/TreeHeader';
import { TreeContextProvider } from './organization-tree.context';
import {
  canAdmin,
  canEdit,
  canView,
  noAccess,
} from '../../../../../lib/access';
import { CustomLabel } from '../../overview.types';

export interface SpacesTreeProps {
  handleSelected: (nodeId: number) => void;
  handleMoveSpace?: (
    spaceId: number,
    targetSpaceId: number,
    position?: number
  ) => Promise<void> | void;
  handleCreateSpace?: (spaceId: number) => void;

  readonly?: boolean;
  draggable?: boolean;
  hideSearch?: boolean;

  itemHeight?: number;
  itemIdPrefix?: string;
  indent?: number;

  searchTerm: string;
  setSearchTerm: (term: string) => void;

  editModeNodeId?: number | null;
  setEditModeNodeId?: (spaceId: number | null) => void;

  treeNodes: Array<SpaceTreeNodeType>;
  selectedSpaceId: number | null;
  expandedNodes: number[];
  setExpandedNodes: Dispatch<SetStateAction<number[]>>;

  customLabel?: CustomLabel;
  treeHeight?: number;
}

function adjustSpacesTreeLocation(
  data: SpaceTreeNodeType[],
  key: Key,
  callback: (
    item: SpaceTreeNodeType,
    index: number,
    arr: SpaceTreeNodeType[]
  ) => void
) {
  data.forEach((item, index, arr) => {
    if (item.key === key) {
      callback(item, index, arr);
      return;
    }
    if (item.children) {
      adjustSpacesTreeLocation(item.children, key, callback);
    }
  });
}

function sortNestedObjectsByPosition(obj: SpaceTreeNodeType) {
  if (obj.children && obj.children.length > 0) {
    obj.children.sort((a, b) => a.position - b.position);

    obj.children.forEach((child) => {
      sortNestedObjectsByPosition(child);
    });
  } else {
    obj.children.sort((a, b) => a.position - b.position);
  }
}

export function Tree({
  handleSelected,
  draggable,
  readonly,
  itemHeight = 45,
  indent,
  itemIdPrefix,
  searchTerm,
  setSearchTerm,
  handleMoveSpace,
  handleCreateSpace,
  editModeNodeId,
  setEditModeNodeId,
  treeNodes,
  selectedSpaceId,
  expandedNodes,
  setExpandedNodes,
  customLabel,
  treeHeight,
}: SpacesTreeProps) {
  const { cx, classes } = useStyles({ itemHeight, indent });

  const { isAdmin } = usePermissionAccess();

  const treeNodesData = useMemo(() => {
    const data = [...treeNodes];

    data.forEach((item) => {
      sortNestedObjectsByPosition(item);
    });

    return data;
  }, [treeNodes]);

  // Toggled to `true` when a device is dragged over the tree, which in return sets the Tree's
  // `draggable` prop to false, so we could drop devices on nodes
  const [isDraggingOutsideOfTree, setIsDraggingOutsideOfTree] = useState(false);

  // Passed to all nodes, used as portal ref for rendering each node's context menu on
  // right-click (had to move them all under 1 element, instead of independently rendering on
  // each node, bc of virtualization)
  const contextMenuPortalRef = useRef<HTMLDivElement>(null);
  const { show } = useContextMenu();

  // Passed to rc-tree as ref, used to access inner methods like `scrollTo`
  const treeRef = useRef<RcTree<SpaceTreeNodeType>>(null);
  const ref = useRef<HTMLDivElement>(null);

  const allIds = useMemo(() => getAllNodesIds(treeNodes), [treeNodes]);
  const defaultExpandedNodesRef = useRef(expandedNodes);

  const onMoveNode = useCallback<
    NonNullable<TreeProps<SpaceTreeNodeType>['onDrop']>
  >(
    async (params) => {
      const draggedNode = params?.dragNode;
      const targetNode = params?.node;

      if (
        isNaN(draggedNode.id) ||
        isNaN(targetNode.id) ||
        !canEdit(targetNode) ||
        !canEdit(draggedNode)
      ) {
        return;
      }

      const dropKey = params.node.key;
      const dragKey = params.dragNode.key;
      const dropPosition = params.dropPosition - params.dragNode.position;

      const adjustedData = [...treeNodesData];

      // Find dragObject
      let dragObj: SpaceTreeNodeType | undefined;

      if (dropPosition !== 0) {
        adjustSpacesTreeLocation(
          adjustedData,
          dragKey,
          //find the grabbed object first
          (item, index, arr) => {
            arr.splice(index, 1);
            // where to insert
            dragObj = item;
          }
        );
        // Drop on the gap (insert before or insert after)
        let arrayToManipulate: SpaceTreeNodeType[] | null = [];
        let whereToInsertDragObjIndex = 0;

        adjustSpacesTreeLocation(
          adjustedData,
          dropKey,
          (item: SpaceTreeNodeType, _, arr: SpaceTreeNodeType[]) => {
            arrayToManipulate = arr;
            whereToInsertDragObjIndex = item.position;
          }
        );

        if (targetNode.parent_id && dragObj) {
          if (dropPosition > 0) {
            arrayToManipulate.splice(whereToInsertDragObjIndex, 0, dragObj);

            handleMoveSpace?.(
              dragObj.id,
              targetNode.parent_id,
              whereToInsertDragObjIndex
            );
          } else {
            arrayToManipulate.splice(whereToInsertDragObjIndex + 1, 0, dragObj);

            handleMoveSpace?.(
              dragObj.id,
              targetNode.parent_id,
              whereToInsertDragObjIndex + 1
            );
          }
        }
      }

      if (!params.dropToGap) {
        handleMoveSpace?.(draggedNode.id, targetNode.id);
      }
    },
    [handleMoveSpace, treeNodesData]
  );

  // Monitor react-dnd dragging state, to disable tree's `draggable` prop, so devices could be
  // dropped on nodes. Otherwise, if dragging a device while Tree's `draggable` set to true, the
  // hover & drop will not be recognized
  const monitor = useDragDropManager().getMonitor();

  useEffect(
    function subscribeToDndStateChange() {
      const unsubscribe = monitor.subscribeToStateChange(() => {
        const isDragging = monitor.isDragging();
        const isDevice = monitor.getItemType() === 'device';

        setIsDraggingOutsideOfTree(isDragging && isDevice);
      });

      return () => {
        unsubscribe();
      };
    },
    [monitor]
  );

  const filteredTreeNodes = useMemo<Array<SpaceTreeNodeType>>(() => {
    if (!searchTerm) return treeNodesData;

    return filterTreeNodes(treeNodesData, searchTerm);
  }, [searchTerm, treeNodesData]);

  const isEditable = useCallback(
    (node: SpaceTreeNodeType) => !readonly && (isAdmin || canAdmin(node)),
    [readonly, isAdmin]
  );

  const canDeleteCustomerNodes = useMemo(() => {
    if (!treeNodes?.[0]) {
      return false;
    }

    const customerNodes = treeNodes[0].children.filter(
      (space) => space.space_type === 'customer'
    );

    return customerNodes.length > 1;
  }, [treeNodes]);

  const isRemovable = useCallback(
    (node: SpaceTreeNodeType) => {
      // Root space cannot be deleted
      if (node.space_type === 'root') return false;

      // Non-editable spaces cannot be deleted
      if (!isEditable(node)) return false;

      // `customer` type spaces can be deleted only when there is more than one `customer` type space
      if (node.space_type === 'customer') return canDeleteCustomerNodes;

      return true;
    },
    [canDeleteCustomerNodes, isEditable]
  );

  const onRightClick = useCallback<
    NonNullable<TreeProps<SpaceTreeNodeType>['onRightClick']>
  >(
    ({
      event,
      node,
    }: {
      event: React.MouseEvent;
      node: EventDataNode<SpaceTreeNodeType>;
    }) => {
      if (!isEditable(node) || node.space_type === 'root') {
        return;
      }

      show({ event, id: node.id });
    },
    [isEditable, show]
  );

  const getIsDraggable: NonNullable<TreeProps<SpaceTreeNodeType>['draggable']> =
    useCallback(
      (node) => {
        if (isDraggingOutsideOfTree || !isEditable(node) || !draggable) {
          return false;
        }

        const currentSpace = searchTree(treeNodes[0], node.id);

        if (!currentSpace) return false;

        return currentSpace.parent_id !== null && canEdit(currentSpace);
      },
      [draggable, isDraggingOutsideOfTree, isEditable, treeNodes]
    );

  const titleRenderer = useCallback<
    NonNullable<TreeProps<SpaceTreeNodeType>['titleRender']>
  >(
    (node) => {
      // Highlights node's title styling when search is active
      const isInFocus =
        !!searchTerm &&
        includes(toLower(searchTerm), toLower(node.title)) &&
        canView(node);

      return (
        <TreeNodeDnDWrapper
          key={`${node.key}-${node.title}`}
          itemIdPrefix={itemIdPrefix}
          node={node}
          handleCreateSpace={handleCreateSpace}
          contextMenuPortalRef={contextMenuPortalRef}
          isInFocus={isInFocus}
          isEditable={isEditable(node)}
          isRemovable={isRemovable(node)}
          isDraggable={draggable}
          editModeNodeId={editModeNodeId}
          setEditModeNodeId={setEditModeNodeId}
          customLabel={customLabel}
        />
      );
    },
    [
      searchTerm,
      itemIdPrefix,
      handleCreateSpace,
      isEditable,
      isRemovable,
      draggable,
      editModeNodeId,
      setEditModeNodeId,
      customLabel,
    ]
  );

  const onSelect = useCallback<
    NonNullable<TreeProps<SpaceTreeNodeType>['onSelect']>
  >(
    (_, { node }) => {
      if (noAccess(node)) return;

      if (handleSelected) {
        handleSelected(node.id);
      }

      // Expand selected node if not expanded
      if (!node.expanded) {
        const space = searchTree(treeNodes[0], node.id);
        const spacePath = space?.path || [];

        setExpandedNodes(uniq([...expandedNodes, ...spacePath]));
      }
    },
    [expandedNodes, handleSelected, setExpandedNodes, treeNodes]
  );

  const onExpand = useCallback<
    NonNullable<TreeProps<SpaceTreeNodeType>['onExpand']>
  >(
    (_, info) => {
      const { expanded, node } = info;

      if (expanded) {
        setExpandedNodes((curr) => uniq([...curr, node.id]));
      } else {
        const nodeIds = getAllNodesIds([node]);

        setExpandedNodes((curr) => without(nodeIds, curr));
      }
    },
    [setExpandedNodes]
  );

  // Don't render toggle caret for rooms
  const switcherIconRenderer: TreeProps['switcherIcon'] = useCallback(
    (node) =>
      isEmpty(node.data?.children) ? null : <NodeToggler node={node} />,
    []
  );

  const getIsAllowDrop = useCallback(
    ({ dropNode, dragNode }: AllowDropOptions<SpaceTreeNodeType>) => {
      const isDropParent = dragNode?.parent_id === dropNode?.id;

      if (isDropParent) return false;

      const isDropTargetUnderSameCustomer =
        dropNode.root_customer_space_id === dragNode.root_customer_space_id;

      const canEditDraggedNode = canEdit(dragNode);
      const canEditDropNode = canEdit(dropNode);

      return (
        canEditDraggedNode && canEditDropNode && isDropTargetUnderSameCustomer
      );
    },
    []
  );

  const onAppearActive = (node: HTMLDivElement) => {
    if (node.children.length === 0) {
      return {
        height: 0.1,
      };
    }

    return {
      height: node.scrollHeight,
    };
  };

  const onLeaveStart = (node: HTMLDivElement) => {
    if (node.children.length === 0) {
      return {
        height: 0.1,
      };
    }

    return {
      height: node.offsetHeight,
    };
  };

  useEffect(
    function updateSelectedSpaceLocation() {
      if (!selectedSpaceId) {
        return;
      }

      treeRef.current?.scrollTo({ key: selectedSpaceId, align: 'auto' });
    },
    [selectedSpaceId]
  );

  return (
    <TreeContextProvider
      expandedNodes={expandedNodes}
      setExpandedNodes={setExpandedNodes}
      selectedNode={selectedSpaceId}
    >
      <Box className={cx('organization-tree-container', classes.container)}>
        <TreeHeader
          searchString={searchTerm}
          handleSearchOnChange={setSearchTerm}
          isReadonly={readonly}
        />

        {isEmpty(treeNodes) ? (
          <EmptyState label="No spaces found" src="empty-state-location" />
        ) : (
          <Box ref={ref} px="md">
            <VerticalScrollBar
              renderTrackVertical={() => (
                <div style={{ display: 'none' }}></div>
              )}
            >
              <Space h="md" />

              <RcTree<SpaceTreeNodeType>
                autoExpandParent
                virtual
                ref={treeRef}
                treeData={filteredTreeNodes}
                height={treeHeight || ref.current?.clientHeight}
                onRightClick={onRightClick}
                itemHeight={itemHeight}
                fieldNames={{
                  key: 'id',
                  title: 'title',
                  children: 'children',
                }}
                //
                // Expand
                defaultExpandedKeys={defaultExpandedNodesRef.current}
                expandedKeys={searchTerm ? allIds : expandedNodes}
                onExpand={onExpand}
                //
                // Select
                defaultSelectedKeys={[selectedSpaceId ?? '']}
                selectedKeys={[selectedSpaceId ?? '']}
                onSelect={onSelect}
                //
                // Drag & drop
                draggable={getIsDraggable}
                dropIndicatorRender={() => null}
                onDrop={onMoveNode}
                allowDrop={getIsAllowDrop}
                //
                // Renderers
                titleRender={titleRenderer}
                switcherIcon={switcherIconRenderer}
                icon={() => null}
                motion={{
                  motionName: 'node-motion',
                  motionAppear: false,
                  onAppearStart: () => ({ height: 0 }),
                  onAppearActive,
                  onLeaveStart,
                  onLeaveActive: () => ({ height: 0 }),
                }}
              />

              <Space h="md" />
            </VerticalScrollBar>
          </Box>
        )}

        {readonly ? null : <div ref={contextMenuPortalRef} />}
      </Box>
    </TreeContextProvider>
  );
}

const useStyles = createStyles(
  (theme, { itemHeight, indent }: { itemHeight: number; indent?: number }) => ({
    container: {
      display: 'grid',
      gridTemplateRows: 'min-content 1fr 0',
      gap: 0,
      height: '100%',

      '.rc-tree-list': {
        'rc-tree-child-tree': {
          display: 'block',
        },

        '.rc-tree-treenode-motion': {
          transition: 'all 0.2s',
          overflowY: 'hidden',
        },

        '.rc-tree-list-holder': {
          '.rc-tree-list-holder-inner': {
            display: 'grid',
            gridAutoRows: `${itemHeight}px`,

            '.rc-tree-indent-unit': {
              width: indent || theme.spacing.md,
            },

            '.rc-tree-node.drop-container ~ .rc-tree-node': {
              borderLeftColor: theme.colors.gray[5],
            },

            '.rc-tree-treenode': {
              height: `${itemHeight}px`,
              display: 'grid',
              gridTemplateColumns: 'max-content min-content 1fr',
              alignItems: 'center',
              borderLeft: 'none !important',
              padding: '2px 0 !important',

              '&.drag-over-gap-bottom': {
                position: 'relative',
                '.rc-tree-node-content-wrapper': {
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    display: 'block',
                    width: '100%',
                    height: 2,
                    backgroundColor: 'black',
                    bottom: 0,
                    left: 0,
                  },
                },
              },

              '&.drag-over, &.drop-target': {
                backgroundColor: 'transparent',

                '.toggler': {
                  backgroundColor: `${theme.other.primaryColor} !important`,
                },

                '&:not(.drag-over-gap-bottom)': {
                  '.rc-tree-node-content-wrapper': {
                    border: `1.5px dashed ${theme.other.primaryColor}`,
                  },
                },
              },

              '&:not(.dragging)': {
                '.rc-tree-node-content-wrapper': {
                  '&.rc-tree-node-selected': {
                    opacity: 1,
                    color: theme.fn.primaryColor(),
                    backgroundColor: theme.colors.gray[2],
                    borderRadius: theme.radius.md,
                  },
                },
              },

              '&.dragging': {
                backgroundColor: 'transparent',
                opacity: 0.3,
              },

              '.toggler': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              },

              '.rc-tree-node-content-wrapper': {
                display: 'grid !important',
                gridTemplateColumns: '0 1fr',
                height: '100%',
                alignItems: 'center',
                padding: `0 0 0 ${theme.spacing.sm}`,
                gap: 0,
                border: '1px dashed transparent',
                boxShadow: 'none',
                transition: 'all 0.15s ease-in-out',
                borderRadius: theme.radius.md,

                '.drop-indicator': {
                  position: 'absolute',
                  bottom: 0,
                  height: '1px',
                  width: '100%',
                  backgroundColor: theme.colors.gray[5],
                },

                '&:hover': {
                  backgroundColor: theme.colors.gray[0],

                  '&:has(.disabled-tree-node)': {
                    cursor: 'not-allowed',
                  },
                },

                '.rc-tree-icon__customize': {
                  width: 0,
                },
              },
            },
          },
        },
      },
    },
  })
);
