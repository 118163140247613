import { Entity } from '@portals/framework/EntityAccess';
import { AccessLevelEnum } from '@portals/types';

export function noAccess(entity?: Entity) {
  return !entity || entity?.access_level === AccessLevelEnum.None;
}

export function canView(entity?: Entity) {
  return entity && entity?.access_level >= AccessLevelEnum.View;
}

export function canEdit(entity?: Entity) {
  return entity && entity?.access_level >= AccessLevelEnum.Edit;
}

export function canAdmin(entity?: Entity) {
  return entity && entity?.access_level >= AccessLevelEnum.Admin;
}
