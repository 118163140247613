import { Box, createStyles, Paper, Stack, Text } from '@mantine/core';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';

import { DeviceType, useSpace } from '@portals/api/organizations';
import {
  DeviceAvatar,
  DeviceCecPartnerLogoOrName,
  DeviceStatusBadge,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as Drag } from '@portals/icons/linear/drag.svg';
import { DeviceStatusType } from '@portals/types';

import { canEdit } from '../../../../../../../lib/access';
import { DeviceIncidentsCounterWithIcon } from '../../../../../../components/DeviceCounters';
import { DeviceRelationsIndicator } from '../../../../device-indicators/DeviceRelationsIndicator';
import { DeviceSnoozeIndicator } from '../../../../device-indicators/DeviceSnoozeIndicator';
import { DeviceMenu } from '../../../../device-menu/DeviceMenu';

interface DeviceCardLayoutProps {
  device: DeviceType;
  dragRef?: ConnectDragSource;
  isDragging?: boolean;
  isPreview?: boolean;
  width?: number;
}

interface ParamsProps {
  isDragging: boolean;
  isPreview?: boolean;
  width: number;
  status: DeviceStatusType;
}

export function DeviceCardLayout({
  device,
  dragRef,
  isDragging,
  isPreview = false,
  width,
}: DeviceCardLayoutProps) {
  const { classes, cx } = useStyles({
    isDragging,
    isPreview,
    width,
    status: device.status,
  });

  const openRouteModal = useOpenRouteModal();
  const space = useSpace({ spaceId: device.space_id });

  return (
    <Paper
      ref={dragRef && canEdit(space) ? dragRef : null}
      className={classes.staticContainer}
      onClick={() =>
        openRouteModal({ modalId: 'device', pathParams: [device.id] })
      }
    >
      <Stack
        className={cx(classes.centeredColumn, {
          [classes.draggingContainer]: isDragging && !isPreview,
          [classes.preview]: isDragging && isPreview,
        })}
      >
        <Stack className={classes.headingContainer}>
          <DeviceCecPartnerLogoOrName device={device} />

          <Stack className={classes.headingTitleContainer} align="center">
            <Text className={classes.deviceName} lineClamp={1}>
              {device.name}
            </Text>
            <Text className={classes.devicePartnerModel} lineClamp={1}>
              {device.partner.model}
            </Text>
          </Stack>
        </Stack>

        <DeviceAvatar
          src={device.image_url}
          icon={device.model_settings?.icon}
          className={classes.deviceAvatar}
        />

        <div className={classes.iconsGrid}>
          <Box w="fit-content">
            <DeviceIncidentsCounterWithIcon device={device} />
          </Box>

          <DeviceStatusBadge status={device.status} dark />

          <Box w="fit-content">
            <DeviceRelationsIndicator device={device} />
          </Box>
        </div>
      </Stack>

      <Box className={cx(classes.menu, 'device-card-menu')}>
        <DeviceMenu device={device} />
      </Box>

      <DeviceSnoozeIndicator
        device={device}
        classNames={{ target: classes.snooze }}
      />

      {canEdit(space) && !isDragging ? (
        <Drag className={cx('device-drag-icon', classes.dragIcon)} />
      ) : null}
    </Paper>
  );
}

const useStyles = createStyles((theme, params: ParamsProps) => {
  let borderStyle = 'none';

  if (!params.isDragging) {
    if (params.status === 'error') {
      borderStyle = `1.5px solid ${theme.colors.red[4]}`;
    } else {
      borderStyle = `1.5px solid ${theme.colors.gray[3]}`;
    }
  }

  return {
    staticContainer: {
      cursor: 'grab',
      width: params.width,
      backgroundColor:
        params.isDragging && !params.isPreview
          ? theme.colors.gray[0]
          : theme.colors.white,
      position: 'relative',
      borderRadius: 20,
      transition: 'filter 150ms linear',
      height: '100%',
      opacity: 1,
      padding: `${theme.spacing.xxl} 40px`,
      border: borderStyle,

      '.device-card-menu, .device-drag-icon': {
        opacity: 0,
        transition: 'opacity 150ms ease-in-out',
      },

      '&:hover': {
        filter: params.isDragging
          ? 'none'
          : 'drop-shadow(0px 4px 10px rgba(38, 50, 56, 0.13))',

        '.device-card-menu, .device-drag-icon': {
          opacity: 1,
        },
      },
    },

    preview: {
      alignItems: 'center',
      borderRadius: 20,
      transition: 'filter 150ms linear',

      '.device-card-menu, .device-drag-icon': {
        opacity: 0,
        transition: 'opacity 150ms ease-in-out',
      },

      '&:hover': {
        filter: 'drop-shadow(0px 4px 10px rgba(38, 50, 56, 0.13))',

        '.device-card-menu, .device-drag-icon': {
          opacity: 1,
        },
      },
    },
    centeredColumn: {
      alignItems: 'center',
    },
    headingContainer: { gap: theme.spacing.sm, alignItems: 'center' },
    headingTitleContainer: {
      gap: 4,
    },
    deviceName: {
      color: theme.colors.gray[8],
      alignItems: 'center',
    },
    devicePartnerModel: {
      color: theme.colors.gray[5],
      fontSize: theme.fontSizes.xs,
      fontWeight: 300,
      textAlign: 'center',
    },
    deviceAvatar: {
      width: 100,
      height: 100,
      borderRadius: theme.radius.lg,
    },
    draggingContainer: {
      backgroundColor: theme.colors.gray[1],
      position: 'relative',
      height: '100%',
      opacity: 0,
    },

    dragIcon: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      color: theme.colors.gray[4],
    },

    menu: {
      position: 'absolute',
      top: theme.spacing.xxl,
      right: theme.spacing.xxl,
    },

    snooze: {
      position: 'absolute',
      top: theme.spacing.xxl,
      left: theme.spacing.xxl,
    },

    iconsGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr auto 1fr',
      gap: theme.spacing.md,
    },
  };
});
