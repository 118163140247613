import { ActionIcon, createStyles, LoadingOverlay } from '@mantine/core';
import { includes, isEmpty } from 'lodash/fp';
import React, { useEffect } from 'react';

import { useConfig } from '@portals/api/organizations';
import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import { FeaturesNotificationsProps } from '@portals/framework/modals';
import { ReactComponent as AddIcon } from '@portals/icons/linear/add.svg';
import { useOpenModal } from '@portals/redux';

import { DashboardControls } from './DashboardControls';
import { DashboardEmptyState } from './DashboardEmptyState';
import { DashboardWrapper } from './DashboardWrapper';
import { useOverviewContext } from '../overview.context';

export function OverviewDashboard() {
  const overview = useOverviewContext();
  const config = useConfig();

  const currentUser = useCurrentUser();
  const openModal = useOpenModal();

  const { classes } = useStyles({ isConfigMode: overview.isConfigMode });

  useEffect(
    function showFourteenDaysTrialNotification() {
      const isTrialNotificationDismissed = includes(
        FeatureNotificationEnum.FourteenDaysTrial,
        currentUser?.data?.feature_notifications
      );

      if (!isTrialNotificationDismissed) {
        return;
      }

      openModal<FeaturesNotificationsProps['data']>('FeaturesNotifications', {
        featureNotification: FeatureNotificationEnum.FourteenDaysTrial,
      });
    },
    [currentUser?.data?.feature_notifications, openModal]
  );

  if (!config.isFetched) return <LoadingOverlay visible />;

  if (isEmpty(overview.dashboard.list)) {
    return <DashboardEmptyState />;
  }

  return (
    <div className={classes.container}>
      <DashboardControls />

      <DashboardWrapper />

      {overview.isConfigMode && (
        <ActionIcon
          size={70}
          variant="filled"
          className={classes.addWidget}
          onClick={overview.navigateToWidgetsPanel}
        >
          <AddIcon width={46} height={46} />
        </ActionIcon>
      )}
    </div>
  );
}

const useStyles = createStyles(
  (theme, { isConfigMode }: { isConfigMode: boolean }) => ({
    container: {
      position: 'relative',
      zIndex: 0,

      '.react-resizable-handle': {
        display: isConfigMode ? 'unset' : 'none',
      },
    },
    addWidget: {
      position: 'fixed',
      zIndex: 1,
      bottom: 40,
      right: 40,
      borderRadius: '50%',
      boxShadow: `0px 5px 12px rgba(38, 50, 56, 0.33)`,

      ...theme.fn.hover({
        boxShadow: `0px 5px 12px rgba(38, 50, 56, 0.5)`,
      }),
    },
  })
);
