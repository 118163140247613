import { Box, createStyles } from '@mantine/core';
import { useDebouncedValue, useElementSize } from '@mantine/hooks';
import { forEach, isEmpty } from 'lodash/fp';
import React, { useCallback, useMemo } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';

import { DashboardWidgetWrapper } from './DashboardWidgetWrapper';
import {
  DASHBOARD_BREAKPOINTS_KEYS,
  DASHBOARD_COLUMNS,
} from '../overview.constants';
import { useOverviewContext } from '../overview.context';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function DashboardWrapper() {
  const styles = useStyles();
  const overview = useOverviewContext();
  const { ref, width } = useElementSize();
  const [debouncedWidth] = useDebouncedValue(width, 150);

  const widgetsList = useMemo(
    () =>
      (overview.dashboard.list || []).map((item) => (
        <Box
          key={item.i}
          data-grid={item}
          className={styles.classes.draggableWrapper}
        >
          <DashboardWidgetWrapper item={item} />
        </Box>
      )),
    [overview, styles.classes.draggableWrapper]
  );

  const onLayoutChange = useCallback(
    (currentLayout: Array<Layout>) => {
      // Save the layout only for the "lg" breakpoint, which also is the only one we're using to
      // make the dashboard consistent in various resolutions
      overview.onUpdateLayouts(currentLayout, { lg: currentLayout });
    },
    [overview]
  );

  const onDrop = useCallback(
    (layout, layoutItem, event) => {
      const widgetId = event?.dataTransfer?.getData('text/plain');

      if (widgetId) {
        overview.onAddWidget({
          id: widgetId,
        });
      }
    },
    [overview]
  );

  // This is a hacky way to make the dashboard layout consistent between breakpoints
  // We set all breakpoints to be based on 12 columns, meaning that the layout will be the same for all breakpoints
  // When the user sets up his dashboard, he does it for his current breakpoint which could be
  // any of the available ones. We need to make sure that the layout is consistent for all breakpoints
  const adjustedLayouts = useMemo(() => {
    let configuredLayout = overview?.dashboard?.layouts?.lg;

    // If the user has not configured the layout for the current breakpoint, we try to find a layout for any other breakpoint
    // as they all should be the same
    if (isEmpty(configuredLayout)) {
      forEach((breakpointKey) => {
        const breakpointLayout = overview?.dashboard?.layouts?.[breakpointKey];

        if (!isEmpty(breakpointLayout)) {
          configuredLayout = breakpointLayout;

          return false;
        }
      }, DASHBOARD_BREAKPOINTS_KEYS);
    }

    return {
      lg: configuredLayout || [],
    };
  }, [overview?.dashboard?.layouts]);

  return (
    <Box className={styles.classes.container} ref={ref}>
      <ResponsiveGridLayout
        innerRef={overview.dashboardRef}
        key={debouncedWidth}
        cols={DASHBOARD_COLUMNS}
        rowHeight={15}
        margin={[25, 25]}
        containerPadding={[32, 0]}
        layouts={adjustedLayouts}
        isDraggable={overview.isAdmin && overview.isConfigMode}
        isResizable={overview.isAdmin && overview.isConfigMode}
        isDroppable={false}
        onLayoutChange={onLayoutChange}
        useCSSTransforms={false}
        measureBeforeMount
        onDrop={onDrop}
        onDrag={() => overview.setIsDragging(true)}
        onDragStop={() => overview.setIsDragging(false)}
      >
        {widgetsList}
      </ResponsiveGridLayout>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.gray[0],

    '.react-grid-placeholder': {
      backgroundColor: theme.colors.gray[5],
      borderRadius: theme.radius.md,
    },
  },
  draggableWrapper: {
    boxShadow: 'none',
    borderRadius: theme.radius.md,

    '&.react-draggable-dragging': {
      boxShadow: theme.shadows.xl,
    },
  },
}));
