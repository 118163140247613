import React, { MutableRefObject, ReactNode } from 'react';

import { SpaceTreeNodeType } from '@portals/api/organizations';

export type WidgetType =
  | 'incident_map'
  | 'devices_map'
  | 'devices_list_table'
  | 'devices_list_map'
  | 'devices_list'
  | 'files_list'
  | 'incidents_list'
  | 'claimed_devices'
  | 'online_devices'
  | 'active_incidents'
  | 'note'
  | 'space_incidents_rate'
  | 'device_incidents_rate'
  | 'devices_model_breakdown'
  | 'devices_per_space'
  // TODO: Legacy widgets - remove when legacy widgets are fully deprecated
  | 'devices_overview_modern'
  | 'incident_summary_modern'
  | 'top_spaces_with_incidents'
  | 'top_incidents'
  | 'device_incidents_age'
  | 'devices_by_model_in_space';

export interface DashboardComponent {
  id: WidgetType;
  name: string;
  description: string;
  Component: React.ComponentType<{
    widgetId?: string;
    closeModal?: () => void;
  }>;
  thumbnail: string | null;
  icon: string | null;
  withSettings?: boolean;
  minW?: number;
  minH?: number;
  maxW?: number;
  maxH?: number;
  h?: number;
  w?: number;
  isLegacy?: boolean;
}

export enum DataLevelEnum {
  All = 'all',
  Local = 'local',
}

export enum TimeUnit {
  Seconds = 'sec',
  Minutes = 'min',
  Hours = 'hrs',
}

export const PRIORITY_NAMES = {
  critical: 'critical',
  high: 'high',
  moderate: 'moderate',
  low: 'low',
  planning: 'planning',
} as const;

export const PRIORITY_NUMERIC_VALUES = {
  critical: 1,
  high: 2,
  moderate: 3,
  low: 4,
  planning: 5,
} as const satisfies Record<PriorityName, number>;

export type PriorityName = keyof typeof PRIORITY_NAMES;
export type PriorityNumericValue =
  (typeof PRIORITY_NUMERIC_VALUES)[keyof typeof PRIORITY_NUMERIC_VALUES];

export type BarType = {
  dataKey: PriorityName;
  fill: string;
  barSize: number;
};

export interface TierType {
  displayName: string;
  firstValue: number;
  lastValue: number;
  firstValueTimeUnit: TimeUnit;
  lastValueTimeUnit: TimeUnit;
  incidentCount?: number;
  planning?: number;
  low?: number;
  moderate?: number;
  high?: number;
  critical?: number;
  id: string;
}

export interface ActiveIncidentsAgeWidgetFormType {
  title: string;
  incidents_severity: boolean;
  tiers: TierType[];
}

export type LayoutBreakpoint = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';

export type CustomLabel = ({ node }: { node: SpaceTreeNodeType }) => ReactNode;

export interface TreeNodeProps {
  node: SpaceTreeNodeType;
  contextMenuPortalRef: MutableRefObject<HTMLDivElement | null>;
  isInFocus: boolean;
  itemIdPrefix?: string;
  isRemovable: boolean;
  isEditable: boolean;
  isDraggable?: boolean;
  editModeNodeId?: number | null;
  handleCreateSpace?: (spaceId: number) => void;
  setEditModeNodeId?: (spaceId: number | null) => void;
  customLabel?: CustomLabel;
}
