import { Button, Group, Modal, Textarea } from '@mantine/core';
import React, { useState } from 'react';

import { useResolveIncidents } from '@portals/api/organizations';
import { ModalBody, ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';
import { ReactComponent as TicketCircle } from '@portals/icons/linear/tick-circle.svg';

export interface ResolveIncidentModalProps
  extends ModalProps<{
    incidentsIds: string[];
  }> {}

export function BulkResolveIncidentsModal({
  data,
  closeMe,
}: ResolveIncidentModalProps) {
  const { incidentsIds } = data;
  const resolveIncidents = useResolveIncidents();

  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    resolveIncidents.mutate(
      {
        incident_ids: incidentsIds,
        bulk_action: {
          closed_reason: reason,
        },
      },
      {
        onSuccess: closeMe,
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      padding="xxl"
      title={
        <Group>
          <TicketCircle />
          Resolve selected incidents?
        </Group>
      }
    >
      <ModalBody>
        <Textarea
          placeholder="Add details..."
          data-testid="resolve-incidents-modal-textarea"
          maxRows={7}
          minRows={7}
          error={resolveIncidents.isError}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </ModalBody>
      <ModalFooter grow>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button
          loading={resolveIncidents.isLoading}
          data-testid="close-incidents-modal-close-button"
          onClick={handleSubmit}
        >
          Resolve Incident
        </Button>
      </ModalFooter>
    </Modal>
  );
}
