import { BackgroundImage, Button, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import findPartnerBackground from '../../../assets/img/find-partner-background.svg';

interface ConnectRouteModalFooterProps {
  onSuggestBrandClick: VoidFunction;
}

export function ConnectRouteModalFooter({
  onSuggestBrandClick,
}: ConnectRouteModalFooterProps) {
  return (
    <BackgroundImage p="xxl" radius="lg" src={findPartnerBackground}>
      <Group position="apart">
        <Stack spacing="xs" c="gray.9">
          <Text size="lg" weight={500}>
            Can't find your brand?
          </Text>
          <Text>
            Let us know which brand you'd like to see on our platform.
          </Text>
        </Stack>

        <Button
          variant="default"
          onClick={onSuggestBrandClick}
          data-testid="suggest-a-brand-button"
        >
          Suggest a brand
        </Button>
      </Group>
    </BackgroundImage>
  );
}
