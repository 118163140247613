import {
  ActionIcon,
  createStyles,
  Flex,
  Loader,
  Popover,
  PopoverProps,
  SimpleGrid,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import React, { useState } from 'react';

import { AssetIconNameType } from '@portals/api/organizations';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { suppressPropagation } from '@portals/utils';

import { ASSETS_ICONS_MAP } from './assets-icons-map';

interface AssetIconProps {
  iconName?: AssetIconNameType;
  iconSize?: number;
  wrapperSize?: number;
  onChange?: (iconName: AssetIconNameType) => void;
  popoverProps?: Partial<PopoverProps>;
  isLoading?: boolean;
}

export function AssetIcon({
  iconName,
  wrapperSize = 48,
  iconSize = 24,
  onChange,
  popoverProps = {},
  isLoading,
}: AssetIconProps) {
  const { classes } = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { hovered, ref } = useHover();

  const Icon = iconName ? ASSETS_ICONS_MAP[iconName] : ASSETS_ICONS_MAP['box'];

  const onIconSelect = (currIconName: AssetIconNameType) => {
    onChange?.(currIconName);
    setIsOpen(false);
  };

  return (
    <Popover
      opened={isOpen}
      onClose={() => setIsOpen(false)}
      withArrow
      arrowSize={20}
      offset={-15}
      classNames={{
        dropdown: classes.popoverDropdown,
      }}
      {...popoverProps}
    >
      <Popover.Target>
        <Flex
          w={wrapperSize}
          h={wrapperSize}
          align="center"
          justify="center"
          pos="relative"
          bg="#C5CEFF4D"
          ref={ref}
          className={classes.container}
        >
          {onChange && hovered ? (
            <Flex
              pos="absolute"
              className={classes.editIcon}
              onClick={() => setIsOpen(true)}
              w={44}
              h={44}
              align="center"
              justify="center"
            >
              {isLoading ? <Loader /> : <Edit width={24} height={24} />}
            </Flex>
          ) : null}

          <Icon width={iconSize} height={iconSize} />
        </Flex>
      </Popover.Target>

      <Popover.Dropdown>
        <SimpleGrid cols={5} spacing="xs">
          {Object.entries(ASSETS_ICONS_MAP).map(([currIconName, Icon]) => {
            const isSelected = currIconName === iconName;

            return (
              <ActionIcon
                key={currIconName}
                radius="md"
                size={40}
                onClick={suppressPropagation(() =>
                  onIconSelect(currIconName as AssetIconNameType)
                )}
                variant={isSelected ? 'light' : 'subtle'}
                color={isSelected ? 'blue_accent' : 'blue_gray'}
                data-testid={`asset-icon-selector-${currIconName}`}
              >
                <Icon />
              </ActionIcon>
            );
          })}
        </SimpleGrid>
      </Popover.Dropdown>
    </Popover>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
    color: theme.colors.gray[7],
  },
  editIcon: {
    top: 0,
    right: 0,
    transform: 'translate(50%, -50%)',
    background: theme.white,
    border: `1px solid ${theme.colors.gray[3]}`,
    radius: theme.radius.xl,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  popoverDropdown: {
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.xl,
    zIndex: 999,
  },
}));
