import { useMemo } from 'react';

import {
  SupportedC2cConnectorName,
  useFeatureFlags,
} from '@portals/api/organizations';
import { C2C_INTEGRATIONS, C2CIntegrationItem } from '@portals/framework';
import { useIntegrations } from '@portals/redux';

type Integrations = ReturnType<typeof useIntegrations>;
type IntegrationsWithIds = ReturnType<typeof getActiveIntegrationsWithIds>;

export type AllowedC2cThirdPartyConnectors = Extract<
  SupportedC2cConnectorName,
  'zoom' | 'mtr' | 'xio' | 'domotz'
>;

export interface OrgC2CIntegrationItem extends C2CIntegrationItem {
  href?: string;
  isComingSoon?: boolean;
  thirdPartyConnectors?: Array<AllowedC2cThirdPartyConnectors>;
}

function getActiveIntegrationsWithIds(integrations: Integrations) {
  const integrationsWithIds = Object.entries(integrations).map(
    ([id, integration]) => ({
      id,
      ...integration,
    })
  );

  return integrationsWithIds.filter(
    (integration) => integration.state === 'active'
  );
}

function filterActiveIntegrationsList(
  integrationsList: OrgC2CIntegrationItem[],
  activeIntegrations: IntegrationsWithIds
) {
  return integrationsList.filter((integration) =>
    activeIntegrations.some(
      (activeIntegration) => activeIntegration.id === integration.id
    )
  );
}

export function useIntegrationsList() {
  const featureFlags = useFeatureFlags();

  return useMemo(() => {
    const integrationList: Array<OrgC2CIntegrationItem> = [
      C2C_INTEGRATIONS.mtr,
      C2C_INTEGRATIONS.zoom,
      C2C_INTEGRATIONS.xio,
      C2C_INTEGRATIONS.q_sys,
      C2C_INTEGRATIONS.bright_sign,
      C2C_INTEGRATIONS.domotz,
      {
        ...C2C_INTEGRATIONS.appspace,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.webex,
        thirdPartyConnectors: ['domotz'],
      },
      {
        ...C2C_INTEGRATIONS.poly,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.neat,
        isComingSoon: !featureFlags?.neat,
      },
      {
        ...C2C_INTEGRATIONS.novastar,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.yale,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS['2n'],
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.honeywell,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.lenel,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.assa,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.visionline,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.avigilon,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.brivo,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.controlbyweb,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS['4suites'],
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.pti,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.salto,
        isComingSoon: true,
      },
      {
        ...C2C_INTEGRATIONS.biamp,
        thirdPartyConnectors: ['mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.sonos,
        thirdPartyConnectors: ['domotz'],
      },
      {
        ...C2C_INTEGRATIONS.viewsonic,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.yealink,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.absen,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.lg,
        thirdPartyConnectors: ['xio', 'zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.philips,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.christie,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.nec,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.samsung,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.elo,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.optoma,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.sharp,
        thirdPartyConnectors: ['xio', 'zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.epson,
        thirdPartyConnectors: ['xio', 'zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.panasonic,
        thirdPartyConnectors: ['xio', 'zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.vivitek,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.acer,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.audiocodes,
        thirdPartyConnectors: ['mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.aver,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.barco,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.clearone,
        thirdPartyConnectors: ['zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.dten,
        thirdPartyConnectors: ['zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.epos,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.hp,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.huddlecamhd,
        thirdPartyConnectors: ['zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.jabra,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.lenovo,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.logitech,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.maxhub,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.microsoft_surface,
        thirdPartyConnectors: ['mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.sennheiser,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.shure,
        thirdPartyConnectors: ['mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.smart,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.sony,
        thirdPartyConnectors: ['xio', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.vaddio,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.vdo360,
        thirdPartyConnectors: ['zoom', 'domotz'],
      },
      {
        ...C2C_INTEGRATIONS.yamaha,
        thirdPartyConnectors: ['zoom', 'mtr', 'domotz'],
      },
    ];

    return integrationList;
  }, [featureFlags?.neat]);
}

export function useActiveCloudIntegrations() {
  const integrations = useIntegrations();
  const integrationsList = useIntegrationsList();

  const activeIntegrations = useMemo(() => {
    return getActiveIntegrationsWithIds(integrations);
  }, [integrations]);

  return useMemo(
    () => filterActiveIntegrationsList(integrationsList, activeIntegrations),
    [integrationsList, activeIntegrations]
  );
}
