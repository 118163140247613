import React from 'react';

import { DeviceType } from '@portals/api/organizations';
import { RowMenuItems } from '@portals/table';
import { RowMenuWrapperType } from '@portals/types';

import { useDeviceMenuItems } from '../../../device-menu/hooks';

interface DevicesListRowMenuProps {
  device: DeviceType;
  wrapperProps: RowMenuWrapperType;
}

export function DevicesListRowMenu({
  device,
  wrapperProps,
}: DevicesListRowMenuProps) {
  const menuItems = useDeviceMenuItems(device);

  return (
    <RowMenuItems
      items={menuItems}
      wrapperProps={wrapperProps}
      closeOnItemClick={true}
    />
  );
}
