import {
  ActionIcon,
  Badge,
  createStyles,
  Group,
  LoadingOverlay,
  Menu,
  SelectProps,
  SimpleGrid,
  Stack,
  Tabs,
  TabsProps,
} from '@mantine/core';
import React from 'react';

import {
  AssetIconNameType,
  generateAssetToUpdateStructure,
  useAsset,
  useMoveAsset,
  useRemoveAsset,
  useSpace,
  useUpdateAsset,
  UseUpdateAssetParams,
  useWarrantiesOfAsset,
} from '@portals/api/organizations';
import { EntityLabelType } from '@portals/api/ui';
import { DetailsPanel } from '@portals/core';
import {
  DetailsList,
  DetailsListProps,
  EntityLabelSelect,
  TruncatedSpaceLink,
} from '@portals/framework';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { useOpenModal } from '@portals/redux';
import { toastrSuccess } from '@portals/redux/actions/toastr';
import { formatDateTime } from '@portals/utils';

import { AssetWarrantiesTab } from './asset-warranties-tab/AssetWarrantiesTab';
import { AssetIcon } from './AssetIcon';
import { AssetsCommentsSection } from './AssetsCommentsSection';
import { AddAssetModalProps, type ChangeSpaceModalProps } from '../../modals';

interface AssetInfoDetailsPanelProps {
  assetId: string;
  onClose: () => void;
}

export function AssetDetailsPanel({
  assetId,
  onClose,
}: AssetInfoDetailsPanelProps) {
  const openModal = useOpenModal();
  const removeAsset = useRemoveAsset();
  const updateAsset = useUpdateAsset();
  const moveAsset = useMoveAsset();

  const warrantiesOfAsset = useWarrantiesOfAsset(assetId);

  const asset = useAsset(assetId);
  const space = useSpace({
    spaceId: asset.data?.space.id,
    fallbackToRootSpace: true,
  });

  const { classes } = useStyles();

  const detailsList: DetailsListProps['items'] = !asset.data
    ? []
    : [
        {
          label: 'Manufacturer',
          value: asset.data?.manufacturer?.name || '',
        },
        {
          label: 'Model',
          value: asset.data?.device_model?.name || '',
        },
        {
          label: 'Space',
          value: (
            <TruncatedSpaceLink
              spaceId={space.id}
              spacePath={space.tree_path_name}
              maxNumOfNames={1}
            />
          ),
        },
        {
          label: 'Serial number',
          value: asset.data?.serial_number,
        },
        {
          label: 'Purchased at',
          value: asset.data?.purchased_at
            ? formatDateTime(asset.data?.purchased_at)
            : '',
        },
      ];

  function onUpdateIcon(iconName: AssetIconNameType) {
    if (!asset.data) return;

    const updatedAsset: UseUpdateAssetParams['asset'] = {
      ...generateAssetToUpdateStructure(asset.data),
      icon_name: iconName,
    };

    onUpdateAsset(updatedAsset);
  }

  function onUpdateEntityLabel(
    category: 'status' | 'device_type' | 'device_model' | 'manufacturer',
    entityLabel: EntityLabelType | null
  ) {
    const updatedAsset: UseUpdateAssetParams['asset'] = {
      ...generateAssetToUpdateStructure(asset.data),
      [category]: entityLabel?.id
        ? {
            id: entityLabel?.id,
          }
        : null,
    };

    onUpdateAsset(updatedAsset);
  }

  function onUpdateComments(comments: string) {
    const updatedAsset: UseUpdateAssetParams['asset'] = {
      ...generateAssetToUpdateStructure(asset.data),
      comments,
    };

    onUpdateAsset(updatedAsset);
  }

  function onMoveAsset(spaceId: number) {
    if (!asset.data?.id) return;

    moveAsset.mutate(
      {
        id: asset.data?.id,
        space_id: spaceId,
      },
      {
        onSuccess: () => {
          toastrSuccess('Asset moved successfully');
        },
      }
    );
  }

  function onUpdateAsset(updatedAsset: UseUpdateAssetParams['asset']) {
    updateAsset.mutate({
      asset: updatedAsset,
      shouldRefetch: false,
    });
  }

  function onRemoveAsset() {
    removeAsset.mutate(assetId, {
      onSuccess: onClose,
    });
  }

  return (
    <DetailsPanel enableScrollUiChanges={false}>
      <LoadingOverlay
        visible={
          asset.isLoading || updateAsset.isLoading || removeAsset.isLoading
        }
      />

      <DetailsPanel.Header onClose={onClose} spacing={0} pos="relative">
        <Menu position="left-start" withinPortal>
          <Menu.Target>
            <ActionIcon sx={{ position: 'absolute', top: 20, left: 20 }}>
              <More />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() =>
                openModal<AddAssetModalProps['data']>('AddAssetModal', {
                  initialAsset: asset.data,
                })
              }
            >
              Edit asset
            </Menu.Item>

            <Menu.Item
              onClick={() =>
                openModal<ChangeSpaceModalProps['data']>('ChangeSpaceModal', {
                  onSetSpaceId: onMoveAsset,
                  space,
                })
              }
            >
              Move asset
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={onRemoveAsset}>Delete asset</Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Stack spacing="md" align="center">
          <AssetIcon
            iconName={asset.data?.icon_name}
            wrapperSize={80}
            iconSize={38}
            onChange={onUpdateIcon}
            isLoading={updateAsset.isLoading}
          />

          <DetailsPanel.Title ta="center">
            {asset.data?.name}
          </DetailsPanel.Title>
        </Stack>
      </DetailsPanel.Header>

      <Tabs defaultValue="overview" styles={tabsStyles} keepMounted={false}>
        <Tabs.List grow>
          <Tabs.Tab value="overview" data-testid="asset-details-overview-tab">
            Overview
          </Tabs.Tab>
          <Tabs.Tab
            value="warranties"
            data-testid="asset-details-warranties-tab"
          >
            <Group spacing="xs">
              Warranties
              {warrantiesOfAsset.data?.data.length ? (
                <Badge size="xs" radius="50%" color="gray" variant="filled">
                  {warrantiesOfAsset.data?.data.length}
                </Badge>
              ) : null}
            </Group>
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="overview">
          <DetailsPanel.Body spacing="md">
            <SimpleGrid cols={2} spacing="md">
              <DetailsPanel.Section
                title="Status"
                content={
                  <EntityLabelSelect
                    category="asset_status"
                    onEntityLabelSelected={(label) =>
                      onUpdateEntityLabel('status', label)
                    }
                    placeholder="Select status"
                    value={asset.data?.status?.id}
                    variant="filled"
                    styles={selectStyles}
                    withIcon
                  />
                }
                className={classes.sectionWrapper}
              />

              <DetailsPanel.Section
                title="Type"
                content={asset.data?.device_type?.name}
                className={classes.sectionWrapper}
              />
            </SimpleGrid>

            <AssetsCommentsSection
              asset={asset.data}
              isEditable
              onChange={onUpdateComments}
            />

            <DetailsList
              title="Information"
              items={detailsList}
              groupProps={{
                className: classes.detailsListGroup,
              }}
              stackProps={{
                className: classes.sectionWrapper,
              }}
            />
          </DetailsPanel.Body>
        </Tabs.Panel>

        <Tabs.Panel value="warranties">
          <AssetWarrantiesTab assetId={assetId} />
        </Tabs.Panel>
      </Tabs>
    </DetailsPanel>
  );
}

const useStyles = createStyles((theme) => ({
  sectionWrapper: {
    backgroundColor: theme.colors.gray[1],
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
  },
  detailsListGroup: {
    gridTemplateColumns: '1fr 1.5fr',
    alignItems: 'center',
    display: 'grid',
    flexWrap: 'nowrap',
  },
}));

const tabsStyles: TabsProps['styles'] = (theme) => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  tabsList: {
    marginInline: theme.spacing.xxl,
  },
  panel: {
    height: '100%',
  },
});

const selectStyles: SelectProps['styles'] = (theme) => ({
  input: {
    paddingInline: 0,
    border: 'none',
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: 0,
  },
});
