import { createStyles, LoadingOverlay, Stack, Text } from '@mantine/core';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useEffect } from 'react';

import { DeviceTile } from './device-tiles/DeviceTile';
import { DevicesControls } from './DevicesControls';
import { OverviewDevicesEmptyFiltersResult } from './OverviewDevicesEmptyFiltersResult';
import { OverviewDevicesEmptyState } from './OverviewDevicesEmptyState';
import { useOverviewDevicesContext } from './OverviewDevicesProvider';
import { useOverviewContext } from '../overview.context';
import { SpaceDataLevelControl } from '../SpaceDataLevelControl';

export function OverviewDevices() {
  const { classes } = useStyles();

  const overview = useOverviewContext();
  const {
    allDevices,
    totalDevicesCount,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    form,
  } = useOverviewDevicesContext();

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allDevices.length + 1 : allDevices.length,
    overscan: 5,
    gap: 8,
    estimateSize: () => 64,
    getScrollElement: () => overview.scrollableRef.current,
  });

  const lastItem = rowVirtualizer.getVirtualItems().at(-1);

  useEffect(
    function fetchNextPageIfNeeded() {
      if (!lastItem) return;

      if (
        lastItem.index >= allDevices.length - 1 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    },
    [
      allDevices.length,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      lastItem,
    ]
  );

  if (!isLoading && !form.isDirty() && totalDevicesCount === 0) {
    return (
      <div className={classes.container}>
        <OverviewDevicesEmptyState />
      </div>
    );
  }

  return (
    <Stack spacing="xl" className={classes.container}>
      <LoadingOverlay visible={isLoading} />

      <Stack>
        <SpaceDataLevelControl />

        <DevicesControls />

        <Text c="gray.7">Showing {totalDevicesCount} devices</Text>
      </Stack>

      {form.isDirty() && totalDevicesCount === 0 ? (
        <OverviewDevicesEmptyFiltersResult />
      ) : (
        <div
          className={classes.virtualizerContainer}
          style={{ height: rowVirtualizer.getTotalSize() }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const isLoaderRow = virtualRow.index > allDevices.length - 1;
            const device = allDevices[virtualRow.index];

            return (
              <div
                key={virtualRow.index}
                className={classes.virtualRow}
                style={{
                  height: virtualRow.size,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {isLoaderRow && hasNextPage ? (
                  <LoadingOverlay visible />
                ) : (
                  <DeviceTile device={device} />
                )}
              </div>
            );
          })}
        </div>
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    paddingInline: theme.spacing.xxl,
  },
  virtualizerContainer: {
    position: 'relative',
    width: '100%',
  },
  virtualRow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
}));
