import { AssetType, UpdatedAssetType } from './assets.types';

export function generateAssetToUpdateStructure(
  asset: AssetType
): UpdatedAssetType {
  return {
    id: asset?.id,
    icon_name: asset?.icon_name,
    name: asset?.name,
    space_id: asset?.space?.id,
    purchased_at: asset?.purchased_at,
    serial_number: asset?.serial_number,
    comments: asset?.comments,

    manufacturer: {
      id: asset?.manufacturer?.id || null,
    },
    device_model: {
      id: asset?.device_model?.id || null,
    },
    device_type: {
      id: asset?.device_type?.id || null,
    },
    status: {
      id: asset?.status?.id || null,
    },
  };
}
