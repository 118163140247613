import { useDescope, useUser } from '@descope/react-sdk';
import {
  Box,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useEffect } from 'react';

import { ReactComponent as Mobile } from '@portals/icons/linear/mobile.svg';
import { ReactComponent as SMS } from '@portals/icons/linear/sms.svg';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { AuthenticatorAppModalProps } from './AuthenticatorAppModal';
import { EmailMFAModalProps } from './EmailMFAModal';

export function Security() {
  const { user, isUserLoading } = useUser();

  const descopeSdk = useDescope();

  const openModal = useOpenModal();

  useEffect(
    function refreshDescopeSdk() {
      descopeSdk.refresh();
    },
    [descopeSdk]
  );

  return (
    <>
      <LoadingOverlay visible={isUserLoading || !user} />

      <Paper withBorder radius="lg" p="lg">
        <Stack spacing="xl">
          <Stack>
            <Text c="gray.9">Two-factor-authentication (2FA)</Text>

            <Text c="gray.5">
              Keep your account secure by enabling 2FA via email or using a
              temporary one-time passcode (TOTP) from an authenticator app.
            </Text>
          </Stack>

          <Stack>
            <Paper
              withBorder
              radius="lg"
              p="xl"
              opacity={user?.customAttributes?.isTotpEnabled ? 0.5 : 1}
            >
              <Group position="apart">
                <Group spacing="xl">
                  <SMS />

                  <Stack spacing="xs">
                    <Text c="gray.9">Email</Text>

                    <Text fz="xs" c="gray.5">
                      Receive a one-time passcode via email each time you log
                      in.
                    </Text>
                  </Stack>
                </Group>

                <Tooltip
                  label="Only one 2FA method can be active at a time"
                  disabled={!user?.customAttributes?.isTotpEnabled}
                >
                  <Box>
                    <Switch
                      disabled={user?.customAttributes?.isTotpEnabled}
                      checked={user?.customAttributes?.isOtpEnabled}
                      onClick={suppressPropagation(() =>
                        openModal<EmailMFAModalProps['data']>('EmailMFAModal')
                      )}
                      onChange={suppressPropagation}
                    />
                  </Box>
                </Tooltip>
              </Group>
            </Paper>

            <Paper
              withBorder
              radius="lg"
              p="xl"
              opacity={user?.customAttributes?.isOtpEnabled ? 0.5 : 1}
            >
              <Group position="apart">
                <Group spacing="xl">
                  <Mobile />

                  <Stack spacing="xs">
                    <Text c="gray.9">Authenticator app (TOTP)</Text>

                    <Text fz="xs" c="gray.5">
                      Use an app to receive a temporary one-time passcode each
                      time you log in.
                    </Text>
                  </Stack>
                </Group>

                <Tooltip
                  label="Only one 2FA method can be active at a time"
                  disabled={!user?.customAttributes?.isOtpEnabled}
                >
                  <Box>
                    <Switch
                      disabled={user?.customAttributes?.isOtpEnabled}
                      checked={user?.customAttributes?.isTotpEnabled}
                      onClick={suppressPropagation(() =>
                        openModal<AuthenticatorAppModalProps['data']>(
                          'AuthenticatorAppModal'
                        )
                      )}
                      onChange={suppressPropagation}
                    />
                  </Box>
                </Tooltip>
              </Group>
            </Paper>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}
