import {
  castArray,
  forEach,
  isNil,
  isNumber,
  isUndefined,
  map,
  size,
  toString,
} from 'lodash/fp';

import {
  PaginatedFilterTypeEnum,
  PaginatedQueryFilterType,
  PaginatedQueryParamsType,
} from '@portals/types';

export function getFilterSearchParams<TData extends object>(
  filter: PaginatedQueryFilterType<TData>
) {
  let gte, lte, params;

  switch (filter?.type) {
    case PaginatedFilterTypeEnum.Date:
      gte = filter.value?.gte;
      lte = filter.value?.lte;

      if (!gte && !lte) return null;

      params = [
        {
          id: `q[${filter.id}_gteq]`,
          value: isNil(gte) ? '' : gte.toISOString(),
        },
        {
          id: `q[${filter.id}_lteq]`,
          value: isNil(lte) ? '' : lte.toISOString(),
        },
      ];

      return params;

    case PaginatedFilterTypeEnum.Number:
      gte = filter.value?.gte;
      lte = filter.value?.lte;

      if (!isNumber(gte) && !isNumber(lte)) return null;

      params = [
        {
          id: `q[${filter.id}_gteq]`,
          value: isUndefined(gte) ? '' : gte,
        },
        {
          id: `q[${filter.id}_lteq]`,
          value: isUndefined(lte) ? '' : lte,
        },
      ];

      return params;

    case PaginatedFilterTypeEnum.SingleSelect:
      if (!filter.value) return null;

      return {
        id: `q[${filter.id}_eq]`,
        value: filter.value[0],
      };

    case PaginatedFilterTypeEnum.Select:
      if (!filter.value) return null;

      if (size(filter.value) > 1) {
        return map(
          (value) => ({
            id: `q[${filter.id}_in][]`,
            value,
          }),
          filter.value
        );
      } else {
        return {
          id: `q[${filter.id}_eq]`,
          value: filter.value[0],
        };
      }

    case PaginatedFilterTypeEnum.Boolean:
      if (isNil(filter.value)) return null;

      return {
        id: `q[${filter.id}]`,
        value: filter.value,
      };

    case PaginatedFilterTypeEnum.Null:
      if (isNil(filter.value)) return null;

      return {
        id: `q[${filter.id}_null]`,
        value: filter.value,
      };

    case PaginatedFilterTypeEnum.NotEq:
      if (isNil(filter.value)) return null;

      return {
        id: `q[${filter.id}_not_eq]`,
        value: filter.value,
      };

    case PaginatedFilterTypeEnum.Eq:
      if (isNil(filter.value)) return null;

      return {
        id: `q[${filter.id}_eq]`,
        value: filter.value,
      };

    case PaginatedFilterTypeEnum.Contains:
      if (isNil(filter.value)) return null;

      return {
        id: `q[${filter.id}_i_cont]`,
        value: filter.value,
      };

    case PaginatedFilterTypeEnum.NotContains:
      if (isNil(filter.value)) return null;

      if (size(filter.value) > 1) {
        return map(
          (value) => ({
            id: `q[${filter.id}_not_in][]`,
            value,
          }),
          filter.value
        );
      } else {
        return {
          id: `q[${filter.id}_not_eq]`,
          value: filter.value,
        };
      }

    case PaginatedFilterTypeEnum.LtreeStartsWith:
      if (isNil(filter.value)) return null;

      return {
        id: `q[${filter.id}_ltree_starts_with]`,
        value: filter.value,
      };

    case PaginatedFilterTypeEnum.Text:
    default:
      return {
        id: `q[${filter.id}_i_cont]`,
        value: filter.value,
      };
  }
}

// Returns URL search params for a given table state
// Columns are used to determine filter types - text, date, number...
export function getSearchParams<TData extends object>({
  filters,
  sorting,
  pagination,
}: PaginatedQueryParamsType<TData>) {
  const searchParams: Array<{ key: string; value: string }> = [];

  // Column filters params
  forEach((filter) => {
    const filterSearchParams = castArray(getFilterSearchParams(filter));

    forEach((currFilter) => {
      if (currFilter) {
        searchParams.push({ key: currFilter.id, value: currFilter.value });
      }
    }, filterSearchParams);
  }, filters);

  // Columns sort by params
  forEach(({ id, desc }) => {
    searchParams.push({
      key: 'q[s]',
      value: `${id} ${desc ? 'desc' : 'asc'}`,
    });
  }, sorting);

  searchParams.push({ key: 'page', value: toString(pagination.page + 1) });
  searchParams.push({ key: 'per_page', value: toString(pagination.pageSize) });

  return searchParams;
}

export function buildUrlFromFilters<TData extends object>({
  url,
  filters = [],
  sorting = [],
  pagination,
}: {
  url: string;
} & PaginatedQueryParamsType<TData>) {
  const requestUrl = new URL(url);

  const tableStateSearchParams = getSearchParams<TData>({
    filters,
    sorting,
    pagination,
  });

  forEach(({ key, value }) => {
    requestUrl.searchParams.append(key, value);
  }, tableStateSearchParams);

  return requestUrl.toString();
}
