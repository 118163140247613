import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { SpaceType } from '@portals/api/organizations';

import { OverviewSettingsTabEnum } from './overview-settings-drawer/settings-drawer.types';
import { useCurrentOverviewTab } from './overview.hooks';
import { OVERVIEW_PATHS, OverviewTabType } from '../../routes';

export function useOverviewRouting() {
  const navigate = useNavigate();
  const currentOverviewTab = useCurrentOverviewTab();

  const generateOverviewTabPath = useCallback(
    (spaceId: SpaceType['id'], overviewTab?: OverviewTabType) => {
      return generatePath(`${OVERVIEW_PATHS.space}/:overviewTab?`, {
        spaceId: String(spaceId),
        overviewTab: overviewTab ?? currentOverviewTab ?? 'dashboard',
      });
    },
    [currentOverviewTab]
  );

  const generateSpaceSettingsPath = useCallback(
    (spaceId: SpaceType['id'], settingsTab: OverviewSettingsTabEnum) => {
      return generatePath(OVERVIEW_PATHS.spaceSettings, {
        spaceId: String(spaceId),
        settingsTab,
      });
    },
    []
  );

  const navigateToOverviewTab = useCallback(
    (
      params: { spaceId: SpaceType['id']; overviewTab?: OverviewTabType },
      navigationOptions?: { search?: string; replace?: boolean }
    ) => {
      const { spaceId, overviewTab } = params;

      const pathname = generateOverviewTabPath(spaceId, overviewTab);

      navigate(
        { pathname, search: navigationOptions?.search },
        { replace: navigationOptions?.replace }
      );
    },
    [generateOverviewTabPath, navigate]
  );

  const navigateToSpaceSettings = useCallback(
    (
      params: {
        spaceId: SpaceType['id'];
        settingsTab: OverviewSettingsTabEnum;
      },
      navigationOptions?: { search?: string; replace?: boolean }
    ) => {
      const { spaceId, settingsTab } = params;

      const pathname = generateSpaceSettingsPath(spaceId, settingsTab);

      navigate(
        { pathname, search: navigationOptions?.search },
        { replace: navigationOptions?.replace }
      );
    },
    [generateSpaceSettingsPath, navigate]
  );

  return {
    generateOverviewTabPath,
    generateSpaceSettingsPath,

    navigateToOverviewTab,
    navigateToSpaceSettings,
  };
}
