import {
  Box,
  createStyles,
  Group,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@mantine/core';
import GoogleMapReact, { Coords } from 'google-map-react';
import { isNumber } from 'lodash/fp';
import React, { ReactNode, useMemo, useState } from 'react';

import { DeviceStatusType, MapCoordinatesType } from '@portals/types';

import {
  DEFAULT_ZOOM,
  MAP_STYLE,
  MAX_ZOOM,
} from './device-location-widget.constants';
import {
  GoogleMapsType,
  GoogleMapType,
  MapFeature,
  SuperClusterPoint,
} from './device-location-widget.types';
import { createClusterMap, getMapBounds } from './device-location-widget.utils';
import { DeviceLocationCluster } from './DeviceLocationCluster';
import { OfflineDeviceStateTooltip } from '../../common/OfflineDeviceStateTooltip';
import { DeviceLocationType } from '../../widgets.constants';
import { WidgetColorType } from '../../widgets.types';

export interface DeviceMapConfig {
  id: string;
  type: DeviceLocationType;
  coordinates: Coords;
  name: string;
  status: DeviceStatusType | undefined;
  renderSinglePoint: () => ReactNode;
}

export interface DeviceLocationWidgetProps {
  title: string;
  showLocationName: boolean;
  color: WidgetColorType;

  stackProps?: StackProps;

  isDeviceOffline?: boolean;
  lastUpdateTimestamp?: string;

  points: Array<SuperClusterPoint>;
  base: MapCoordinatesType;
}

const MAP_OPTIONS = {
  maxZoom: MAX_ZOOM,
  styles: MAP_STYLE,
};

export function DeviceLocationWidget({
  points,
  base,
  stackProps,
  isDeviceOffline,
  lastUpdateTimestamp,
}: DeviceLocationWidgetProps) {
  const { classes, theme } = useStyles();

  const [map, setMap] = useState<GoogleMapType | null>(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [bounds, setBounds] = useState<[number, number, number, number]>([
    0, 0, 0, 0,
  ]);

  const handleMapLoaded: GoogleMapReact.Props['onGoogleApiLoaded'] = ({
    map: gMap,
    maps,
  }: {
    map: GoogleMapType;
    maps: GoogleMapsType;
  }) => {
    const latLngBounds = getMapBounds(maps, points, base);

    gMap.setCenter(latLngBounds.getCenter());
    gMap.fitBounds(latLngBounds);

    const soWe = gMap.getBounds()?.getSouthWest();
    const noEa = gMap.getBounds()?.getNorthEast();

    setMap(gMap);

    setBounds([
      soWe?.lng() || 0,
      soWe?.lat() || 0,
      noEa?.lng() || 0,
      noEa?.lat() || 0,
    ]);
  };

  const [clusters, superCluster] = useMemo(
    () => createClusterMap(bounds, points, zoom),
    [points, bounds, zoom]
  );

  const handleMapMove = (mapData: GoogleMapReact.ChangeEventValue): void => {
    const newZoom = mapData.zoom;
    const newBounds = mapData.bounds;

    setZoom(newZoom);
    setBounds([
      newBounds.nw.lng,
      newBounds.se.lat,
      newBounds.se.lng,
      newBounds.nw.lat,
    ]);
  };

  const zoomToCluster = (cluster: MapFeature) => {
    const expansionZoom =
      superCluster && isNumber(cluster.id)
        ? Math.min(superCluster.getClusterExpansionZoom(cluster.id), MAX_ZOOM)
        : DEFAULT_ZOOM;

    map?.setZoom(expansionZoom);
    map?.panTo({
      lat: cluster.geometry.coordinates[1],
      lng: cluster.geometry.coordinates[0],
    });
  };

  if (!points?.length) {
    return <DeviceLocationEmptyState stackProps={stackProps} />;
  }

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      {isDeviceOffline ? (
        <OfflineDeviceStateTooltip lastUpdateTimestamp={lastUpdateTimestamp} />
      ) : null}

      <Box
        w="100%"
        h="100%"
        sx={{
          borderRadius: theme.radius.md,
          overflow: 'hidden',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NX_GOOGLE_KEY || 'MISSING_GOOGLE_MAPS_API_KEY',
          }}
          defaultZoom={MAX_ZOOM - 2}
          center={{
            lng: points?.[0]?.geometry.coordinates[0],
            lat: points?.[0]?.geometry.coordinates[1],
          }}
          yesIWantToUseGoogleMapApiInternals
          options={MAP_OPTIONS}
          onGoogleApiLoaded={handleMapLoaded}
          onChange={handleMapMove}
        >
          {clusters.map((item) => {
            const [longitude, latitude] = item.geometry.coordinates;

            return item.properties?.cluster ? (
              <DeviceLocationCluster
                key={item.id}
                item={item}
                superCluster={superCluster}
                onClick={zoomToCluster}
                zoom={zoom}
                lat={latitude}
                lng={longitude}
              />
            ) : (
              item.properties?.['renderSinglePoint']()
            );
          })}
        </GoogleMapReact>
      </Box>
    </Stack>
  );
}

export function DeviceLocationEmptyState({
  stackProps,
}: Pick<DeviceLocationWidgetProps, 'stackProps'>) {
  const { classes } = useStyles();

  return (
    <Stack
      className={classes.container}
      p="xl"
      spacing="lg"
      pos="relative"
      h="100%"
      w="100%"
      bg="white"
      justify="center"
      {...stackProps}
    >
      <Group
        bg="gray.2"
        grow
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Tooltip
          label={<Text size="xs">Unknown location</Text>}
          px="sm"
          withinPortal={false}
          opened={true}
          withArrow
          bg="blue_gray.9"
          arrowSize={8}
        >
          <Box mt="xl" />
        </Tooltip>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.lg,
  },
}));
