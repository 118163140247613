import { useNavigate, useParams, useMatch } from 'react-router-dom';

import { OVERVIEW_SETTINGS_TABS } from './settings-drawer.constants';
import { OverviewSettingsTabEnum } from './settings-drawer.types';
import { useCurrentSpace } from '../overview.hooks';

export function useCurrentSettingsTab() {
  const navigate = useNavigate();
  const params = useParams<{ settingsTab?: OverviewSettingsTabEnum }>();
  const currentSpace = useCurrentSpace();
  const isSettings = useMatch('/settings/:settingsTab');

  const currenTab = OVERVIEW_SETTINGS_TABS[params.settingsTab];

  if (isSettings && !currenTab && currentSpace) {
    navigate(`/overview/${currentSpace.id}/settings/general`);
  }

  return currenTab;
}
