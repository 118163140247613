import { difference, getOr } from 'lodash/fp';
import { FC, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { getSystemIssues } from '@portals/redux/actions/system_issues';
import { addNotification, removeNotification } from '@portals/redux/actions/ui';
import { SystemIssuesType, TenantType } from '@portals/types';

const SYSTEM_ISSUES_UPDATE_INTERVAL = 30; // In seconds

const ISSUE_ENTITY_TYPE_TO_LINK = {
  space: (id) => `/overview/${id}/settings/general`,
  invoice: (id) =>
    id ? `/store-management/invoices/${id}` : '/store-management/invoices',
};

type IssuesMap = Record<number, SystemIssuesType>;

type SystemIssuesProps = {
  tenantType: TenantType.Partner | TenantType.Organization;
  getSystemIssues: (
    tenantType: TenantType.Partner | TenantType.Organization
  ) => void;
  issues: IssuesMap;
  addNotification: (id: number, message: string, link: string) => void;
  removeNotification: (id: number) => void;
};

const getNotificationLink = (issue: SystemIssuesType) => {
  const { type, id } = issue.entity || {};
  const link_generator = ISSUE_ENTITY_TYPE_TO_LINK[type];

  if (issue.link) {
    return issue.link;
  }

  if (link_generator) {
    return link_generator(id);
  }
};

const updateNotificationsBar = (
  issues: IssuesMap,
  prevIssues,
  addNotification,
  removeNotification
) => {
  Object.values(issues).map((issue) => {
    const link = getNotificationLink(issue);
    return addNotification(issue.id, issue.title, link);
  });

  // Remove notification for solved issues
  const removed = difference(
    Object.keys(prevIssues.current),
    Object.keys(issues)
  );
  removed.map((issueId) => removeNotification(parseInt(issueId)));

  prevIssues.current = issues;
};

const SystemIssues: FC<SystemIssuesProps> = ({
  issues,
  getSystemIssues,
  addNotification,
  removeNotification,
  tenantType,
}) => {
  const prevIssues = useRef({});

  useEffect(() => getSystemIssues(tenantType), [getSystemIssues, tenantType]);

  // Update notification bar
  useEffect(() => {
    updateNotificationsBar(
      issues,
      prevIssues,
      addNotification,
      removeNotification
    );
  }, [issues, addNotification, removeNotification]);

  // Periodically fetch issues
  useEffect(() => {
    const intervalId = setInterval(
      () => getSystemIssues(tenantType),
      SYSTEM_ISSUES_UPDATE_INTERVAL * 1000
    );
    return () => clearInterval(intervalId);
  }, [getSystemIssues, tenantType]);

  return null;
};

const mapStateToProps = (state) => ({
  issues: getOr({}, 'data.system_issues', state),
});

export default connect(mapStateToProps, {
  getSystemIssues,
  addNotification,
  removeNotification,
})(SystemIssues);
