import { Button, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { binocularsEmptyStateSrc } from '@portals/assets';

import { useOverviewDevicesContext } from './OverviewDevicesProvider';

export function OverviewDevicesEmptyFiltersResult() {
  const { classes } = useStyles();

  const { form } = useOverviewDevicesContext();

  return (
    <Stack className={classes.container}>
      <img width={250} height="auto" src={binocularsEmptyStateSrc} />

      <Stack spacing={4} fw={500}>
        <Text c="gray.8">No devices that matches these filters</Text>

        <Text c="gray.5" fz="xs">
          Try adjusting or clearing your filters to display better results
        </Text>
      </Stack>

      <Button variant="default" onClick={() => form.reset()}>
        Reset filters
      </Button>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing.xl,
    textAlign: 'center',
  },
}));
