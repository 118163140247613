import {
  Box,
  Button,
  ButtonProps,
  createStyles,
  Divider,
  Grid,
  Group,
  Indicator,
  Popover,
  Select,
  Stack,
  Switch,
  SwitchGroupProps,
  SwitchProps,
  Text,
} from '@mantine/core';
import React from 'react';

import { getDeviceStatusList } from '@portals/framework/route-modals';
// import { ReactComponent as Element3Icon } from '@portals/icons/bold/element-3.svg';
// import { ReactComponent as ListIcon } from '@portals/icons/linear/list.svg';
import { ReactComponent as Setting4Icon } from '@portals/icons/linear/setting-4.svg';

// import { DisplayTypeCard } from './DisplayTypeCard';
import { useOverviewDevicesContext } from '../OverviewDevicesProvider';

// const priorityList = getPrioritiesList();
const deviceStatusList = getDeviceStatusList();

const SORT_BY_OPTIONS = [
  { value: 'name', label: 'Name' },
  { value: 'device_model_name', label: 'Model' },
  { value: 'status', label: 'Status' },
];

const SORT_DIRECTION_OPTIONS = [
  { value: 'asc', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
];

export function DeviceFilters() {
  const { classes, theme } = useStyles();

  const overviewDevices = useOverviewDevicesContext();

  return (
    <Popover
      withinPortal
      position="left-start"
      width={470}
      radius="lg"
      shadow="xl"
    >
      <Popover.Target>
        <Indicator offset={2} disabled={!overviewDevices.form.isDirty()}>
          <Button
            variant="default"
            leftIcon={<Setting4Icon width={16} height={16} />}
          >
            Filters
          </Button>
        </Indicator>
      </Popover.Target>

      <Popover.Dropdown py="xl" px="xxl">
        <Stack spacing="xl">
          <Text fw={500} fz="md">
            Filters
          </Text>

          {/*<Group grow>*/}
          {/*  <DisplayTypeCard*/}
          {/*    icon={<ListIcon />}*/}
          {/*    label="List"*/}
          {/*    isActive={overviewDevices.displayType === 'list'}*/}
          {/*    onClick={() => overviewDevices.setDisplayType('list')}*/}
          {/*  />*/}

          {/*  <DisplayTypeCard*/}
          {/*    icon={<Element3Icon />}*/}
          {/*    label="Grid"*/}
          {/*    isActive={overviewDevices.displayType === 'grid'}*/}
          {/*    onClick={() => overviewDevices.setDisplayType('grid')}*/}
          {/*  />*/}
          {/*</Group>*/}

          <Stack>
            <Text fw={500} c="gray.5">
              Sort by
            </Text>

            <Grid columns={10}>
              <Grid.Col span={6}>
                <Select
                  placeholder="Sort by"
                  data={SORT_BY_OPTIONS}
                  {...overviewDevices.form.getInputProps('sortByField')}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  placeholder="Sort order"
                  data={SORT_DIRECTION_OPTIONS}
                  {...overviewDevices.form.getInputProps('sortByDirection')}
                />
              </Grid.Col>
            </Grid>
          </Stack>

          <div className={classes.switchesGrid}>
            {/*@todo: leave this here for when we revert the incidents switches*/}
            {/*<Switch.Group*/}
            {/*  label="Incidents"*/}
            {/*  styles={switchGroupStyles}*/}
            {/*  value={overviewDevices.form.values.incidentsPriority.map(String)}*/}
            {/*  onChange={(values) =>*/}
            {/*    overviewDevices.form.setFieldValue(*/}
            {/*      'incidentsPriority',*/}
            {/*      values.map(Number) as PriorityNumericValue[]*/}
            {/*    )*/}
            {/*  }*/}
            {/*>*/}
            {/*  <Stack>*/}
            {/*    {priorityList.map((priority) => (*/}
            {/*      <Switch*/}
            {/*        key={priority.name}*/}
            {/*        value={String(priority.id)}*/}
            {/*        labelPosition="left"*/}
            {/*        styles={switchStyles}*/}
            {/*        label={*/}
            {/*          <Group spacing="xs">*/}
            {/*            <DangerIcon*/}
            {/*              width={18}*/}
            {/*              height={18}*/}
            {/*              color={theme.fn.themeColor(priority.color)}*/}
            {/*            />*/}
            {/*            <Text>{priority.name}</Text>*/}
            {/*          </Group>*/}
            {/*        }*/}
            {/*      />*/}
            {/*    ))}*/}
            {/*  </Stack>*/}
            {/*</Switch.Group>*/}

            <Switch.Group
              label="Status"
              styles={switchGroupStyles}
              {...overviewDevices.form.getInputProps('statuses')}
            >
              <Stack>
                {deviceStatusList.map((deviceStatus) => (
                  <Switch
                    key={deviceStatus.id}
                    labelPosition="left"
                    styles={switchStyles}
                    value={deviceStatus.id}
                    label={
                      <Group spacing="xs">
                        <Box
                          className={classes.statusDot}
                          bg={theme.fn.themeColor(deviceStatus.color)}
                        />
                        <Text>{deviceStatus.displayName}</Text>
                      </Group>
                    }
                  />
                ))}
              </Stack>
            </Switch.Group>
          </div>

          <Divider color="gray.3" />

          <Button
            variant="white"
            styles={resetButtonStyles}
            onClick={() => overviewDevices.form.reset()}
          >
            Reset to default
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}

const useStyles = createStyles((theme) => ({
  switchesGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',

    // @todo: leave this here for when we revert the incidents switches
    // '>:nth-of-type(odd)': {
    //   paddingRight: theme.spacing.xxl,
    //   borderRight: `1px solid ${theme.colors.gray[1]}`,
    // },
    //
    // '>:nth-of-type(even)': {
    //   paddingLeft: theme.spacing.xxl,
    //   borderLeft: `1px solid ${theme.colors.gray[1]}`,
    // },
  },
  statusDot: {
    width: 15,
    height: 15,
    borderRadius: '50%',
  },
}));

const switchGroupStyles: SwitchGroupProps['styles'] = (theme) => ({
  label: {
    marginBottom: theme.spacing.md,
    fontWeight: 500,
    color: theme.colors.gray[5],
  },
});

const switchStyles: SwitchProps['styles'] = {
  body: {
    justifyContent: 'space-between',
  },
};

const resetButtonStyles: ButtonProps['styles'] = {
  root: {
    height: 20,
    padding: 0,
    alignSelf: 'flex-start',
  },
  label: {
    fontWeight: 500,
  },
};
