import { useCallback } from 'react';

import { usePricingPlan } from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import { CeCTrialAnnouncementModalProps } from '../desktop/modals';

export function useOpenTrialAnnouncementModal() {
  const openModal = useOpenModal();
  const pricingPlan = usePricingPlan();

  return useCallback(
    ({ onContinueWithTrial }: { onContinueWithTrial: VoidFunction }) => {
      if (pricingPlan?.name === 'brand_affiliated') {
        openModal<CeCTrialAnnouncementModalProps['data']>(
          'CeCTrialAnnouncementModal',
          { onContinueWithTrial: () => onContinueWithTrial() }
        );
      } else {
        onContinueWithTrial();
      }
    },
    [pricingPlan, openModal]
  );
}
