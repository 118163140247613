import { Button, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { dashboardSrc } from '@portals/assets';

import { useOverviewContext } from '../overview.context';

export function DashboardEmptyState() {
  const { classes } = useStyles();
  const overview = useOverviewContext();

  return (
    <Stack
      align="center"
      justify="center"
      spacing="xl"
      className={classes.container}
    >
      <img height={200} width="auto" src={dashboardSrc} alt="Empty state" />

      <Stack spacing="xs" ta="center">
        <Text color="gray.8" align="center">
          Customize Your Dashboard with Widgets
        </Text>

        <Text size="xs" color="gray.5" align="center">
          Make the Most of Your Dashboard by Adding Relevant Widgets and
          Creating a Customized Experience
        </Text>
      </Stack>

      <Button
        onClick={overview.navigateToWidgetsPanel}
        data-testid="add-widgets-button"
      >
        Add Widgets
      </Button>
    </Stack>
  );
}

const useStyles = createStyles({
  container: {
    height: '100%',
    width: 350,
    marginInline: 'auto',
  },
});
