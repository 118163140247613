import {
  Box,
  Center,
  Flex,
  FlexProps,
  Loader,
  MantineColor,
  Popover,
  Select,
  SelectProps,
  Text,
} from '@mantine/core';
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  EntityLabelType,
  useCreateEntityLabel,
  useEntityLabelsByCategory,
} from '@portals/api/ui';
import { suppressPropagation } from '@portals/utils';

import { ColorSelector } from './ColorSelector';

interface EntityLabelSelectProps
  extends Omit<SelectProps, 'data' | 'onCreate'> {
  category: string;
  onEntityLabelSelected: (entityLabel: EntityLabelType | null) => void;
  withIcon?: boolean;
}

type EntityLabelOption = { value: string; label: string; color?: string };

const PALETTE_COLORS = [
  'gray.9',
  'gray.4',
  'gray.3',
  'deep_orange.9',
  'red.4',

  'amber.7',
  'yellow.6',
  'lime.5',
  'light_green.4',
  'teal_accent.3',

  'cyan.4',
  'blue.4',
  'indigo.3',
  'deep_purple_accent.2',
  'pink_accent.2',
];

function composeOptions(entityLabels?: EntityLabelType[]): EntityLabelOption[] {
  if (!entityLabels) return [];

  return entityLabels.map(({ id, name, color }) => ({
    value: id,
    label: name,
    color,
  }));
}

export function EntityLabelSelect({
  category,
  onEntityLabelSelected,
  withIcon,
  ...selectProps
}: EntityLabelSelectProps) {
  const entityLabels = useEntityLabelsByCategory(category);
  const createEntityLabel = useCreateEntityLabel();

  const [openColorSelector, setOpenColorSelector] = useState(false);
  const [selectedValue, setSelectedValue] = useState<EntityLabelOption | null>(
    null
  );

  const selectOptions = composeOptions(entityLabels.data);

  const getValueFromSelectOptions = useCallback(
    (value: string | null) => {
      if (!value) return null;

      return (
        selectOptions.find((option) => option.value === value) || {
          value,
          label: value,
          color: '',
        }
      );
    },
    [selectOptions]
  );

  useEffect(
    function updateSelectedValue() {
      if (selectProps.value && !selectedValue) {
        setSelectedValue(getValueFromSelectOptions(selectProps.value));
      }
    },
    [selectOptions, selectProps.value, getValueFromSelectOptions, selectedValue]
  );

  function onCreateLabel(entityLabel: string) {
    if (category === 'asset_status') {
      // NOTE: since creating and asset status is a two step process, we mutate on colorSelector change

      setOpenColorSelector(true);
      setSelectedValue(getValueFromSelectOptions(entityLabel));
    } else {
      createEntityLabel.mutate(
        {
          category,
          name: entityLabel,
        },
        {
          onSuccess: (newEntityLabel) => onEntityLabelSelected(newEntityLabel),
        }
      );
    }

    return entityLabel;
  }

  function onCreateStatusLabel({
    entityLabel,
    labelColor,
  }: {
    entityLabel: string;
    labelColor: string;
  }) {
    createEntityLabel.mutate(
      {
        category,
        name: entityLabel,
        color: labelColor,
      },
      {
        onSuccess: (newEntityLabel) => onEntityLabelSelected(newEntityLabel),
      }
    );

    return entityLabel;
  }

  function getCreateLabel(name: string) {
    return (
      <div>
        + Add label <b>{name}</b>
      </div>
    );
  }

  function onChange(labelId: string | null) {
    if (!labelId) {
      onEntityLabelSelected(null);

      return;
    }

    const selectedLabel = entityLabels.data?.find(
      (label) => label.id === labelId
    );

    if (selectedLabel) {
      onEntityLabelSelected(selectedLabel);
    }
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  return (
    <Popover withinPortal opened={openColorSelector} trapFocus>
      <Popover.Target>
        <Select
          creatable
          searchable
          clearable
          withinPortal
          rightSection={
            entityLabels.isLoading ? <Loader size="xs" /> : undefined
          }
          data={selectOptions}
          clearButtonProps={{ onClick: () => setSelectedValue(null) }}
          icon={
            withIcon &&
            selectedValue?.color && (
              <Box
                bg={selectedValue?.color || 'transparent'}
                w={9}
                h={9}
                sx={{
                  borderRadius: 100,
                }}
              />
            )
          }
          itemComponent={(props) => (
            <SelectItem
              {...props}
              Icon={
                withIcon && (
                  <StatusSelectIndicator
                    color={props?.color || 'transparent'}
                  />
                )
              }
            />
          )}
          onCreate={onCreateLabel}
          getCreateLabel={getCreateLabel}
          nothingFound="Nothing found"
          onChange={(labelId) => {
            setSelectedValue(getValueFromSelectOptions(labelId));

            onChange(labelId);
          }}
          onKeyDown={onKeyDown}
          {...selectProps}
        />
      </Popover.Target>
      <Popover.Dropdown maw={300}>
        <ColorSelector
          label={`Choose color for ${selectedValue?.label}`}
          colors={PALETTE_COLORS}
          onSelect={(color) => {
            setOpenColorSelector(false);

            if (!selectedValue) return;

            setSelectedValue({
              ...selectedValue,
              color,
            });

            onCreateStatusLabel({
              entityLabel: selectedValue?.label || '',
              labelColor: color,
            });
          }}
          closeColorSelector={() => setOpenColorSelector(false)}
        />
      </Popover.Dropdown>
    </Popover>
  );
}

interface SelectItemProps extends FlexProps {
  value: string;
  label: string;
  Icon?: ReactNode;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ value, label, color, onMouseDown, Icon, ...selectItemProps }, ref) => {
    return (
      <Flex
        onMouseDown={suppressPropagation(onMouseDown)}
        ref={ref}
        align="center"
        gap="xs"
        px="md"
        py="sm"
        {...selectItemProps}
      >
        {Icon}
        <Text>{label}</Text>
      </Flex>
    );
  }
);

function StatusSelectIndicator({ color }: { color: MantineColor }) {
  return (
    <Center w={24} h={24}>
      <Box
        bg={color || 'transparent'}
        w={9}
        h={9}
        sx={{
          borderRadius: 100,
        }}
      />
    </Center>
  );
}
