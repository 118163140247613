import { Button, Group } from '@mantine/core';
import React from 'react';

import { AssetType } from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useTableInstance } from '@portals/table';

interface AssetsAdditionalActionsProps {
  onBulkRemove: (ids: string[]) => void;
}

export function AssetsAdditionalActions({
  onBulkRemove,
}: AssetsAdditionalActionsProps) {
  const asyncConfirmationCheck = useConfirmationModal();
  const { selectedFlatRows } = useTableInstance<AssetType>();

  async function onRemove() {
    const description =
      selectedFlatRows.length === 1
        ? `Are you sure you want to delete this asset?`
        : `Are you sure you want to delete ${selectedFlatRows.length} assets?`;

    const isConfirmed = await asyncConfirmationCheck({
      description,
    });

    if (isConfirmed) {
      onBulkRemove(selectedFlatRows.map((row) => row.original.id));
    }
  }

  if (selectedFlatRows.length < 1) return null;

  return (
    <Group>
      <Button
        size="xs"
        px={5}
        color="red"
        leftIcon={<Trash />}
        variant="subtle"
        styles={{
          leftIcon: {
            width: 16,
            height: 16,
          },
        }}
        onClick={onRemove}
      >
        Delete
      </Button>
    </Group>
  );
}
