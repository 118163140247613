import React from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  GettingStartedToursNamesEnum,
  useCompleteGettingStartedTour,
  useOnboardingTours,
} from '@portals/api/organizations';
import { TOUR_STEPS_IDS } from '@portals/framework';
import { ReactComponent as BoxIcon } from '@portals/icons/bulk/box-1.svg';
import { useOpenModal } from '@portals/redux';

import { TourCompletedModalProps } from '../../modals';
import { TourStepTooltip } from '../TourStepTooltip';

export function SetupSpacesTour() {
  const openModal = useOpenModal();
  const completeGettingStartedTour = useCompleteGettingStartedTour();
  const location = useLocation();
  const navigate = useNavigate();
  const onBoardingTours = useOnboardingTours();

  const searchParams = new URLSearchParams(location.search);

  const steps: Array<Step> = [
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.overviewSpaces}]`,
      title: 'Get to know the spaces navigation bar',
      content:
        'Spaces are fully customizable (e.g. office locations/departments), displayed in a heirarchical tree structure.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right-start',
    },
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.space}]`,
      title: 'Choose a space',
      content:
        'The highlighted space represents your current selection. You can easily select a different space or use the three-dots-menu to create a new one as per your preference.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'right',
    },
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.overview}]`,
      title: 'Explore Your Space Dashboard',
      content:
        'Once a space is selected from the left menu, its dashboard appears on the right. Here, you can claim devices to your chosen space, troubleshoot incidents, and monitor and manage the space effectively.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'left-start',
    },
    {
      target: `[data-tour-step-id=${TOUR_STEPS_IDS.overviewHeaderMenu}]`,
      title: 'Review setting and manage access',
      content:
        'Set the units, configuration and information parameters for your space, integrate tools and workflows for incident routing, and manage space access.',
      disableBeacon: true,
      floaterProps: {
        disableAnimation: true,
      },
      placement: 'left',
    },
  ];

  const onTransitionCallback = (data: CallBackProps) => {
    const { status, lifecycle } = data;

    if (status === 'finished' && lifecycle === 'complete') {
      if (!onBoardingTours?.getting_started?.setup_spaces_completed) {
        openModal<TourCompletedModalProps['data']>('TourCompletedModal', {
          title: 'Congratulations! You’ve just completed a tour',
          nextTourTitle: 'Claim your first device',
          nextTourDescription:
            'Quickly claim your first device, ensuring seamless integration into your system.',
          nextTourIcon: <BoxIcon />,
          onNextTourClick: () => {
            navigate(
              `/overview/?active_tour=${GettingStartedToursNamesEnum.ClaimDevice}`
            );
          },
        });
      }

      completeGettingStartedTour.mutate('setup_spaces_completed');

      searchParams.delete('active_tour');
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  };

  const onSkipTour = () => {
    searchParams.delete('active_tour');
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      callback={onTransitionCallback}
      tooltipComponent={(props) => (
        <TourStepTooltip
          tourName="Set up your spaces"
          onSkip={onSkipTour}
          {...props}
        />
      )}
      styles={{
        options: {
          arrowColor: 'black',
        },
      }}
    />
  );
}
