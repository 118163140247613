import { ActionIcon, Menu, Text } from '@mantine/core';
import React, { Fragment } from 'react';

import { DeviceType } from '@portals/api/organizations';
import { ReactComponent as MoreIcon } from '@portals/icons/linear/more.svg';
import { suppressPropagation } from '@portals/utils';

import { useDeviceMenuItems } from './hooks';

interface DeviceMenuProps {
  device: DeviceType;
}

export function DeviceMenu({ device }: DeviceMenuProps) {
  const menuItems = useDeviceMenuItems(device);

  if (menuItems.length === 0) return null;

  return (
    <Menu withinPortal position="right-start">
      <Menu.Target>
        <ActionIcon onClick={suppressPropagation()}>
          <MoreIcon />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {menuItems.map(({ Icon, Wrapper, ...menuItem }) => (
          <Fragment key={menuItem.id}>
            {menuItem.withTopDivider && <Menu.Divider />}

            <Menu.Item
              key={menuItem.id}
              color={menuItem.color ? `${menuItem.color}.4` : 'gray.9'}
              icon={<Icon />}
              onClick={suppressPropagation(menuItem.onClick)}
            >
              {Wrapper ? (
                <Wrapper>
                  <Text>{menuItem.label}</Text>
                </Wrapper>
              ) : (
                menuItem.label
              )}
            </Menu.Item>

            {menuItem.withBottomDivider && <Menu.Divider />}
          </Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
