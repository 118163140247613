import {
  Button,
  Group,
  Image,
  ImageProps,
  LoadingOverlay,
  Modal,
  Stack,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { ModalProps as MantineModalProps } from '@mantine/core/lib/Modal/Modal';
import { getOr } from 'lodash/fp';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  DeviceModelType,
  DeviceType,
  SpaceType,
  useAddDevice,
  useSpace,
} from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { DeviceAvatar, ModalProps } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import { toastrSuccess } from '@portals/redux/actions/toastr';
import { NO_DEVICE_MONITORING_LICENSES } from '@portals/utils';

import { OnSubmitFormParams } from './add-device-form/add-device-modal.types';
import { ChangeSpaceField } from './add-device-form/ChangeSpaceField';
import { ModelForm } from './add-device-form/ModelForm';
import { NoLicense } from './NoLicense';
import { useClaimFirstDeviceTourCompleted } from '../../../hooks/claim-first-device-tour-completed';
import { DeviceTroubleshootingSteps } from '../device-troubleshooting-steps/DeviceTroubleshootingStepsModal';

export interface AddDeviceModalProps
  extends ModalProps<{
    selectedModel: DeviceModelType | undefined;
    spaceId?: SpaceType['id'];
  }> {}

export function AddDeviceModal({
  closeMe,
  data: { selectedModel, spaceId },
}: AddDeviceModalProps) {
  const theme = useMantineTheme();
  const [currentSpaceId, onSetCurrentSpaceId] = useState(spaceId);

  const space = useSpace({
    spaceId: currentSpaceId,
    fallbackToRootSpace: true,
  });
  const addDevice = useAddDevice();
  const openRouteModal = useOpenRouteModal();
  const isNoLicense = useSelector(
    getOr(false, ['data', 'system_issues', NO_DEVICE_MONITORING_LICENSES])
  );
  const claimFirstDeviceTourCompleted =
    useClaimFirstDeviceTourCompleted(closeMe);

  const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);
  const [showClaimDeviceSuccessfulModal, setShowClaimDeviceSuccessfulModal] =
    useState(false);
  const [showTroubleshootingStepsModal, setShowTroubleshootingStepsModal] =
    useState<string | null>(null);

  const onSuccess = async (device: DeviceType) => {
    toastrSuccess(
      'Successfully added device',
      `${device.name || device.partner.model}`
    );

    await claimFirstDeviceTourCompleted();

    setSelectedDevice(device);
    setShowClaimDeviceSuccessfulModal(true);
  };

  const onSubmit = (params: OnSubmitFormParams) => {
    const { name, model, mac, sn, cloud_id, zoom_room, c2c_id } = params;

    addDevice.mutate(
      {
        space_id: currentSpaceId,
        name: name?.trim(),
        device_model_id: model,
        mac,
        sn,
        cloud_id,
        zoom_room,
        c2c_id,
      },
      {
        onSuccess,
        onError: () => setShowTroubleshootingStepsModal(model),
      }
    );
  };

  if (isNoLicense) {
    return (
      <Modal
        opened
        onClose={closeMe}
        title="Add device to space"
        radius="lg"
        styles={modalStyles}
      >
        {addDevice.isLoading ? <LoadingOverlay visible /> : null}

        <NoLicense closeMe={closeMe} />
      </Modal>
    );
  }

  if (showClaimDeviceSuccessfulModal && selectedDevice) {
    return (
      <ModalCenteredMediaLayout
        radius="lg"
        onClose={closeMe}
        opened
        title="Device claimed successfully"
        media={
          <Image
            withPlaceholder
            src={selectedDevice?.image_url}
            width={96}
            styles={imageStyles}
          />
        }
        footer={
          <Group position="center" grow>
            <Button
              variant="default"
              onClick={() => setShowClaimDeviceSuccessfulModal(false)}
            >
              Claim another device
            </Button>

            <Button
              data-testid="show-claimed-device-button"
              onClick={() => {
                openRouteModal({
                  modalId: 'device',
                  pathParams: [selectedDevice.id],
                });

                closeMe();
              }}
            >
              Show device
            </Button>
          </Group>
        }
      >
        <Stack align="center" spacing="xl">
          <Text align="center">
            Your device has been successfully claimed. You can now enjoy all
            features and benefits of our platform with this device
          </Text>

          {selectedDevice.has_token_licenses ? (
            <Group spacing={2}>
              <UnstyledButton
                onClick={() => {
                  openRouteModal({
                    modalId: 'device',
                    pathParams: [
                      selectedDevice.id,
                      'licenses?redeem-license-code=true',
                    ],
                  });

                  closeMe();
                }}
              >
                <Text underline color="blue_gray.9" fw={500}>
                  Activate a license with a code for this device
                </Text>
              </UnstyledButton>
              <Tooltip
                w={300}
                multiline
                label="This device has additional features which can be unlocked with a device license. If you have a code for such a license, follow this link to redeem it."
              >
                <QuestionCircle
                  width={18}
                  height={18}
                  color={theme.colors.gray[4]}
                />
              </Tooltip>
            </Group>
          ) : null}
        </Stack>
      </ModalCenteredMediaLayout>
    );
  }

  const deviceModel = showTroubleshootingStepsModal && selectedModel;

  if (!selectedModel) {
    return null;
  }

  return (
    <>
      {showTroubleshootingStepsModal && deviceModel ? (
        <DeviceTroubleshootingSteps
          name="DeviceTroubleshootingSteps"
          data={{
            deviceModel,
          }}
          closeMe={() => setShowTroubleshootingStepsModal(null)}
        />
      ) : null}

      <Modal
        opened
        onClose={closeMe}
        title="Add new device"
        styles={modalStyles}
        sx={{ display: showTroubleshootingStepsModal ? 'none' : 'unset' }}
      >
        <Stack spacing="lg">
          <Stack px="xxl">
            <Stack spacing={4} align="center">
              <DeviceAvatar
                src={selectedModel.image_url}
                icon={selectedModel.user_settings?.icon}
                size={97}
                radius="lg"
              />
              <Text size="lg" color="gray.5" weight={500}>
                {selectedModel.partner?.display_name}
              </Text>
              <Text size={24} color="blue_gray.9">
                {selectedModel.model}
              </Text>
            </Stack>

            <Text size="md" color="gray.6" align="center">
              Please fill the details below in order to claim this device.
            </Text>

            <ChangeSpaceField
              space={space}
              onSetSpaceId={onSetCurrentSpaceId}
            />
          </Stack>

          <ModelForm
            model={selectedModel}
            handleSubmit={onSubmit}
            handleClose={closeMe}
            onTroubleshoot={() =>
              setShowTroubleshootingStepsModal(selectedModel.id)
            }
          />
        </Stack>
      </Modal>
    </>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    padding: `${theme.spacing.xl} ${theme.spacing.xxl}`,
  },

  body: {
    padding: 0,
  },
});

const imageStyles: ImageProps['styles'] = () => ({
  placeholder: {
    width: 96,
    height: 96,
  },
});
