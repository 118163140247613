import {
  createStyles,
  Menu,
  MenuDropdownProps,
  MenuProps,
  MenuTargetProps,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

const SNOOZE_OPTIONS = [
  {
    value: 30 * 60_000,
    label: '30 minutes',
  },
  {
    value: 60 * 60_000,
    label: '1 Hour',
  },
  {
    value: 60 * 24 * 60_000,
    label: '1 Day',
  },
  {
    value: 60 * 24 * 7 * 60_000,
    label: '7 Days',
  },
  {
    value: 60 * 24 * 30 * 60_000,
    label: '30 Days',
  },
];

interface IncidentSnoozeDropdownProps extends MenuProps {
  target: ReactNode;
  onOptionClick: (value: number | null) => void;
  isIncidentSnoozed?: boolean;
  targetProps?: MenuTargetProps;
  dropdownProps?: MenuDropdownProps;
}

export function IncidentSnoozeDropdown({
  onOptionClick,
  target,
  isIncidentSnoozed,
  targetProps,
  dropdownProps,
  ...hoverCardProps
}: IncidentSnoozeDropdownProps) {
  const { classes } = useStyles();

  return (
    <Menu shadow="sm" {...hoverCardProps}>
      <Menu.Target {...targetProps}>{target}</Menu.Target>
      {!isIncidentSnoozed && (
        <Menu.Dropdown p={4} {...dropdownProps}>
          {SNOOZE_OPTIONS.map(({ value, label }) => (
            <Text
              size="sm"
              className={classes.dropdownItem}
              key={label}
              onClick={() => {
                onOptionClick(value);
              }}
              data-testid={`snooze-dropdown-option-${label}`}
            >
              {label}
            </Text>
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  dropdownItem: {
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.gray[9],
    width: '100%',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));
