import { get, toString } from 'lodash/fp';
import { useMemo } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';

import {
  DeviceType,
  SpaceTreeNodeType,
  SpaceType,
  useMoveDevice,
} from '@portals/api/organizations';
import { useConfirmationModal } from '@portals/framework';

import { StatusType } from '../desktop/components/DeviceCard/DeviceStatusIcons';

export const useDeviceState = (device: DeviceType) =>
  useMemo(() => {
    const status = get('state.status', device);

    if (!status || status === 'offline') {
      return 'unknown';
    }

    if (status === 'error' || device.incidents[0] > 0) {
      return 'error';
    }

    if (status === 'unavailable') {
      return 'warning';
    }

    if (device.incidents[1] > 0 || device.incidents[2] > 0) {
      return 'warning';
    }

    return 'success';
  }, [device]);

export const useDeviceDrag = (device: DeviceType) => {
  const asyncConfirmationCheck = useConfirmationModal();
  const { mutate: moveDevice, isLoading } = useMoveDevice();

  const [{ isDragging, getHandlerId }, dragRef, preview] = useDrag(
    () => ({
      type: 'device',
      item: { device },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        getHandlerId: monitor.getHandlerId(),
      }),
      end: async (item, monitor) => {
        const space = monitor.getDropResult() as SpaceType;

        if (space) {
          const isConfirmed = await asyncConfirmationCheck({
            title: `Move device to ${space.name}`,
            description: `Are you sure you want to move "${device.name}" to "${space.name}"?`,
            confirmationLabel: 'Move Device',
            cancelLabel: 'Cancel',
          });

          if (isConfirmed) {
            moveDevice({
              device_id: device.id,
              space_id: space.id,
            });
          }
        }
      },
    }),
    [device, asyncConfirmationCheck, moveDevice]
  );

  return { dragRef, isDragging, preview, getHandlerId, isLoading };
};

export const useDeviceDragLayer = () => {
  return useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    initialCursorOffset: monitor.getSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
};

function isDeviceUnderSameCustomer({
  device,
  node,
}: {
  device: DeviceType;
  node: SpaceTreeNodeType;
}) {
  return device.root_customer_space_id === node.root_customer_space_id;
}

export const useDeviceDrop = ({
  node,
  space,
  isDraggable,
  canEdit,
  canView,
}: {
  node: SpaceTreeNodeType;
  space: SpaceType;
  isDraggable: boolean;
  canEdit: (node: SpaceTreeNodeType) => boolean;
  canView: (node: SpaceTreeNodeType) => boolean;
}) => {
  const [{ deviceHover, deviceDroppable, isOver }, dropRef] = useDrop<
    {
      type: string;
      device: DeviceType;
    },
    any,
    any
  >(() => ({
    accept: 'device',
    drop: () => (canView(node) ? space : undefined),
    canDrop: ({ device }) =>
      isDraggable &&
      isDeviceUnderSameCustomer({
        device,
        node,
      }) &&
      toString(device?.space_id) !== toString(space?.id) &&
      canEdit(node),
    collect: (monitor) => ({
      deviceHover:
        monitor.isOver({ shallow: true }) && monitor.canDrop() && canView(node),
      deviceDroppable: monitor.canDrop() && canEdit(node),
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  return { deviceHover, deviceDroppable, dropRef, isOver };
};

export const useDeviceStatusIcons = (device: DeviceType) => {
  return useMemo(() => {
    const status = [];

    if (device.snoozed_until) {
      status.push(StatusType.Snoozed);
    }

    if (device.parent) {
      status.push(StatusType.Child);
    }

    return status;
  }, [device]);
};
