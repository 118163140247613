import {
  createStyles,
  Group,
  GroupProps,
  Stack,
  StackProps,
  Text,
} from '@mantine/core';
import React, { ReactNode } from 'react';

interface IncidentDetailsPanelSectionProps extends Omit<StackProps, 'title'> {
  title: string;
  actionIcon?: ReactNode;
  titleGroupProps?: GroupProps;
}

export function IncidentDetailsPanelSection({
  children,
  title,
  actionIcon,
  titleGroupProps,
  ...restStackProps
}: IncidentDetailsPanelSectionProps) {
  const { classes } = useStyles();

  return (
    <Stack
      c="gray.9"
      bg="gray.1"
      p="md"
      spacing="xs"
      className={classes.container}
      {...restStackProps}
    >
      <Group position="apart" noWrap {...titleGroupProps}>
        <IncidentsDetailsPanelSectionTitle>
          {title}
        </IncidentsDetailsPanelSectionTitle>

        {actionIcon}
      </Group>

      {typeof children === 'string' ? (
        <Text size="sm">{children}</Text>
      ) : (
        children
      )}
    </Stack>
  );
}

export function IncidentsDetailsPanelSectionTitle({
  children,
}: {
  children: string;
}) {
  return (
    <Text size="md" weight={500}>
      {children}
    </Text>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.spacing.md,
  },
}));
