import { MantineThemeOverride, rem } from '@mantine/core';

import { mantineComponentsOverride } from './mantine-components-override';
import { COLORS } from './theme-colors';
import { THEME_OTHER } from './theme-other';

export const THEME: MantineThemeOverride = {
  globalStyles: (theme) => ({
    body: {
      fontFamily: '"Inter", sans-serif',
      color: theme.colors.gray[9],
    },
    label: {
      marginBottom: 0,
    },
  }),
  components: mantineComponentsOverride,
  primaryColor: 'gray',
  primaryShade: 9,
  other: THEME_OTHER,
  fontFamily: '"Inter", sans-serif',
  colors: COLORS,
  white: '#FFFFFF',
  black: '#000000',
  spacing: {
    xs: rem(8),
    xxl: rem(32),
  },
  lineHeight: 1.35,
  headings: {
    sizes: {
      h1: { fontSize: rem(36), lineHeight: 1.35 },
      h2: { fontSize: rem(32), lineHeight: 1.35 },
      h3: { fontSize: rem(28), lineHeight: 1.35 },
      h4: { fontSize: rem(24), lineHeight: 1.35 },
      h5: { fontSize: rem(20), lineHeight: 1.35 },
      h6: { fontSize: rem(18), lineHeight: 1.35 },
    },
  },
};
