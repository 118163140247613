import { BackgroundImage, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { SearchInput } from '@portals/core';

import headerBackgroundImageSrc from './header-bg-image.svg';

interface ConnectRouteModalHeaderProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  isStale: boolean;
}

export function ConnectRouteModalHeader({
  searchTerm,
  setSearchTerm,
  isStale,
}: ConnectRouteModalHeaderProps) {
  const { classes } = useStyles();

  return (
    <BackgroundImage src={headerBackgroundImageSrc} className={classes.header}>
      <Stack spacing="xl" pt={40}>
        <Text className={classes.title} data-testid="connect-modal-title">
          Unlock Endless Possibilities
        </Text>

        <Text size="md" weight={500} color="gray.9">
          Discover a world of products and services from leading brands,
          personalized just for you.
        </Text>
      </Stack>

      <SearchInput
        radius="md"
        size="md"
        mt="xl"
        mx="auto"
        maw={440}
        w="100%"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClear={() => setSearchTerm('')}
        loading={isStale}
      />
    </BackgroundImage>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 1,
    position: 'sticky',
    top: -132,
    textAlign: 'center',
    paddingBottom: 40,
    paddingInline: theme.spacing.md,

    [theme.fn.smallerThan('md')]: {
      position: 'static',
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 600,
    color: theme.colors.gray[9],
  },
}));
