import {
  Button,
  ClassNames,
  createStyles,
  HoverCard,
  MantineNumberSize,
  Selectors,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import React from 'react';

import { DeviceType, useUnsnoozeDevice } from '@portals/api/organizations';
import { ReactComponent as SnoozeIcon } from '@portals/icons/linear/snooze.svg';
import { formatDateTime, suppressPropagation } from '@portals/utils';

interface DeviceSnoozeIndicatorProps {
  device: DeviceType;
  size?: MantineNumberSize;
  classNames?: ClassNames<Selectors<typeof useStyles>>;
}

export function DeviceSnoozeIndicator({
  device,
  size = 'lg',
  classNames,
}: DeviceSnoozeIndicatorProps) {
  const { classes, cx } = useStyles();

  const unsnoozeDevice = useUnsnoozeDevice(device.id);

  if (!device.snoozed_until) return null;

  return (
    <HoverCard withinPortal>
      <HoverCard.Target>
        <ThemeIcon
          variant="light"
          size={size}
          className={cx(classes.target, classNames?.target)}
        >
          <SnoozeIcon width={16} height={16} />
        </ThemeIcon>
      </HoverCard.Target>

      <HoverCard.Dropdown className={classes.dropdown}>
        <Stack>
          <Text c="blue_gray.8" ta="center">
            <Text mb={4}>Incident tracking is off until</Text>

            <Text fw={600}>{formatDateTime(device.snoozed_until)}</Text>
          </Text>

          <Button
            variant="default"
            loading={unsnoozeDevice.isLoading}
            onClick={suppressPropagation(() => unsnoozeDevice.mutate())}
          >
            Click to turn it on
          </Button>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}

const useStyles = createStyles((theme) => ({
  target: {
    borderRadius: '50%',
    color: theme.colors.gray[9],
    backgroundColor: theme.colors.gray[2],
  },
  dropdown: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.lg,
    borderRadius: theme.radius.lg,
    boxShadow: theme.shadows.lg,
  },
}));
