import { Box, Group } from '@mantine/core';
import React from 'react';

import { EditDashboardButton } from './EditDashboardButton';
import { useOverviewContext } from '../overview.context';
import { SpaceDataLevelControl } from '../SpaceDataLevelControl';

export function DashboardControls() {
  const overview = useOverviewContext();

  if (overview.isConfigMode) {
    return null;
  }

  return (
    <Group px="xxl" mb="xl" position="apart">
      <Box sx={{ flexGrow: 1 }}>
        <SpaceDataLevelControl />
      </Box>

      <EditDashboardButton />
    </Group>
  );
}
