import { identity, isEqual, reduce } from 'lodash/fp';
import { useMemo, useRef } from 'react';
import { useLocalStorage, useUpdateEffect } from 'react-use';

import { buildUrl } from '@portals/api';
import { TableState, UsePaginatedTable } from '@portals/types';
import { buildUrlFromTableState } from '@portals/utils';

import {
  useCreateTableInstance,
  useTableStateWithUrlSync,
} from '../table.hooks';
import { getDefaultHiddenColumns } from '../table.utils';

export function usePaginatedTable<TData extends object>({
  defaultSortBy = [],
  defaultFilters = [],
  isUrlSyncEnabled = true,
  mapDataFn = identity,
  readOnly,
  columns,
  expandRow,
  rowStyle,
  name,
  onSelected,
  dataHook,
  dataHookUrl,
  dataHookQueryKey,
  pageSize = 20,
  exportParams,
}: UsePaginatedTable<TData>) {
  const [localStoredHiddenColumns, setLocalStoredHiddenColumns] =
    useLocalStorage(`${name}.hiddenColumns`, getDefaultHiddenColumns(columns));
  const localDefaultFilters = useRef(defaultFilters);

  // `searchParams` are a "mirror" of react-table's instance state
  const [searchParams, setSearchParams] = useTableStateWithUrlSync({
    sortBy: defaultSortBy,
    filters: defaultFilters,
    hiddenColumns: localStoredHiddenColumns,
    isUrlSyncEnabled,
    columns,
    pageSize,
  });

  useUpdateEffect(
    function onDefaultFiltersUpdate() {
      if (!isEqual(localDefaultFilters.current, defaultFilters)) {
        setSearchParams((curr) => ({
          ...curr,
          filters: defaultFilters,
        }));
      }
    },
    [defaultFilters]
  );

  // `hiddenColumns` are not used in query search params.
  // Creating a subset of `searchParams`, which is then passed to `dataHook`
  // When `queryParams` is updated, `dataHook` re-fetches data based on updated filters state
  const queryParams = useMemo<
    Pick<TableState<TData>, 'sortBy' | 'filters' | 'pageIndex' | 'pageSize'>
  >(
    () => ({
      sortBy: searchParams.sortBy,
      filters: searchParams.filters,
      pageSize: searchParams.pageSize,
      pageIndex: searchParams.pageIndex,
    }),
    [
      searchParams.filters,
      searchParams.pageIndex,
      searchParams.pageSize,
      searchParams.sortBy,
    ]
  );

  // `dataHook` is provided via props, should be based on `usePaginatedTableApiQuery`
  const query = dataHook(queryParams, columns, dataHookUrl, dataHookQueryKey);
  const csvUrl = exportParams?.remoteUrl
    ? buildUrlFromTableState({
        url: buildUrl(exportParams.remoteUrl),
        columns,
        tableState: queryParams,
      })
    : '';

  // Iterating over returned data from `query` with passed `mapDataFn`
  const tableData = useMemo(
    () =>
      reduce(
        (acc: Array<TData>, pageData) => {
          const rows = mapDataFn(pageData);

          return acc.concat(rows);
        },
        [],
        query?.data?.data
      ),
    [mapDataFn, query?.data]
  );

  const tableInstance = useCreateTableInstance<TData>(tableData, columns, {
    expandRow,
    rowStyle,
    searchParams,
    onSelected,
    isServerFiltering: true,
    totalCount: query?.data?.page_info?.total_count || 0,
    readOnly,
  });

  const { sortBy, filters, hiddenColumns, pageIndex } =
    tableInstance?.state || {};

  useUpdateEffect(
    function updateHiddenColumns() {
      setSearchParams((curr) => ({
        ...curr,
        hiddenColumns,
      }));

      setLocalStoredHiddenColumns(hiddenColumns);
    },
    [hiddenColumns]
  );

  useUpdateEffect(
    function updateSearchParamsOnFiltersChange() {
      let isChangedSortBy = false;
      let isChangedFilters = false;
      let isChangedPage = false;

      // If defaultFilters changed, need to reset the table state entirely
      const isChangedDefaultFilters = !isEqual(
        localDefaultFilters.current,
        defaultFilters
      );

      if (isChangedDefaultFilters) localDefaultFilters.current = defaultFilters;
      if (!isEqual(sortBy, queryParams.sortBy)) isChangedSortBy = true;
      if (!isEqual(filters, queryParams.filters) && !isChangedDefaultFilters)
        isChangedFilters = true;
      if (pageIndex !== queryParams.pageIndex) isChangedPage = true;

      if (isChangedFilters) {
        // Reset current page if filters changed
        tableInstance.gotoPage(0);
        setSearchParams((curr) => ({ ...curr, sortBy, filters, pageIndex: 0 }));
      } else if (isChangedPage || isChangedSortBy) {
        // No need to reset page if filters didn't change
        setSearchParams((curr) => ({ ...curr, sortBy, pageIndex }));
      }
    },
    [
      queryParams,

      searchParams.sortBy,
      sortBy,

      searchParams.filters,
      filters,

      searchParams.pageIndex,
      pageIndex,
    ]
  );

  return {
    query,
    tableInstance,
    csvUrl,
  };
}
