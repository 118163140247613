import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { DEVICES_API_URL, getDeviceApiUrl } from './devices.constants';
import { ServerError, ServerSuccess } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';
import { globalQueryKeys } from '../global-query-keys';
import { IncidentType } from '../incidents/incidents.types';
import { spacesQueryKeys } from '../spaces/spaces.constants';

function getDeviceIncidentsApiUrl(deviceId: string) {
  return `${getDeviceApiUrl(deviceId)}/incidents`;
}

function getDeviceIncidentUrl(deviceId: string, incidentId: string) {
  return `${getDeviceIncidentsApiUrl(deviceId)}/${incidentId}`;
}

interface UseUpdateIncidentRequestPayload {
  deviceId: string;
  incidentId: string;
  incident: Partial<
    Pick<IncidentType, 'title' | 'description' | 'priority' | 'assignee'>
  >;
}

export function useUpdateIncident() {
  const queryClient = useQueryClient();
  const method = 'PUT';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  return useMutation<
    IncidentType,
    ServerError,
    UseUpdateIncidentRequestPayload
  >({
    mutationFn: ({ deviceId, incidentId, incident }) =>
      fetchApiRequest(`${url}/${getDeviceIncidentUrl(deviceId, incidentId)}`, {
        ...options,
        body: JSON.stringify(incident),
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(spacesQueryKeys.base);

      const didUpdatePriorityOnly =
        Object.keys(variables.incident).length === 1 &&
        variables.incident.priority;

      toastrSuccess(
        didUpdatePriorityOnly
          ? 'Priority changed successfully'
          : 'Updated incident successfully'
      );
    },
    onError: ({ error }: any) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useUpdateIncident',
      baseUrl: `${DEVICES_API_URL}/:id/incidents/:id`,
      method,
    },
  });
}

interface UseUpdateIncidentAssigneeRequestPayload {
  deviceId: string;
  incidentId: string;
  assignee_id: string | null;
}

export function useUpdateIncidentAssignee() {
  const queryClient = useQueryClient();
  const method = 'PUT';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  return useMutation<
    IncidentType,
    ServerError,
    UseUpdateIncidentAssigneeRequestPayload
  >({
    mutationFn: ({ deviceId, incidentId, assignee_id }) =>
      fetchApiRequest(`${url}/${getDeviceIncidentUrl(deviceId, incidentId)}`, {
        ...options,
        body: JSON.stringify({ assignee_id }),
      }),
    onSuccess: ({ assignee }) => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);
      queryClient.invalidateQueries(spacesQueryKeys.base);

      toastrSuccess(
        assignee ? 'Assignee set successfully' : 'Unassigned successfully'
      );
    },
    onError: ({ error }: any) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useUpdateIncidentAssignee',
      baseUrl: `${DEVICES_API_URL}/:id/incidents/:id`,
      method,
    },
  });
}

interface UseCreateIncidentRequestPayload {
  deviceId: string;
  incident: Pick<IncidentType, 'title' | 'description' | 'priority'>;
}

export function useCreateIncident() {
  const queryClient = useQueryClient();
  const method = 'POST';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  return useMutation<
    IncidentType,
    ServerError,
    UseCreateIncidentRequestPayload
  >({
    mutationFn: ({ deviceId, incident }) =>
      fetchApiRequest(`${url}/${getDeviceIncidentsApiUrl(deviceId)}`, {
        ...options,
        body: JSON.stringify(incident),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2000);

      toastrSuccess('Created incident successfully');
    },
    onError: ({ error }) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useCreateIncident',
      baseUrl: `${DEVICES_API_URL}/:id/incidents`,
      method,
    },
  });
}

interface SnoozeIncidentRequestPayload {
  incident: IncidentType;
  snoozed_until: number | null;
}

export function useSnoozeIncident() {
  const queryClient = useQueryClient();
  const method = 'PUT';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  return useMutation<ServerSuccess, ServerError, SnoozeIncidentRequestPayload>({
    mutationFn: async ({ incident, snoozed_until }) =>
      fetchApiRequest(
        `${url}/${getDeviceIncidentUrl(incident.device_id, incident.id)}`,
        {
          ...options,
          body: JSON.stringify({
            snoozed_until: snoozed_until
              ? new Date(Date.now() + snoozed_until)
              : null,
          }),
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(globalQueryKeys.incidents);
      queryClient.invalidateQueries(globalQueryKeys.devices);

      toastrSuccess('Incident snoozed successfully');

      setTimeout(() => {
        queryClient.invalidateQueries(spacesQueryKeys.base);
      }, 2000);
    },
    onError: ({ error }) => {
      toastrError(error);
    },
    meta: {
      mutationName: 'useSnoozeIncident',
      baseUrl: `${DEVICES_API_URL}/:id/incidents/:id`,
      method,
    },
  });
}

interface ResolveIncidentRequestPayload {
  incident: IncidentType;
  resolve_reason: string;
}

export function useResolveIncident() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const method = 'DELETE';

  const { url, options } = useRequestOptions({
    url: '',
    method,
  });

  return useMutation<ServerSuccess, ServerError, ResolveIncidentRequestPayload>(
    {
      mutationFn: async ({ incident, resolve_reason }) =>
        fetchApiRequest(
          `${url}/${getDeviceIncidentUrl(incident.device_id, incident.id)}`,
          {
            ...options,
            body: JSON.stringify({ closed_reason: resolve_reason }),
          }
        ),
      onSuccess: () => {
        queryClient.invalidateQueries(globalQueryKeys.incidents);
        queryClient.invalidateQueries(globalQueryKeys.devices);

        dispatch(toastrSuccess('Incident resolved successfully'));

        setTimeout(() => {
          queryClient.invalidateQueries(spacesQueryKeys.base);
        }, 2000);
      },
      onError: ({ error }) => {
        dispatch(toastrError(error));
      },
      meta: {
        mutationName: 'useResolveIncident',
        baseUrl: `${DEVICES_API_URL}/:id/incidents/:id`,
        method,
      },
    }
  );
}
