import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toastrError } from '@portals/redux/actions/toastr';

import {
  ENTITY_LABELS_API_URL,
  entityLabelsQueryKeys,
} from './entity-labels.constants';
import { EntityLabelType } from './entity-labels.types';
import { useApiQuery } from '../../hooks';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

export function useEntityLabelsByCategory(category: string) {
  return useApiQuery<EntityLabelType[]>(
    `${ENTITY_LABELS_API_URL}?category=${category}`,
    entityLabelsQueryKeys.byCategory(category),
    {
      enabled: !!category,
    }
  );
}

interface CreateEntityLabelMutationParams {
  name: string;
  category: string;
}

export function useCreateEntityLabel() {
  const queryClient = useQueryClient();
  const { url, options } = useRequestOptions({
    url: ENTITY_LABELS_API_URL,
    method: 'POST',
  });

  return useMutation<
    EntityLabelType,
    ServerError,
    CreateEntityLabelMutationParams
  >({
    mutationFn: (entityLabel) =>
      fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(entityLabel),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(entityLabelsQueryKeys.base);
    },
    onError: (error) =>
      toastrError(error?.error || 'Failed to create a label. Please try again'),
    meta: {
      mutationName: 'useCreateEntityLabel',
      baseUrl: ENTITY_LABELS_API_URL,
      method: 'PUT',
    },
  });
}
