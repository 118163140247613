import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  MantineSize,
  MantineThemeColors,
  Menu,
  MenuProps,
  Text,
  Tooltip,
} from '@mantine/core';
import { map } from 'lodash/fp';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

import { ComponentRendererType, RowMenuWrapperType } from '@portals/types';

import RowMenuToggle from './RowMenuToggle';

export interface RowMenuItem {
  id: string;
  label: ReactNode;
  Icon?: ComponentRendererType;
  color?: keyof Pick<MantineThemeColors, 'red' | 'gray'>;
  onClick?: () => void;
  disabled?: boolean;
  withTopDivider?: boolean;
  withBottomDivider?: boolean;
  Wrapper?: ({ children }: PropsWithChildren<any>) => ReactElement;
}

export interface RowMenuItemsProps {
  items: Array<RowMenuItem>;
  labelSize?: MantineSize;
  isLoading?: boolean;
  wrapperProps: RowMenuWrapperType;
  menuProps?: Omit<MenuProps, 'children'>;
  closeOnItemClick?: boolean;
}

const ICON_SIZE = 18 as const;

function DefaultWrapper({ children }) {
  return <Group sx={{ height: '100%' }}>{children}</Group>;
}

function RowMenuItems({
  items,
  labelSize = 'xs',
  isLoading,
  wrapperProps,
  menuProps = {},
  closeOnItemClick = false,
}: RowMenuItemsProps) {
  const { classes } = useStyles();
  const { isHovered, isMenuOpen, setIsMenuOpen } = wrapperProps;

  if (!isHovered && !isMenuOpen) return null;

  if (isLoading) return <LoadingOverlay visible />;

  return (
    <Box
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      className={classes.container}
    >
      <Menu
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
        closeOnItemClick={closeOnItemClick}
        withinPortal={true}
        position="bottom-end"
        {...menuProps}
      >
        <Menu.Target>
          <Tooltip label="Actions">
            <div>
              <RowMenuToggle isOpen={isMenuOpen} />
            </div>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          {map(
            ({
              id,
              Icon,
              color,
              label,
              onClick,
              disabled,
              Wrapper = DefaultWrapper,
              withTopDivider,
              withBottomDivider,
            }) => (
              <React.Fragment key={id}>
                {withTopDivider && <Menu.Divider />}

                <Menu.Item
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    }
                  }}
                  disabled={disabled}
                  color={color ? `${color}.4` : 'gray.9'}
                  data-testid={`row-menu-item-${id}`}
                >
                  <Wrapper>
                    <Group spacing="sm">
                      {Icon ? (
                        <Icon width={ICON_SIZE} height={ICON_SIZE} />
                      ) : null}

                      {typeof label === 'string' ? (
                        <Text size={labelSize}>{label}</Text>
                      ) : (
                        label
                      )}
                    </Group>
                  </Wrapper>

                  {withBottomDivider && <Menu.Divider />}
                </Menu.Item>
              </React.Fragment>
            ),
            items
          )}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'sticky',
    right: 0,
    width: 0,
  },
  tooltipContainer: {
    position: 'absolute',
    transform: 'translateX(-100%) translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    justifyContent: 'center',
    background: theme.fn.linearGradient(
      90,
      'transparent',
      'white',
      'white',
      'white'
    ),
    width: 50,
    height: 56,
  },
}));

export default RowMenuItems;
