import {
  Anchor,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  TextInput,
  TextInputProps,
  Tooltip,
  TooltipProps,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import {
  DeviceModelType,
  useSendPartnerSupportEmail,
} from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ReactComponent as QuestionCircle } from '@portals/icons/linear/question-circle.svg';
import { AuthMethodType } from '@portals/types';

import { OnSubmitFormParams } from './add-device-modal.types';
import macAddressSrc from '../../../../assets/img/mac-address-image.svg';
import snNumberSrc from '../../../../assets/img/sn-number.svg';
import { ContactPartnerSupportButton } from '../../../components/ContactPartnerSupportButton';

interface ModelFormProps {
  model: DeviceModelType;
  handleSubmit: (values: OnSubmitFormParams) => void;
  handleClose: () => void;
  onTroubleshoot?: () => void;
}

export function ModelForm({
  model,
  handleSubmit,
  handleClose,
  onTroubleshoot,
}: ModelFormProps) {
  const sendSupportEmail = useSendPartnerSupportEmail();

  const form = useForm<OnSubmitFormParams>({
    initialValues: {
      cloud_id: '',
      mac: '',
      sn: '',
      name: '',
      model: model.id,
    },
  });

  const authMethod = model ? model.auth_method : null;

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <LoadingOverlay visible={sendSupportEmail.isLoading} />

      <Stack pb="xxl" px="xxl">
        {authMethod === AuthMethodType.CloudId && (
          <TextInput
            required
            withAsterisk={false}
            label="Device Cloud ID"
            data-testid="text-input-cloud_id"
            placeholder="Device Cloud ID"
            {...form.getInputProps('cloud_id')}
          />
        )}

        {authMethod === AuthMethodType.MacSN && (
          <>
            <TextInput
              label={
                <Group spacing="xs">
                  <Text>MAC Address</Text>

                  <Tooltip
                    label={
                      <Paper>
                        <Stack w={350}>
                          <img src={macAddressSrc} />

                          <Stack p="xl">
                            <Text color="blue_gray.9">MAC Address</Text>

                            <Text color="gray.6">
                              The MAC Address is a unique identifier assigned to
                              network devices, usually found on a label on the
                              device itself.
                            </Text>
                          </Stack>
                        </Stack>
                      </Paper>
                    }
                    styles={tooltipStyles}
                    withArrow
                    arrowSize={20}
                    arrowPosition="center"
                    multiline
                  >
                    <QuestionCircle />
                  </Tooltip>
                </Group>
              }
              placeholder="e.g  00:B0:D0:63:C2:26"
              required
              withAsterisk={false}
              styles={textInputStyles}
              {...form.getInputProps('mac')}
            />

            <TextInput
              label={
                <Group spacing="xs">
                  <Text>Serial Number</Text>

                  <Tooltip
                    label={
                      <Paper>
                        <Stack w={350}>
                          <img src={snNumberSrc} />

                          <Stack p="xl">
                            <Text color="blue_gray.9">Serial Number</Text>

                            <Text color="gray.6">
                              The Serial Number is a unique identifier assigned
                              to your device by the manufacturer. It's typically
                              located on a label on the device
                            </Text>
                          </Stack>
                        </Stack>
                      </Paper>
                    }
                    styles={tooltipStyles}
                    withArrow
                    arrowSize={20}
                    arrowPosition="center"
                    multiline
                  >
                    <QuestionCircle />
                  </Tooltip>
                </Group>
              }
              styles={textInputStyles}
              placeholder="Enter serial number"
              required
              withAsterisk={false}
              {...form.getInputProps('sn')}
            />
          </>
        )}

        <TextInput
          label="Device Name (Optional)"
          placeholder="e.g CEO’s Room TV"
          data-testid="text-input-name"
          {...form.getInputProps('name')}
        />

        <Group spacing={4}>
          <Text c="gray.6" size="sm">
            Need help?
          </Text>
          <Anchor c="gray.6" size="sm" onClick={onTroubleshoot}>
            See troubleshooting guide
          </Anchor>
        </Group>
      </Stack>

      <Divider color="gray.2" />

      <ModalFooter position="right" py="xl" px="xxl">
        <ContactPartnerSupportButton
          supportModalTitle={`Claiming device: ${model.model}`}
          partnerId={model.partner.id}
        />

        <Button
          variant="default"
          onClick={handleClose}
          data-testid="cancel-button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          data-testid="add-device-to-space-claim-device-button"
          disabled={!form.values.model}
        >
          Claim device
        </Button>
      </ModalFooter>
    </form>
  );
}

const textInputStyles: TextInputProps['styles'] = (theme) => ({
  label: {
    display: 'flex',
    gap: theme.spacing.xs,
  },
});

const tooltipStyles: TooltipProps['styles'] = (theme) => ({
  tooltip: {
    backgroundColor: theme.white,
    borderRadius: theme.radius.lg,
    boxShadow: '0px 11px 23.9px 0px rgba(0, 0, 0, 0.19)',
  },
});
