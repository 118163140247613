import { Box, createStyles, Group, Tooltip } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { contextMenu } from 'react-contexify';
import { createPortal } from 'react-dom';
import 'react-contexify/dist/ReactContexify.css';

import { useSpace, useUpdateSpace } from '@portals/api/organizations';
import { IncidentsBySpaceIdTooltip, TOUR_STEPS_IDS } from '@portals/framework';
import { ReactComponent as Danger } from '@portals/icons/bulk/danger.svg';
import { ReactComponent as Maintenance } from '@portals/icons/linear/maintenance.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import {
  getSpaceHighestIncidentPriority,
  suppressPropagation,
} from '@portals/utils';

import { NodeContextMenu } from './NodeContextMenu';
import { TreeNodeLabel } from './TreeNodeLabel';
import { TreeNodeProps } from '../../../../overview.types';
import { useIsNodeExpanded } from '../../organization-tree.context';

export function TreeNode({
  handleCreateSpace,
  node,
  contextMenuPortalRef,
  isInFocus,
  itemIdPrefix,
  isEditable,
  isRemovable,
  editModeNodeId,
  setEditModeNodeId,
  customLabel,
}: TreeNodeProps) {
  const space = useSpace({ spaceId: node?.id });
  const updateSpace = useUpdateSpace();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [nameInput, setNameInput] = useState(node.title);

  const { classes, theme, cx } = useStyles({ isContextMenuOpen });

  const isExpanded = useIsNodeExpanded(node.id);

  const isInputChanged = node.title !== nameInput;

  const isEditMode = editModeNodeId === node.id;

  const dangerIconColor = getSpaceHighestIncidentPriority(
    isExpanded ? space?.state?.local_incidents : space?.state?.incidents
  );

  const saveUpdatedSpaceName = async () => {
    if (nameInput && isInputChanged && space?.id) {
      try {
        await updateSpace.mutateAsync({
          spaceId: space.id,
          updatedSpace: { name: nameInput },
        });
      } catch (error) {
        setNameInput(node.title);
      }
    } else {
      setNameInput(node.title);
    }

    setEditModeNodeId?.(null);
  };

  const onMenuToggle = (event) => contextMenu.show({ event, id: node.id });

  const shouldDisplayIncidentsAlert = useMemo(() => {
    if (!space) return false;

    const incidentsTotal = isExpanded
      ? space.state?.local_incidents?.total
      : space.state?.incidents?.total;

    if (!incidentsTotal || incidentsTotal < 1) return false;

    return incidentsTotal;
  }, [isExpanded, space]);

  if (!space) return null;

  return (
    <>
      <Box
        id={
          itemIdPrefix
            ? `${itemIdPrefix}-tree-item-${node.id}`
            : `tree-item-${node.id}`
        }
        onDoubleClick={() => (isEditable ? setEditModeNodeId?.(node.id) : noop)}
        className={cx(classes.container, 'tree-item selectable', {
          focused: isInFocus,
          'context-menu-open': !isInFocus && isContextMenuOpen,
        })}
        data-tour-step-id={TOUR_STEPS_IDS.space}
        data-testid={`tree-item-${node.name}`}
      >
        <TreeNodeLabel
          isEditMode={isEditMode}
          onFinishEditing={saveUpdatedSpaceName}
          node={node}
          spaceNameInput={nameInput}
          onSetSpaceNameInput={setNameInput}
          customLabel={customLabel}
        />

        <Group align="center" spacing="xs" className="tree-item-indicators">
          {space?.state?.maintenance ? (
            <Tooltip label="Maintenance Mode">
              <Maintenance
                width={14}
                height={14}
                data-testid="maintenance-mode"
              />
            </Tooltip>
          ) : null}

          {space?.state?.snoozed && !space?.state?.maintenance ? (
            <Tooltip label="Snooze Mode">
              <Snooze width={14} height={14} data-testid="snoozed-mode" />
            </Tooltip>
          ) : null}

          {shouldDisplayIncidentsAlert ? (
            <IncidentsBySpaceIdTooltip space={space} isLocal={isExpanded}>
              <Danger
                width={17}
                height={17}
                color={theme.fn.themeColor(dangerIconColor)}
              />
            </IncidentsBySpaceIdTooltip>
          ) : null}

          {isEditable && space.space_type !== 'root' ? (
            <div className="context-menu-toggle">
              <More
                onClick={suppressPropagation(onMenuToggle)}
                data-testid="menu-toggle"
              />
            </div>
          ) : null}
        </Group>
      </Box>

      {contextMenuPortalRef.current
        ? createPortal(
            <NodeContextMenu
              branchType={space.space_type}
              setIsContextMenuOpen={setIsContextMenuOpen}
              nodeId={node.id}
              setEditModeNodeId={setEditModeNodeId}
              onCreateSpace={handleCreateSpace}
              canDeleteCustomer={isRemovable}
            />,
            contextMenuPortalRef.current
          )
        : null}
    </>
  );
}

const useStyles = createStyles(
  (theme, { isContextMenuOpen }: { isContextMenuOpen: boolean }) => ({
    container: {
      width: '100%',
      zIndex: 1,
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '1fr max-content max-content max-content',
      boxSizing: 'border-box',
      alignItems: 'center',

      '.tree-item-indicators': {
        marginRight: '10px',
      },

      '.context-menu-toggle': {
        opacity: isContextMenuOpen ? 1 : 0,
        display: isContextMenuOpen ? 'block' : 'none',
        padding: '0 5px',
        transition: 'opacity 0.15s ease-in-out',

        svg: {
          width: '18px',
          fill: theme.black,
        },
      },

      '&:hover': {
        '.context-menu-toggle': {
          display: 'block',
          opacity: 1,
        },
      },
    },
  })
);
