import { AuthType } from '@portals/types';

export enum StepIdEnum {
  Authentication = 'authentication',
  Confirmation = 'confirmation',
  SetUpAccount = 'setupAccount',
  OrganizationDoesntExists = 'OrganizationDoesntExists',
  OrganizationExists = 'organizationExists',
  InviteCoWorkers = 'inviteCoWorkers',
  PartnerExists = 'partnerExists',
  AccessRequest = 'accessRequest',
}

export interface SignupWizardContextDataType {
  email: string;
  name: string;
  password: string;
  org?: string;
  googleToken?: string;
  auth?: AuthType | null | boolean;
  sessionToken?: string;
  partnerLogo?: string | null;
  partnerName?: string;
  organizationExists?: boolean;
}
