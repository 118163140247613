import { Box, Button, Grid, Stack, Text } from '@mantine/core';
import React, { forwardRef, KeyboardEvent } from 'react';

import { suppressPropagation } from '@portals/utils';

interface ColorSelectorProps {
  label: string;
  colors: string[];
  onSelect: (color: string) => void;
  closeColorSelector: () => void;
}

export const ColorSelector = forwardRef<HTMLDivElement, ColorSelectorProps>(
  function ({ label, colors, onSelect, closeColorSelector }, ref) {
    return (
      <Stack
        ref={ref}
        align="center"
        onKeyDown={suppressPropagation((e: KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Escape') {
            closeColorSelector();
          }
        })}
      >
        <Text fz="xs" c="gray.5">
          {label}
        </Text>

        <Grid columns={5} gutter={4}>
          {colors.map((color) => (
            <Grid.Col key={color} span={1}>
              <Button variant="white" onClick={() => onSelect(color)}>
                <Box
                  bg={color}
                  w={9}
                  h={9}
                  sx={{
                    borderRadius: 100,
                  }}
                />
              </Button>
            </Grid.Col>
          ))}
        </Grid>
      </Stack>
    );
  }
);
