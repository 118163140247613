import { Button } from '@mantine/core';
import React from 'react';

import { ConnectionCard, ConnectionCardProps } from './ConnectionCard';
import { OrgC2CIntegrationItem } from '../../../../hooks/c2c-integrations';

interface C2CIntegrationConnectionCardProps {
  isConnected: boolean;
  integration: OrgC2CIntegrationItem;
  highlightText: ConnectionCardProps['highlightText'];
  onActionClick: (integration: OrgC2CIntegrationItem) => void;
}

export function C2CIntegrationConnectionCard({
  integration,
  isConnected,
  onActionClick,
  highlightText,
}: C2CIntegrationConnectionCardProps) {
  return (
    <ConnectionCard
      key={integration.id}
      id={integration.id}
      logo={integration.logo}
      title={integration.name}
      description={integration.description}
      isComingSoon={integration.isComingSoon}
      isConnected={isConnected}
      highlightText={highlightText}
      action={
        <Button
          onClick={() => onActionClick(integration)}
          data-analyticsid={
            integration.isComingSoon
              ? 'c2c-card-ask-to-connect-btn'
              : 'c2c-card-connect-btn'
          }
        >
          {integration.isComingSoon ? 'Ask to connect' : 'Connect'}
        </Button>
      }
    />
  );
}
