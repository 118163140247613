import { createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import emptyStateSrc from '../../assets/empty-state.svg';

export function NoteEmptyState() {
  const { classes } = useStyles();

  return (
    <Stack
      align="center"
      justify="center"
      spacing={4}
      className={classes.container}
    >
      <Text size="lg" weight={500} color="gray.8">
        Your Notes Here
      </Text>
      <Text size="md" color="gray.5">
        Add notes in your space
      </Text>

      <img src={emptyStateSrc} className={classes.image} />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.spacing.xl,
  },
  image: {
    position: 'absolute',
    transform: 'translate(100px, 10px)',
    zIndex: 0,
  },
}));
