import { useDescope } from '@descope/react-sdk';
import {
  Alert,
  Anchor,
  Box,
  Button,
  LoadingOverlay,
  PasswordInput,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { captureException } from '@sentry/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { object } from 'yup';

import { useSignIn } from '@portals/api/auth';
import { TenantType } from '@portals/types';

import {
  PasswordInputWithRequirements,
  yupPasswordConfirmValidator,
  yupPasswordValidator,
} from './PasswordInputWithRequirements';
import { useAppConfig } from '../../context';

const schema = object({
  password: yupPasswordValidator,
  confirmPassword: yupPasswordConfirmValidator('password'),
});

export function ChangePassword() {
  const token = useSearchParam('t');
  const descopeSdk = useDescope();

  const [isLoading, setLoading] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const signIn = useSignIn();
  const { tenantType } = useAppConfig();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validate: yupResolver(schema),
  });

  const onSubmit = async (values: typeof form.values) => {
    setLoading(true);

    try {
      const verifiedToken = await descopeSdk.magicLink.verify(token);

      if (!verifiedToken.ok || !verifiedToken.data?.user?.email) {
        if (verifiedToken.error?.errorCode === 'E062504') {
          setIsTokenExpired(true);
        }

        form.setFieldError('name', verifiedToken.error?.errorDescription);
        throw new Error(verifiedToken.error?.errorDescription);
      }

      const updatePassword = await descopeSdk.password.update(
        verifiedToken.data?.user?.email,
        values.password,
        verifiedToken.data?.refreshJwt
      );

      if (!updatePassword.ok) {
        form.setFieldError('name', updatePassword.error?.errorDescription);

        throw new Error(updatePassword.error?.errorDescription);
      }

      const descopeSignIn = await descopeSdk.password.signIn(
        verifiedToken.data.user.email,
        values.password
      );

      if (!descopeSignIn.ok || !descopeSignIn.data?.sessionJwt) {
        form.setFieldError('name', descopeSignIn.error?.errorDescription);

        throw new Error(descopeSignIn.error?.errorDescription);
      }

      await signIn.mutateAsync({
        token: descopeSignIn.data.sessionJwt,
        tenant_type: tenantType as TenantType,
      });

      navigate('/');
    } catch (e) {
      captureException(`descope error - ${e}`);
    } finally {
      setLoading(false);
    }
  };

  if (!token) {
    return (
      <Stack justify="center" spacing="xl">
        <Text fz={32}>Reset password link expired</Text>

        <Text ta="center">Please click below to create a new link</Text>

        <Anchor ta="center" href="/auth/reset-password">
          Reset password
        </Anchor>
      </Stack>
    );
  }

  return (
    <Stack spacing="xxl">
      <LoadingOverlay visible={isLoading} />

      <Title order={1}>Type in new password</Title>

      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xl">
          <PasswordInputWithRequirements
            label="New password"
            placeholder="Password"
            {...form.getInputProps('password')}
          />

          <PasswordInput
            label="Confirm password"
            placeholder="Confirm password"
            required
            {...form.getInputProps('confirmPassword')}
          />

          <Button fullWidth type="submit">
            Continue
          </Button>

          {isTokenExpired && (
            <Alert color="red.0" radius="lg">
              <Stack>
                <Box>
                  <Text align="center" fw={700}>
                    This invitation code has already been used
                  </Text>

                  <Text align="center">
                    You might have already signed up using this invitation.
                  </Text>
                </Box>

                <Anchor
                  c="gray.9"
                  align="center"
                  component={Link}
                  to="/auth/sign-in"
                >
                  Sign in
                </Anchor>
              </Stack>
            </Alert>
          )}
        </Stack>
      </form>
    </Stack>
  );
}
