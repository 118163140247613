import { Anchor, Button, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';

import { C2CConnectorName } from '@portals/api/organizations';
import { ModalCenteredMediaLayout } from '@portals/core';
import { C2C_INTEGRATIONS, ModalProps } from '@portals/framework';

import {
  AllowedC2cThirdPartyConnectors,
  OrgC2CIntegrationItem,
} from '../../../hooks/c2c-integrations';

const C2C_SUPPORTED_MODELS_LINKS = {
  zoom: 'https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0062179',
  mtr: 'https://learn.microsoft.com/en-us/microsoftteams/devices/certified-devices-overview',
  xio: 'https://www.crestron.com/pro/app/Crestron-Connected-Web-App',
  domotz: 'https://www.domotz.com/integrations.php',
} as const satisfies Record<AllowedC2cThirdPartyConnectors, string>;

export interface ThirdPartyC2CConnectorsModalProps
  extends ModalProps<{
    integration: OrgC2CIntegrationItem;
    onAskToConnect: VoidFunction;
    onConnectWithThirdParty: (connectorName: C2CConnectorName) => void;
  }> {}

export function ThirdPartyC2CConnectorsModal({
  closeMe,
  data: { integration, onAskToConnect, onConnectWithThirdParty },
}: ThirdPartyC2CConnectorsModalProps) {
  function onConnectClick(connectorName: C2CConnectorName) {
    onConnectWithThirdParty(connectorName);

    closeMe();
  }

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      size={645}
      title={`Connect ${integration.name} devices`}
      media={<img src={integration.logo} alt={integration.name} />}
      mediaSize={80}
      description={
        <Text>
          Devices from <strong>{integration.name}</strong> are currently
          accessible through these third-party connectors. You can view
          supported models for each connector or click 'Connect' to proceed with
          the connection process
        </Text>
      }
      footer={
        <Text ta="center">
          <Anchor onClick={onAskToConnect}>Click here</Anchor> to request native
          support for <strong>{integration.name}</strong>
        </Text>
      }
    >
      <Stack spacing="xs">
        {integration.thirdPartyConnectors?.map((connectorName) => {
          const connectorMetadata = C2C_INTEGRATIONS[connectorName];

          return (
            <Paper key={connectorName} p="xl" withBorder radius="lg">
              <Group position="apart">
                <Group noWrap>
                  <img
                    width={50}
                    height={50}
                    src={connectorMetadata.logo}
                    alt={connectorMetadata.name}
                  />

                  <Text>{connectorMetadata.name}</Text>
                </Group>

                <Group>
                  <Anchor
                    href={C2C_SUPPORTED_MODELS_LINKS[connectorName]}
                    target="_blank"
                  >
                    View supported models
                  </Anchor>

                  <Button onClick={() => onConnectClick(connectorMetadata.id)}>
                    Connect
                  </Button>
                </Group>
              </Group>
            </Paper>
          );
        })}
      </Stack>
    </ModalCenteredMediaLayout>
  );
}
